import * as React from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import {
  ChartingLibraryWidgetOptions,
  ResolutionString,
  widget,
} from '../../../charting_library';
import Datafeed from './datafeed';

export interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions['symbol'];
  interval: ChartingLibraryWidgetOptions['interval'];
  libraryPath: ChartingLibraryWidgetOptions['library_path'];
  clientId: ChartingLibraryWidgetOptions['client_id'];
  userId: ChartingLibraryWidgetOptions['user_id'];
  fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
  autosize: ChartingLibraryWidgetOptions['autosize'];
  studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
  container: ChartingLibraryWidgetOptions['container'];
}

type TProps = {
  symbol: string;
  interval?: any;
  isMobile?: boolean;
};

export const TVChartContainer = ({ symbol, interval, isMobile }: TProps) => {
  const chartContainerRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const [url, setUrl] = React.useState<URL>(
    () => new URL(window.location.href)
  );

  const defaultProps: Omit<ChartContainerProps, 'container'> = {
    symbol: 'BTC',
    interval: '5' as ResolutionString,
    libraryPath: '/charting_library/',
    clientId: 'EDENEX',
    userId: 'public_user_id',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
  };

  const getActivePair = () => {
    return url?.searchParams?.get('pair')?.toUpperCase();
  };

  const getLocalization = () => {
    return url?.searchParams?.get('localization');
  };

  const [activePair, setActivePair] = useState<string | undefined | null>();
  const [lang, setLang] = useState<string | undefined | null>('en');
  const [disabledFeatures, setDisabledFeatures] = useState<string[]>([]);

  useLayoutEffect(() => {
    setUrl(() => new URL(window.location.href));
  }, [window.location.href]);

  useLayoutEffect(() => {
    console.log(activePair, lang, isMobile);
    if (isMobile) {
      setActivePair(getActivePair());
      setLang(getLocalization());
      setDisabledFeatures([
        'header_symbol_search',
        'use_localstorage_for_settings',
        'header_compare',
        'header_saveload',
        'items_favoriting',
        'drawing_templates',
        'header_symbol_search',
        'edit_buttons_in_legend',
        'show_hide_button_in_legend',
        'format_button_in_legend',
        'delete_button_in_legend',
        'legend_context_menu',
      ]);
    } else if (!isMobile) {
      setActivePair(localStorage.getItem('activePair'));
      if (localStorage.getItem('i18nextLng')) {
        setLang(localStorage.getItem('i18nextLng'));
      }
      setDisabledFeatures([
        'header_symbol_search',
        'use_localstorage_for_settings',
        'header_compare',
        'header_saveload',
      ]);
    }
    console.log('isDisabledFeatures', disabledFeatures);
  }, [isMobile, lang, activePair]);

  useLayoutEffect(() => {
    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: symbol || (defaultProps.symbol as string),
      datafeed: Datafeed,
      interval:
        interval ||
        (defaultProps.interval as ChartingLibraryWidgetOptions['interval']),
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath as string,
      timeframe: '5D',
      // @ts-ignore
      locale: lang || 'en',
      disabled_features: disabledFeatures,
      enabled_features: ['hide_last_na_study_output'],
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: defaultProps.studiesOverrides,
      load_last_chart: true,
      toolbar_bg: 'transparent',
      theme: 'Dark',
      custom_font_family: "'Inter', 'monospace'",
    };

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        // const button = tvWidget.createButton();
        // button.setAttribute('title', 'Click to show a notification popup');
        // button.classList.add('apply-common-tooltip');
        // button.addEventListener('click', () =>
        //   tvWidget.showNoticeDialog({
        //     title: 'Notification',
        //     body: 'TradingView Charting Library API works correctly',
        //     callback: () => {
        //       console.log('Noticed!');
        //     },
        //   })
        // );
        // button.innerHTML = 'Check API';
      });
    });

    return () => {
      tvWidget.remove();
    };
  }, [activePair, lang, isMobile]);

  return (
    <div
      ref={chartContainerRef}
      className={'TVChartContainer'}
      style={{ height: '100%', width: '100%' }}
    />
  );
};

import {FC} from 'react'
import {Modal, Typography} from 'antd'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import Button from '../button'
import {ButtonsTypes} from '../button/filledButton.p'

interface IProps {
  open: boolean
  onCancel: () => void
  onOk: () => void
}

const ConfirmCloseAppealModal: FC<IProps> = ({open, onCancel, onOk}) => {
  const {t}  = useTranslation()
  return <Modal title='Отмена апелляции' open={open} onCancel={onCancel} footer={null} centered width={560}>
<ConfirmAppellModalText>{t('appealModal.confirmCloseAppeal')}</ConfirmAppellModalText>
<ConfirmAppellModalText $isDescription>{t('appealModal.confirmCLoseAppealDescription')}</ConfirmAppellModalText>
    <div
      style={{ marginTop: '32px' }}
      className="flex justify-content-flex-end gap-8"
    >
      <Button style={{height: 40, width: '65%'}} onClick={onOk} >{t('p2p.getCurrentStep.cancelAppeal')}</Button>
      <Button style={{height: 40, width: '35%', minWidth: 'none'}} onClick={onCancel} type={ButtonsTypes.outlined}>{t('p2p.getCurrentStep.getBack')}</Button>
    </div>
  </Modal>
}

const ConfirmAppellModalText = styled(Typography.Text)<{$isDescription?: boolean}>`
  display: block;
  font-size: 16px;
  color: ${props => props.$isDescription ? '#767687' : '#FBFBFF'};
  margin-bottom: 16px;
`

export default ConfirmCloseAppealModal

import Icon from '@ant-design/icons'
import { AllTypeCoinIcon } from '../components/ui/assets/svg/coinsIcon'
import { FormCoinList, formSelectList } from '../helpers/formCoinList'
import { getUniqList } from '../helpers/getUniqOptionList'
import React from 'react'
import { LogicalFilter, useTranslate } from '@pankod/refine-core'
import { IGetListData } from '../../features/myOrders/myOrders.p'

interface IUseGetTranslateItemsMyOrder{
  setInputCoinKey: (text:string) => void
  setOutputCoinKey: (text:string) => void
  setStatusKey: (text:string) => void
  setFilter: (filter:LogicalFilter) => void
  inputList: IGetListData[]
  outputList: IGetListData[]
}

export const useGetMyOrderTranslatedItems = (data: IUseGetTranslateItemsMyOrder) => {
  const t = useTranslate();
  const {setInputCoinKey, setFilter, setOutputCoinKey, inputList, outputList, setStatusKey} = data
  const drawSelectCoinItem = (item: FormCoinList, isGet?: boolean) => {
    return {
      key: item.key,
      icon: item.coinIcon,
      label: (
        <div
          className={'flex-column gap-4'}
          style={{ maxHeight: 56, paddingLeft: 8 }}
        >
          <div style={{ height: 'fit-content' }} className={'text-14px'}>
            {item.coinFullName}
          </div>
          <div className={'text-14px gray'}>{item.shortName}</div>
        </div>
      ),
      onClick: () => {
        if (isGet) {
          setOutputCoinKey(item.coinFullName);
          setFilter({
            field: 'output_currency',
            operator: 'eq',
            value: `${item.key}`,
          });
        } else {
          setInputCoinKey(item.coinFullName);
          setFilter({
            field: 'input_currency',
            operator: 'eq',
            value: `${item.key}`,
          });
        }
      },
    };
  };


  const inputCoinItems = [
    {
      key: 'all',
      icon: <Icon component={AllTypeCoinIcon} />,
      label: t('myOrders.filters.receive'),
      onClick: () => {
        setInputCoinKey(t('myOrders.filters.receive'));
        setFilter({
          field: 'input_currency',
          operator: 'contains',
          value: '',
        });
      },
    },
    ...formSelectList(
      getUniqList(inputList, 'input_currency'),
      'input_currency'
    ).map((item) => drawSelectCoinItem(item)),
  ];

  const outputCoinItems = [
    {
      key: 'all',
      icon: <Icon component={AllTypeCoinIcon} />,
      label: t('myOrders.filters.get'),
      onClick: () => {
        setOutputCoinKey(t('myOrders.filters.get'));
        setFilter({
          field: 'output_currency',
          operator: 'contains',
          value: '',
        });
      },
    },
    ...formSelectList(
      getUniqList(outputList, 'output_currency'),
      'output_currency'
    ).map((item) => drawSelectCoinItem(item, true)),
  ];

  const statusItems = [
    {
      key: 'all',
      label: t('myOrders.filters.status.all'),
      onClick: () => {
        setStatusKey(t('myOrders.filters.status.all'));
        setFilter({
          field: 'status',
          operator: 'contains',
          value: '',
        });
      },
    },
    {
      key: 'complete',
      label: t('myOrders.filters.status.done'),
      onClick: () => {
        setStatusKey(t('myOrders.filters.status.done'));
        setFilter({
          field: 'status',
          operator: 'eq',
          value: `executed`,
        });
      },
    },
    {
      key: 'cancel',
      label: t('myOrders.filters.status.cancel'),
      onClick: () => {
        setStatusKey(t('myOrders.filters.status.cancel'));
        setFilter({
          field: 'status',
          operator: 'eq',
          value: `canceled`,
        });
      },
    },
    {
      key: 'return',
      label: t('myOrders.filters.status.refund'),
      onClick: () => {
        setStatusKey(t('myOrders.filters.status.refund'));
        setFilter({
          field: 'status',
          operator: 'eq',
          value: `return`,
        });
      },
    },
    {
      key: 'processing',
      label: t('myOrders.filters.status.pending'),
      onClick: () => {
        setStatusKey(t('myOrders.filters.status.pending'));
        setFilter({
          field: 'status',
          operator: 'eq',
          value: [`new`, 'wallet_created', 'withdraw_waiting', 'wallet_replenished'],
        });
      },
    },
  ];

  return {
    inputCoinItems,
    outputCoinItems,
    statusItems,
  }
}

import { FC, lazy, useEffect, useState } from 'react';
import { Form, Select, Typography } from '@pankod/refine-antd';
import { SelectIcon } from '../../../../shared/components/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  getCoinFromCurrencyConstraintData,
  getIconByCoinName,
  IParsedCurrencyData,
  TCoinName,
} from '../../../../entities/coin';
import { ReactComponent as Icon } from './icon.svg';
import { useWalletState } from '../../../../useWalletState';

const CoinItem = lazy(() => import('./CoinItem'));

type CoinsFormProps = {
  type: 'input' | 'output';
  showError?: boolean;
};

const CoinForm: FC<CoinsFormProps> = ({ type, showError = false }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const {
    selectedCoin,
    currencyConstraintsWithNetworks,
    setSelectedCoin,
    selectedCoinNetwork,
    setSelectedCoinNetwork,
    setWallet,
  } = useWalletState((state) => state);

  const [isOpenCoinSelect, setIsOpenCoinSelect] = useState<boolean>(false);
  const [isOpenNetworkSelect, setIsOpenNetworkSelect] =
    useState<boolean>(false);

  const handleChangeCoin = (coinName: TCoinName) => {
    const coin = getCoinFromCurrencyConstraintData(
      coinName,
      currencyConstraintsWithNetworks
    );
    if (coin) {
      setSelectedCoin(coin);
    }
  };

  const getAvailability = (coin: IParsedCurrencyData) => {
    if (type === 'input') {
      return coin.is_available_input;
    }
    if (type === 'output') {
      return coin.is_available_output;
    }
    return false;
  };

  const handleChangeNetwork = (
    networkId: string,
    coin: IParsedCurrencyData
  ) => {
    if (!!coin?.networks?.length) {
      const network = coin.networks.find((network) => network.id === networkId);
      if (!!network) {
        setSelectedCoinNetwork(network);
      }
      return;
    }
    setSelectedCoinNetwork(undefined);
  };

  const createLabelCoin = (coinName: string, coinFullName: string) => (
    <SelectItem>
      {getIconByCoinName(coinName)}
      <Typography.Text>{coinFullName}</Typography.Text>
    </SelectItem>
  );

  useEffect(() => {
    setWallet(undefined);
    form.setFieldsValue({
      coin: selectedCoin?.currency,
      network: selectedCoinNetwork?.id,
    });
  }, [selectedCoin, selectedCoinNetwork]);

  useEffect(() => {
    setSelectedCoinNetwork(undefined);
  }, [selectedCoin]);

  useEffect(() => {
    if (showError) form.validateFields().then();
  }, [showError, form]);

  const handelChangeCoinSelect = (value: boolean) => setIsOpenCoinSelect(value);

  const handelChangeCoinNetworkSelect = (value: boolean) =>
    setIsOpenNetworkSelect(value);

  const Placeholder = () => (
    <ContainerPlaceholderStyled>
      {type === 'input' && <Icon />}{' '}
      {t('wallet.coin.chooseCurrencyPlaceholder')}
    </ContainerPlaceholderStyled>
  );

  const SuffixIcon = ({ isOpen }: { isOpen: boolean }) => (
    <ContainerSuffixIconStyled transform={`rotate(${isOpen ? 180 : 0}deg)`}>
      <SelectIcon fill={isOpen ? '#FBFBFF' : undefined} />
    </ContainerSuffixIconStyled>
  );

  return (
    <CoinFormContainer form={form} name="control-hooks" layout="vertical">
      {currencyConstraintsWithNetworks && (
        <>
          <FormItem
            name="coin"
            label={
              type === 'input'
                ? t('wallet.coin.inputCurrency')
                : t('wallet.coin.outputCurrency')
            }
            rules={[
              {
                required: true,
                message: t('wallet.coin.chooseCurrencyPlaceholder'),
              },
            ]}
          >
            <Select
              placeholder={<Placeholder />}
              suffixIcon={<SuffixIcon isOpen={isOpenCoinSelect} />}
              onChange={handleChangeCoin}
              onDropdownVisibleChange={(value) => handelChangeCoinSelect(value)}
              optionLabelProp={'label'}
            >
              {currencyConstraintsWithNetworks.map(
                (coin: IParsedCurrencyData) =>
                  coin &&
                  getAvailability(coin) && (
                    <Select.Option
                      key={coin.currency}
                      value={coin.currency}
                      label={createLabelCoin(
                        coin.currency.toLowerCase(),
                        coin.name
                      )}
                    >
                      <SelectItem>
                        {getIconByCoinName(coin.currency.toLowerCase(), 24)}
                        <SelectedItemInnerStyled>
                          <Typography.Text style={{ fontSize: 14 }}>
                            {coin.name}
                          </Typography.Text>
                          <SelectItemDescription>
                            {coin.currency.toUpperCase()}
                          </SelectItemDescription>
                        </SelectedItemInnerStyled>
                      </SelectItem>
                    </Select.Option>
                  )
              )}
            </Select>
          </FormItem>
          {selectedCoin && !!selectedCoin.networks?.length && (
            <FormItem
              name="network"
              label={t('wallet.coin.chooseNetwork')}
              rules={[
                {
                  min: 1,
                  message: t('wallet.coin.chooseNetworkPlaceholder'),
                },
              ]}
            >
              <Select
                onDropdownVisibleChange={(value) =>
                  handelChangeCoinNetworkSelect(value)
                }
                allowClear={false}
                suffixIcon={<SuffixIcon isOpen={isOpenNetworkSelect} />}
                placeholder={t('wallet.coin.chooseNetworkPlaceholder')}
                onChange={(id) => handleChangeNetwork(id, selectedCoin)}
                optionLabelProp={'label'}
              >
                {(selectedCoin.networks ?? []).map((network) => (
                  <CoinItem network={network} />
                ))}
              </Select>
            </FormItem>
          )}
        </>
      )}
    </CoinFormContainer>
  );
};

export default CoinForm;

const ContainerSuffixIconStyled = styled.div<{ transform: string }>`
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${({ transform }) => transform};
`;

const ContainerPlaceholderStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CoinFormContainer = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 32px;
`;

const SelectItem = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  gap: 10px;

  & svg {
    height: 24px;
    width: 24px;
  }
`;
const SelectedItemInnerStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectItemDescription = styled(Typography.Text)`
  color: rgba(118, 118, 135, 1);
  font-size: 14px;
`;

const FormItem = styled(Form.Item)`
  width: 100%;
  margin-bottom: 0;

  .ant-form-item-required::before {
    display: none !important;
  }

  & .ant-select-selector {
    background: linear-gradient(
      80.47deg,
      #18181f 6.62%,
      #2d2d3a 148.62%
    ) !important;
    border-radius: 8px;
  }

  .ant-select-selector:hover {
    border-color: #7c44dc !important;
  }

  .ant-select-focused .ant-select-selector {
    border-color: #fbfbff !important;
  }
`;

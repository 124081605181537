import React from 'react';
import { WhiteEyeIcon, EyeIcon, EyeSlashIcon, WhiteEyeSlashIcon } from '../../../icons';

interface IEyeButton {
  isVisible: boolean;
  color?: 'grey' | 'white';
  onClickAction: () => void;
}

const Index: React.FC<IEyeButton> = ({ isVisible, onClickAction, color='grey' }) => {
  return (
    <>
      <div
        onClick={onClickAction}
        style={{ userSelect: 'none', cursor: 'pointer', height: 24 }}
      >
        {isVisible ? color==='grey' ? <EyeSlashIcon /> : <WhiteEyeSlashIcon /> : color==='grey' ? <EyeIcon /> : <WhiteEyeIcon/>}
      </div>
    </>
  );
};

export default Index;

import * as React from 'react';
import { useState } from 'react';

export function useRelay<TData = undefined>(initialState: boolean = false) {
  const [state, setState] = useState(initialState);

  return {
    state: state,
    enable: () => {
      if (state) {
        throw new Error();
      }

      setState(true);
    },
    disable: () => {
      if (!state) {
        throw new Error();
      }

      setState(false);
    },
  };
}

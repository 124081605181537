import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Form,
} from '@pankod/refine-antd';
import {
  BankCredention,
  FPS,
  MobileMethod,
} from '../../../shared/components/icons';
import './index.css';
import {
  HttpError,
  useCreate,
  useList,
  useTranslate,
} from '@pankod/refine-core';
import { IGetListAdData, IPaymentInfo, IPaymentsType, IRegionTemp } from './interface';
import * as _ from 'lodash';
import { PaymentTypeEnum } from '../../../features/p2p/createOrder/types';
import IconOld from '@ant-design/icons';
import { IP2PUser } from '../../../interfaces';
import { toFixed } from '../../../shared/helpers/toFixed';
import { IP2PCollapseArrayElement } from 'widgets/p2p/buyOrder/collapse/interface';
import {Collapse as CollapseCustom} from 'widgets/p2p/buyOrder/collapse/index';
import { useCookiesCustom } from '../../../shared/hooks/useCookieCustom';
import {useAuthState} from "../../../useAuthState";

type IconType = { payment_type: PaymentTypeEnum, name: string }

interface ICustomCollapse {
  type: 'buy' | 'sell';
  item: IGetListAdData;
  setOpenModal: Dispatch<SetStateAction<boolean>>,
  setFromCard: Dispatch<SetStateAction<boolean>>,
  user?: IP2PUser;
  iconsData?: IPaymentsType[],
  regions?: IRegionTemp[];
}

const CustomCollapse: FC<ICustomCollapse> = ({
                                               type = 'buy',
                                               item,
                                               user,
                                               setOpenModal,
                                               setFromCard,
                                               iconsData,
                                               regions
                                             }) => {
    const uid = useAuthState((state) => state?.userAPI?.uid);
  const [isOpen, setIsOpen] = useState(false);
  const [isValid, setIsValid] = useState<boolean | undefined>(true);

  const translate = useTranslate();
  const [dataAd] = useState<IGetListAdData>(item);
  const [paymentData, setPaymentData] = useState<IPaymentInfo[]>();
  const [error, setError] = useState<{
    buy?: string;
    payment?: string;
  }>({});
  const [form] = Form.useForm();
  const buyWatch = Form.useWatch('buy', form);
  const sellWatch = Form.useWatch('sell', form);
  const paymentMethodWatch = Form.useWatch('paymentMethod', form);
  const { mutate } = useCreate<{ data: { id: string } }>({
    mutationOptions: { retry: false },
  });

  const { refetch: paymentRefetch } = useList<IPaymentInfo, HttpError>({
    dataProviderName: 'p2p',
    resource: 'payment',
    config: {
      filters: [
        {
          field: 'account_id',
          operator: 'eq',
          value: uid,
        },
      ],
      pagination: {
        current: 1,
        pageSize: 100,
      },
    },
    queryOptions: {
      enabled: false,
      onSuccess: (res) => {
        setPaymentData(res.data);
        setIsValid(
          res.data.some((val) =>
            dataAd.buy_payment_info?.some(
              (val1) => val1.payment_type === val.payment_type
            )
          )
        );
      },
      retry: false,
    },
  });

  const handleOpen = async () => {
    setIsOpen(() => !isOpen);
    if (!isOpen && type === 'buy') {
      await paymentRefetch();
    }
  };

  function countDecimalPlaces(number: number) {
    const numberString = number.toString();
    const decimalIndex = numberString.indexOf('.');
    if (decimalIndex === -1) {
      return 0;
    } else {
      return numberString.length - decimalIndex - 1;
    }
  }

  const applyLimit = (
    limit: string,
    decimalPlaces: number,
    direction: 'min' | 'max' | ''
  ): string => {
    const result = +limit / +dataAd?.price;
    const decimalIndex = countDecimalPlaces(result);

    if (decimalIndex <= decimalPlaces) {
      return result.toString();
    } else {
      const splitIndex = decimalPlaces + 1;
      if (direction === 'min') {
        const minCeil = (result + 0.00000001).toFixed(splitIndex);
        return minCeil.slice(0, minCeil.length - 1);
      }
      if (direction === 'max') {
        const maxFloor = result.toFixed(splitIndex);
        return maxFloor.slice(0, maxFloor.length - 1);
      } else return result.toFixed(decimalPlaces).toString();
    }
  };


  const typeText = useMemo(() => {
    return {
      text: translate(`dashboard.collapseHeader.button.${type}`),
      input: [
        translate(`dashboard.collapseBody.${type}.first`),
        translate(`dashboard.collapseBody.${type}.last`),
      ],
      placeholder: `${applyLimit(
        dataAd?.min_sum_limit,
        item?.ccy === 'USDT' ? 2 : 8,
        'min'
      )} - ${applyLimit(
        dataAd?.max_sum_limit,
        item?.ccy === 'USDT' ? 2 : 8,
        'max'
      )}`,
      sellMin: +applyLimit(
        dataAd?.min_sum_limit,
        item?.ccy === 'USDT' ? 2 : 8,
        'min'
      ),
      sellMax: +applyLimit(
        dataAd?.max_sum_limit,
        item?.ccy === 'USDT' ? 2 : 8,
        'max'
      ),
    };
  }, [
    dataAd.max_sum_limit,
    dataAd.min_sum_limit,
    dataAd.price,
    item.ccy,
    translate,
    type,
  ]);

  const countDeals = useMemo(() => {
    return `${dataAd.owner_info.total_orders_amount} ${translate(
      'dashboard.collapseHeader.countDeals'
    )} | ${+toFixed(
      ((dataAd.owner_info.success_orders_amount || 0) /
        (dataAd.owner_info.total_orders_amount || 1)) *
      100
    )}% ${translate('dashboard.collapseHeader.countCompleted')}`;
  }, [
    dataAd.owner_info.success_orders_amount,
    dataAd.owner_info.total_orders_amount,
    translate,
  ]);

  const Icon = useCallback(({payment_type, name}: IconType) => (
    <img
      alt={'paymentIcon'}
      src={`data:image/svg+xml;base64,${iconsData
        ?.find(type => type.payment_type === payment_type)
        ?.items
        ?.find(icon => icon.name === name)
        ?.icon
      }`}
      style={{
        height: '16px'
      }}
    />
  ), [iconsData])

  const tmpBuy = useCallback(({
                                data = [],
                                icon = false,
                              }: {
    data?: IGetListAdData['buy_payment_info'];
    icon?: boolean;
  }) => {
    return data?.map((item): IP2PCollapseArrayElement => {
      switch (item.payment_type) {
        case PaymentTypeEnum.bankCardPayment:
          return {
            value: item.id,
            label: icon ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
                key={item.payment_type + '_' + item.id}
              >
                <Icon payment_type={PaymentTypeEnum.bankCardPayment} name={item.bank_name!}/>
                {item.bank_name}
              </div>
            ) : (
              item.bank_name
            ),
            type: translate('payments.types.banks')
          };
        case PaymentTypeEnum.mobileBalancePayment:
          return {
            value: item.id,
            label: icon ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
                key={item.payment_type + '_' + item.id}
              >
                <IconOld component={() => MobileMethod('', 16)}/>
                {translate('dashboard.filters.method.other.methods.mobile')}
              </div>
            ) : (
              translate('dashboard.filters.method.other.methods.mobile')
            ),
            title: '+7 (900) *** ** **',
            type: translate('payments.types.mobiles')
          };
        case PaymentTypeEnum.cashPayment:
          return {
            value: item.id,
            label: 'Cash Payment',
            type: translate('payments.types.cashes')
          };
        case PaymentTypeEnum.bankTransferPayment:
          return {
            value: item.id,
            label: icon ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
                key={item.payment_type + '_' + item.id}
              >
                <IconOld component={() => BankCredention('#FBFBFF', 16)}/>
                {translate('dashboard.filters.method.other.methods.bank')}
              </div>
            ) : (
              translate('dashboard.filters.method.other.methods.bank')
            ),
            title: item.bank_name,
            type: translate('dashboard.filters.method.other.methods.bank')
          };
        case PaymentTypeEnum.fastPaymentSystemPayment:
          return {
            value: item.id,
            label: icon ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
                key={item.payment_type + '_' + item.id}
              >
                <IconOld component={() => FPS('', 16)}/>
                {translate('dashboard.filters.method.other.methods.fps')}
              </div>
            ) : (
              translate('dashboard.filters.method.other.methods.fps')
            ),
            type: translate('payments.types.sbp')
          };
        case PaymentTypeEnum.webServicePayment:
          return {
            value: item.id,
            label: icon ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
                key={item.payment_type + '_' + item.id}
              >
                <Icon payment_type={PaymentTypeEnum.webServicePayment} name={item.web_service_name!}/>
                {item.web_service_name}
              </div>
            ) : (
              item.web_service_name
            ),
            type: translate('payments.electronService')
          };
        default:
          return {
            value: item.id,
            label: '',
            type: ''
          }
      }
    })
  }, [Icon, translate])

  const tmp = useCallback(({
                             data = [],
                             icon = false,
                           }: {
    data?: IPaymentInfo[];
    icon?: boolean;
  }) => {
    return (
      data
        ?.map((item) => {
          switch (item.payment_type) {
            case PaymentTypeEnum.bankCardPayment:
              return (
                item.payment_details?.map((bank) => {
                  return {
                    value: icon ? item.id : undefined,
                    label: icon ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <Icon payment_type={PaymentTypeEnum.bankCardPayment} name={bank.bank_name!}/>
                        {bank.bank_name}
                      </div>
                    ) : (
                      bank.bank_name
                    ),
                    type: translate('payments.types.banks'),
                  };
                }) ?? []
              );
            case PaymentTypeEnum.mobileBalancePayment:
              return (
                item.payment_details?.map(() => {
                  return {
                    value: item.id,
                    label: icon ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <IconOld component={() => MobileMethod('', 16)} />
                        {translate(
                          'dashboard.filters.method.other.methods.mobile'
                        )}
                      </div>
                    ) : (
                      translate('dashboard.filters.method.other.methods.mobile')
                    ),
                    title: '+7 (900) *** ** **',
                    type: translate('payments.types.mobiles'),
                  };
                }) ?? []
              );
            case PaymentTypeEnum.cashPayment:
              return (
                item.payment_details?.map(() => {
                  return {
                    value: item.id,
                    label: 'Cash Payment',
                    type: translate('payments.types.cashes'),
                  };
                }) ?? []
              );
            case PaymentTypeEnum.bankTransferPayment:
              return (
                item.payment_details?.map((transfer) => {
                  return {
                    value: item.id,
                    label: icon ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <IconOld
                          component={() => BankCredention('#FBFBFF', 16)}
                        />
                        {translate(
                          'dashboard.filters.method.other.methods.bank'
                        )}
                      </div>
                    ) : (
                      translate('dashboard.filters.method.other.methods.bank')
                    ),
                    title: transfer.bank_name,
                    type: translate(
                      'dashboard.filters.method.other.methods.bank'
                    ),
                  };
                }) ?? []
              );
            case PaymentTypeEnum.fastPaymentSystemPayment:
              return (
                item.payment_details?.map(() => {
                  return {
                    value: item.id,
                    label: icon ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <IconOld component={() => FPS('', 16)} />
                        {translate(
                          'dashboard.filters.method.other.methods.fps'
                        )}
                      </div>
                    ) : (
                      translate('dashboard.filters.method.other.methods.fps')
                    ),
                    type: translate('payments.types.sbp'),
                  };
                }) ?? []
              );
            case PaymentTypeEnum.webServicePayment:
              return (
                item.payment_details?.map((web) => {
                  return {
                    value: item.id,
                    label: icon ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <Icon
                          payment_type={PaymentTypeEnum.webServicePayment}
                          name={web.web_service_name!}
                        />
                        {web.web_service_name}
                      </div>
                    ) : (
                      web.web_service_name
                    ),
                    type: translate('payments.electronService'),
                  };
                }) ?? []
              );
          }

          return [];
        })
        .flat() ?? []
    );
  }, [Icon, translate])

  const methodArray = useCallback(
    (icon: boolean = false, method = false) => {
      switch (dataAd.type) {
        case 'buy':
          if (method && isOpen && paymentData?.length !== undefined) {
            return tmpBuy({
              data: _.uniqBy(
                (paymentData ?? [])
                  .filter((itemFilter) =>
                    (dataAd.buy_payment_info ?? []).find((itemFind) =>
                      itemFind.payment_type === itemFilter.payment_type &&
                      (!itemFilter.bank_name || !itemFind.bank_name || (itemFind.bank_name === itemFilter.bank_name)) &&
                      (!itemFilter.web_service_name || !itemFind.web_service_name || (itemFind.web_service_name === itemFilter.web_service_name))
                    ))
                  .map((item) => ({
                    ...item.payment_details[0],
                    id: item.id,
                    payment_type: item.payment_type,
                  })),
                'id'
              ) as IGetListAdData['buy_payment_info'],
              icon,
            });
          }
          return tmpBuy({
            data: dataAd?.buy_payment_info ?? [],
            icon,
          });
        case 'sell':
          return tmp({
            data: dataAd?.payment_info ?? [],
            icon,
          });
      }
    },
    [isOpen, paymentData, dataAd, tmp, tmpBuy]
  );

  return (
      <CollapseCustom
        regions={regions ? regions : []}
        isOpen={isOpen}
        user={user}
        setOpenModal={setOpenModal}
        setFromCard={setFromCard}
        typeText={typeText}
        dataAd={dataAd}
        countDeals={countDeals}
        methodArray={methodArray}
        onClickOpen={handleOpen}
        setIsOpen={setIsOpen}
        item={item}
      />
  );
};

export default CustomCollapse;

import React, { Dispatch, useEffect, useState } from 'react';
import '../Card/styles.css';
import CountryPhoneInput, {
  ConfigProvider,
  CountryPhoneInputValue,
} from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
import 'antd/dist/reset.css';
import 'antd-country-phone-input/dist/index.css';
import { PayService } from '../../../../../pages/p2p/settings/payment/payment';
import { useTranslation } from 'react-i18next';
import Comment from '../Comment';

interface Props {
  controlsTemplatePay: [PayService, Dispatch<PayService>];
  setIsValid: Dispatch<boolean>;
  clickSubmit: boolean;
  phoneMask: (phone:string | undefined) => string | undefined;
  errorHasPayment: boolean;
}

const Phone = ({
  controlsTemplatePay: [templatePay, setTemplatePay],
  setIsValid,
  clickSubmit,
  phoneMask,
  errorHasPayment,
}: Props) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<CountryPhoneInputValue>({ short: 'RU' });
  const [error, setError] = useState('num');
  const getFlag = (short: string) => {
    const data = require(`world_countries_lists/data/flags/24x24/${short?.toLowerCase()}.png`);
    // for dumi
    if (typeof data === 'string') {
      return data;
    }
    // for CRA
    return data.default;
  };
  const onChange = (value: string, key: keyof PayService) => {
    setTemplatePay({
      ...templatePay,
      [key]: value.replace(/[()\s-]/g, ''),
    });
  };

  useEffect(() => {
    setError(
      !templatePay.num ||
      (templatePay.num &&
        (templatePay.num?.length < 10 ||
          templatePay.num.length > 20 ||
          value.phone?.length !== 15))
        ? 'num'
        : templatePay.comment && templatePay.comment.length > 40
          ? 'comment'
          : ''
    );
  }, [templatePay, clickSubmit]);

  useEffect(() => {
    setIsValid(error === '');
  }, [error]);

  return (
    <ConfigProvider
      areaMapper={(area) => {
        return {
          ...area,

          emoji: (
            <img
              alt="flag"
              style={{ width: 18, height: 18, verticalAlign: 'sub' }}
              src={getFlag(area.short)}
            />
          ),
        };
      }}
      locale={en}
    >
      <div className={'bank-container'}>
        <span className={'bank-title'}>
          {t('payments.mobileBalanceReplenishment')}
        </span>
        {errorHasPayment && (
          <span style={{ marginTop: 4 }} className={'bank-input-label-error'}>
            {t('payments.errorAddPayment')}
          </span>
        )}
        <div className={'phone-form'}>
          <div className={'bank-container-input'}>
            <span className={'bank-input-label'}>
              {t('payments.enterMobileNumber')}
            </span>
            <CountryPhoneInput
              maxLength={14}
              placeholder={'X (XXX) XXX-XX-XX'}
              status={error === 'num' && clickSubmit ? 'error' : undefined}
              inline
              value={value}
              onChange={(v) => {
                v.phone = phoneMask(v.phone)
                setValue(v)
                if (v.phone && v.phone?.length <= 15) {
                  onChange(`+${value.code}${v.phone}`, 'num');
                }
              }}
            />
            {error === 'num' && clickSubmit && (
              <span className={'bank-input-label-error'}>
                {t('payments.incorrectMobileNumber')}
              </span>
            )}
          </div>
          <Comment
            templatePay={templatePay}
            error={error}
            clickSubmit={clickSubmit}
            onChange={onChange}
          />
        </div>
      </div>
    </ConfigProvider>
  );
};
export default Phone;

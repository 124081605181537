import React, { useState } from 'react';
import { useCustom } from '@pankod/refine-core';
import { BanksResponse } from '../../../pages/p2p/settings/payment/types';
import Icon from '@ant-design/icons';
import {
  bankIcons,
  IKeyIcons,
} from '../../../pages/p2p/dashboard/methodsPayment';
import Money from '../../../features/paymentComponents/modalContent/secondStep/Service/image/ЮMoney.svg';
import Qiwi from '../../../features/paymentComponents/modalContent/secondStep/Service/image/Qiwi.svg';
import Payeer from '../../../features/paymentComponents/modalContent/secondStep/Service/image/Payeer.svg';
import Adv from '../../../features/paymentComponents/modalContent/secondStep/Service/image/AdvCash.svg';
import Phone from '../../../features/paymentComponents/modalContent/secondStep/Service/image/phone.svg';
import { GetIcon } from '../../../features/paymentComponents/modalContent/secondStep/Card/getIcon';
import SBP from '../../../features/paymentComponents/modalContent/secondStep/Service/image/sbp.svg';
import { IBankTemp } from '../../../pages/p2p/dashboard/interface';
import { useTranslation } from 'react-i18next';
import { Banks, FPS, Phones, Transfer, Webs } from './components';

export const useGetPayments = (banks: IBankTemp[]) => {
  const [dataPaymentPick, setDataPick] = useState<any>([]);
  const { t } = useTranslation();
  const webServices = [
    {
      value: 'YooMoney',
      label: 'ЮMoney',
      icon: Money,
    },
    {
      value: 'QIWI',
      label: 'QIWI',
      icon: Qiwi,
    },
    {
      value: 'Payeer',
      label: 'Payeer',
      icon: Payeer,
    },
    {
      value: 'AdvCash',
      label: 'AdvCash',
      icon: Adv,
    },
  ];
  const {
    data: dataBank,
    isLoading: ABc,
    refetch,
  } = useCustom<BanksResponse>({
    url: '/p2p/api/banks',
    method: 'get',
    queryOptions: {
      onSuccess: (data) => {
        const result: any = [];
        result.push({
          title: t('dashboard.filters.method.all'),
          value: 'all',
          label: t('dashboard.filters.method.all'),
        });
        result.push({
          title: t('dashboard.filters.method.bankCard'),
          value: 'bankCardPayment',
          disabled: true,
          children: banks.map((itemMap) => ({
            title: (
              <Banks name={itemMap.name} icon={itemMap.name as IKeyIcons} />
            ),
            value: `${itemMap.id}card`,
            label: itemMap.name,
          })),
        });
        result.push({
          title: t('dashboard.filters.method.electron'),
          value: 'webServicePayment',
          disabled: true,
          children: webServices.map(({ icon, ...web }) => ({
            ...web,
            title: (
              <Webs
                icon={icon}
                name={
                  web.value === 'YooMoney'
                    ? t('dashboard.filters.method.YooMoney')
                    : web.label
                }
                alt={web.value}
              />
            ),
          })),
        });
        result.push({
          title: (
            <Phones>
              {t('dashboard.filters.method.other.methods.mobile')}
            </Phones>
          ),
          value: 'mobileBalancePayment',
          label: t('dashboard.filters.method.other.methods.mobile'),
        });
        result.push({
          title: t('dashboard.filters.method.other.methods.bank'),
          value: 'bankTransferPayment',
          disabled: true,
          children: data.data.data.map((itemMap) => {
            return {
              title: <Transfer name={itemMap.name} icon={itemMap.id} />,
              value: itemMap.id,
              label: itemMap.name,
            };
          }),
        });
        result.push({
          title: <FPS>{t('dashboard.filters.method.other.methods.fps')}</FPS>,
          value: 'fastPaymentSystemPayment',
          label: 'СБП',
        });
        setDataPick(result);
      },
    },
  });

  return { dataPaymentPick, refetch };
};

import { FieldData } from '../../../features/cryptocurrencyExchange/cryptoCurrencyExchange.p';
import { coinArr } from '../../../pages/coin-account/coinArr';
import {
  BTC_COIN_DATA_PROVIDER,
  ETH_COIN_DATA_PROVIDER,
  LTC_COIN_DATA_PROVIDER,
} from '../../../packages/keycloak-client/constants';
import { TCoinFormValues, TCoinName } from '../../../entities/coin';

export enum WithdrawStep {
  Closed,
  Main,
  PassphraseGuard,
}

export const DEFAULT_VALUES: FieldData[] = [
  {
    name: 'address',
    value: '',
  },
  {
    name: 'amount',
    value: '',
  },
  {
    name: 'passphrase',
    value: '',
  },
];

export const formBTCValues: TCoinFormValues = {
  coin: coinArr[0].id as TCoinName,
  network: coinArr[0].networks[0].id,
};

export const ModalWidth = 560;

export const CoinProviders = [BTC_COIN_DATA_PROVIDER, ETH_COIN_DATA_PROVIDER, LTC_COIN_DATA_PROVIDER];

import {FC} from 'react';
import styled from 'styled-components';
import {useNavigate} from '@pankod/refine-react-router-v6';
import {P2PAnnouncement, P2PAnnouncementType} from '../../types';
import {useTranslate} from '@pankod/refine-core';
import {Typography} from '@pankod/refine-antd';
import dayjs from 'dayjs';
import {getIconByCoin} from '../../../forms/coin-form/coinForm';
import {GreenArrow} from '../../../../shared/components/icons';
import PaymentTypes from '../paymentTypes/PaymentTypes';
import { AnnButtons } from '../announcementButtons/announcementButtons';
import paymentTypes from '../paymentTypes/PaymentTypes';

const {Text} = Typography;

interface IProps {
  announcement: P2PAnnouncement;
}

const Card: FC<IProps> = ({announcement}) => {
  const t = useTranslate();
  const navigate = useNavigate();

  return (
    <CardContainer $type={announcement.type}>
      <CardLinkOverlay
        onClick={() => navigate(announcement.id.toString())}
      ></CardLinkOverlay>
      <Vector>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path d="M0 32V0H32L0 32Z" fill="#413D50" fillOpacity="0.5"/>
        </svg>
      </Vector>
      <CardInfo>
        <CardInfoText>
          <PrimaryText>
            {t(`announcements.types.${announcement.type}`)}
          </PrimaryText>
          <SecondaryText>
            {`${t('from')} ${dayjs(announcement.created_at)
              .format('DD.MM.YYYY')
              .toString()}`}
          </SecondaryText>
        </CardInfoText>
        <CardInfoText>
          <Currency>
            {getIconByCoin(announcement.ccy.toLowerCase())}
            <PrimaryText>{announcement.ccy.toUpperCase()}</PrimaryText>

            <GreenArrow/>

            {getIconByCoin(announcement.fiat.toLowerCase())}
            <PrimaryText>{announcement.fiat.toUpperCase()}</PrimaryText>
          </Currency>
          <SecondaryText>
            {t(`announcements.status.${announcement.status}`)}
          </SecondaryText>
        </CardInfoText>
        <CardInfoText>
          <PrimaryText>
            {`${announcement.price} ${announcement.fiat}`}
          </PrimaryText>
          <SecondaryText>{t('announcements.params.priceCoin')}</SecondaryText>
        </CardInfoText>
        <ValueInfo>
          <CardInfoText>
            <PrimaryText>
              {`${(+announcement.total_sum)?.toFixed(8)} ${announcement.ccy}`}
            </PrimaryText>
            <SecondaryText $color="#FBFBFF">
              {t('announcements.totalSum')}
            </SecondaryText>
          </CardInfoText>

          <PrimaryText> / </PrimaryText>

          <CardInfoText>
            <PrimaryText $color="#767687">
              {`${(+announcement.total_sum - +announcement.sum_left)?.toFixed(
                8
              )} ${announcement.ccy}`}
            </PrimaryText>
            <SecondaryText>{t('announcements.sumLeft')}</SecondaryText>
          </CardInfoText>
        </ValueInfo>
        <PaymentTypes
          paymentTypes={
            announcement.buy_payment_info?.length
              ? announcement.buy_payment_info
              : announcement.payment_info?.map((i) => ({
                ...i.payment_details[0],
                payment_type: i.payment_type,
              }))
          }
        />
      </CardInfo>
      <AnnButtons
        status={announcement.status}
        disabled={announcement.orders_active_count > 0}
        itemId={announcement.id}
        itemType={announcement.type}
        itemCcy={announcement.ccy}
        itemFiat={announcement.fiat}
      />
    </CardContainer>
  );
};

export default Card;

const CardLinkOverlay = styled.div`
  position: absolute;
  width: 85%;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  cursor: pointer;
`;

const CardContainer = styled.div<{ $type: P2PAnnouncementType }>`
  min-height: 100px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 24px;

  border-radius: 8px;
  border: 1px solid #474755;

  border-left: 3px solid ${(props) => (props.$type === 'buy' ? '#088473' : '#7B59FD')};

  background: #1c1c27;

  & svg {
    width: 16px;
    height: 16px;
  }

  position: relative;
`;

const Vector = styled.div`
  width: 32px;
  height: 32px;

  position: absolute;

  top: 0;
  left: 0;
`;

const CardInfo = styled.div`
  display: flex;
  gap: 24px;
  min-height: 100px;
  align-items: center;
`;

const ValueInfo = styled.div`
  display: flex;
  gap: 2px;
`;

const CardInfoText = styled.div`
  height: 42px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PrimaryText = styled(Text)<{ $color?: string }>`
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => (props.$color ? props.$color : '#FBFBFF')};
`;

const SecondaryText = styled(Text)<{ $color?: string }>`
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => (props.$color ? props.$color : '#767687')};
`;

const Currency = styled.div`
  height: 25px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

import React from 'react';
import { styled } from 'styled-components';
import { Logo } from '../../../../pages/paymentLogout/icon';
import Icon from '@ant-design/icons';
import { t } from 'i18next';
import './Footers.css';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

const Footer = () => {
  const screens = useBreakpoint();
  const isMobile = !screens.md ? true : false;
  return (
    <FooterWrapper translate="no" $mobile={isMobile}>
      <FooterContent $mobile={isMobile}>
        <Icon
          style={{ height: 'fit-content' }}
          component={() => Logo(undefined, 132, 32)}
        />
        <FooterTextBlock>
          <FooterTextBlockTitle>{t('siteCreators')}</FooterTextBlockTitle>
          <FooterTextBlockText>EDENEX</FooterTextBlockText>
        </FooterTextBlock>
        <FooterTextBlock>
          <FooterTextBlockTitle>{t('sitePartners')}</FooterTextBlockTitle>
          <FooterTextBlockText>TradingView</FooterTextBlockText>
          <FooterTextBlockLinkInfo
            dangerouslySetInnerHTML={{ __html: t('sitePartnersInfo') }}
          />
        </FooterTextBlock>
        <FooterDate $mobile={isMobile}>{new Date().getFullYear()}</FooterDate>
      </FooterContent>
    </FooterWrapper>
  );
};

export default Footer;

const FooterWrapper = styled.div<{ $mobile: boolean }>`
  padding: ${({ $mobile }) => ($mobile ? '32px 16px' : '32px')};
  border-top: 1px solid #413d5080;
  margin-top: ${({ $mobile }) => ($mobile ? '16px' : '32px')};
`;
const FooterContent = styled.div<{ $mobile: boolean }>`
  display: flex;
  flex-direction: ${({ $mobile }) => ($mobile ? 'column' : 'row')};
  gap: ${({ $mobile }) => ($mobile ? '24px' : '56.5px')};
`;
const FooterTextBlock = styled.div`
  max-width: 476px;
`;
const FooterTextBlockTitle = styled.div`
  font-size: 12px;
  color: #767687;
  margin-bottom: 2px;
`;
const FooterTextBlockText = styled.div`
  color: #fff;
  font-size: 12px;
  margin-bottom: 8px;
`;
const FooterTextBlockLinkInfo = styled.div`
  font-size: 10px;
  color: #767687;
  vertical-align: top;
`;
const FooterDate = styled.div<{ $mobile: boolean }>`
  margin-left: ${({ $mobile }) => ($mobile ? '0px' : 'auto')};
  font-size: 12px;
  color: #fbfbff;
`;

import {useTranslate} from "@pankod/refine-core"
import MyDealsCard from "pages/p2p/myDeals/cards/my-deals-card/myDealsCard"
import {IGetListData} from "pages/p2p/myDeals/cards/myTrades.p"
import EmptyComponent from "shared/components/ui/emptyComponent/EmptyComponent"
import VisibilityObserver from "shared/hooks/on-visible/observer"

export const DealsList = ({isLoading, deals, type, handleNextPage}:
                            {
                              isLoading: boolean,
                              deals: IGetListData[],
                              type: 'buy' | 'sell' | 'all',
                              handleNextPage: (visibility: boolean) => void
                            }) => {
  const t = useTranslate();
  const getCardProps = (item: IGetListData, index: number) => ({
    index,
    type,
    item,
    key: `my-deal-card-${item.id}`,
  });
  return (
    <div>
      {!isLoading && deals.length
        ? deals.map((item, index) =>
            index === deals.length - 1 ? (
              <VisibilityObserver
                onChangeVisibility={handleNextPage}
                options={{ rootMargin: '100px' }}
                key={`my-deal-card-${index}`}
              >
                <MyDealsCard {...getCardProps(item, index)} />
              </VisibilityObserver>
            ) : (
              <MyDealsCard {...getCardProps(item, index)} />
            )
          )
        : null}
      {!deals?.length && (
        <EmptyComponent text={t('emptyComponent.noDealsFound')}/>
      )}
    </div>
  );
};

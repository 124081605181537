import { Button } from '@pankod/refine-antd';
import { useState } from 'react';
import Modal from 'shared/components/ui/modal';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../../useAuthState';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { ErrorBoundary } from '../../../app/ErrorBoundary';
import { useCookiesCustom } from '../../../shared/hooks/useCookieCustom';

const AppealModalPage = () => {
  const navigate = useNavigate();
  const {removeCookie} = useCookiesCustom();
  const { t } = useTranslation();

  const [openAppealModal, setOpenAppealModal] = useState(false);

  const handleOnOpenAppealModal = () => {
    setOpenAppealModal(true);
  };

  const handleOnCloseAppealModal = () => {
    setOpenAppealModal(false);
  };

  return (
    <ErrorBoundary
      logout={() => {}}
      navigate={navigate}
      removeCookie={removeCookie}
    >
      <Button type="primary" onClick={handleOnOpenAppealModal}>
        {t('buttons.openModalAppealWindow')}
      </Button>
      <Modal.AppealModal
        open={openAppealModal}
        onCancel={handleOnCloseAppealModal}
        onOk={() => {}}
      />
    </ErrorBoundary>
  );
};

export { AppealModalPage };

import {DatePicker, MenuProps, Skeleton, Typography} from 'antd';
import {RangePickerProps} from 'antd/es/date-picker';
import React, {useMemo} from 'react';
import {LogicalFilter, useTranslate} from '@pankod/refine-core';
import {RangeValue} from 'features/myOrders/myOrders.p';
import {Badge, Icon, Radio, Space} from '@pankod/refine-antd';
import {CaretIcon, DashCircle,} from 'shared/components/ui/assets/svg/svgIcons';
import dayjs from 'dayjs';
import CustomSelect from '../../../../shared/components/ui/select';
import {ICoinForRender} from '../../../../pages/p2p/myAnnouncements';
import {AllCoin} from '../../../../shared/components/icons';


const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  // Can not select days after today
  return current && current > dayjs().endOf('day');
};

interface IAnnFilters {
  isLoading: boolean;
  statusItemsMenu: MenuProps;
  coinsItemsMenu: ICoinForRender[];
  currentStatus: string | JSX.Element | undefined;
  currentCoin: string | JSX.Element | undefined;
  startDate: Date;
  setCurrentStatus: (arg: string) => void;
  endDate: Date;
  setFilter: (filter: LogicalFilter) => void;
  dates: RangeValue;
  value: RangeValue;
  setValue: (arg: RangeValue) => void;
  setDates: (arg: RangeValue) => void;
  setCurrentCoin: (value: string) => void;
  tab: string;
  setTab: (value: string) => void;
  setClearFilters: () => void;
}

const AnnFilters: React.FC<IAnnFilters> = ({
                                             isLoading,
                                             coinsItemsMenu,
                                             statusItemsMenu,
                                             currentStatus,
                                             currentCoin,
                                             setCurrentStatus,
                                             startDate,
                                             endDate,
                                             setFilter,
                                             dates,
                                             value,
                                             setDates,
                                             setValue,
                                             setCurrentCoin,
                                             tab,
                                             setTab,
                                             setClearFilters,
                                           }) => {
  const t = useTranslate();
  const changeStatus = (e: any) => {
    setCurrentStatus(e.target.value);
  };
  const handleClearFilter = () => {
    setCurrentCoin('all');
    setDates(null);
    setValue(null);
    setCurrentStatus('all');
    setTab('all');
    setClearFilters();
  };
  const getCountFilter = useMemo(() => {
    return (
      (currentCoin === 'all' ? 0 : 1) +
      (dates === null ? 0 : 1) +
      (currentStatus === 'all' ? 0 : 1) +
      (tab === 'all' ? 0 : 1)
    );
  }, [currentCoin, dates, value, currentStatus, tab]);
  return (
    <>
      <Skeleton
        active={isLoading}
        loading={isLoading}
        title={false}
        className={'custom-skeleton-elements'}
        paragraph={{
          rows: 1,
          width: '100%',
        }}
      >
        <div className={'flex-row gap-16 position-absolute filters-container'}>
          <CustomSelect
            colorBgContainer={'#2B2B36'}
            style={{width: '260px'}}
            defaultValue={coinsItemsMenu[0]}
            value={currentCoin}
            setValue={(value) => setCurrentCoin(value)}
            dropdownRender={(menu) => <div className={'my-ann-dd'}>{menu}</div>}
            data={[
              {
                label: (
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: '400',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '32px',
                      }}
                    >
                      {AllCoin('#FBFBFF', 24)}
                    </div>
                    <p style={{margin: 0}}>{t('deals.allCoins')}</p>
                  </div>
                ),
                value: 'all',
              },
              ...(coinsItemsMenu.map((item) => ({
                label: (
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: '400',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      width: '100%',
                    }}
                  >
                    {
                      <div
                        style={{
                          display: 'flex',
                          height: '32px',
                          alignItems: 'center',
                        }}
                      >
                        {item?.icon}
                      </div>
                    }
                    <div
                      className={'flex-column gap-4'}
                      style={{maxHeight: 56, height: '100%'}}
                    >
                      <div
                        style={{height: 'fit-content'}}
                        className={'text-14px'}
                      >
                        {item.label}
                      </div>
                      <div className={'text-14px gray deals-select-desc'}>
                        {item.value !== 'all' && item.value}
                      </div>
                    </div>
                  </div>
                ),
                value: item?.value,
              })) ?? []),
            ]}
          />

          <DatePicker.RangePicker
            suffixIcon={<Icon component={() => CaretIcon()}/>}
            clearIcon={<Icon component={() => DashCircle()}/>}
            separator={<>-</>}
            disabledDate={disabledDate}
            value={value || dates || undefined}
            onCalendarChange={(val) => {
              setDates(val);
            }}
            onChange={(val) => {
              setValue(val);
              if (val === null) {
                setFilter({
                  field: 'created_at',
                  operator: 'contains',
                  value: '',
                });
              } else {
                setFilter({
                  field: 'created_at',
                  operator: 'between',
                  value: val.map((date) => date?.format('YYYY-MM-DD')),
                });
              }
            }}
          />
          <div
            className="flex-row items-center"
            style={{paddingLeft: '20px'}}
          >
            <Radio.Group
              value={currentStatus}
              onChange={(e) => changeStatus(e)}
            >
              <Space size={24} direction="horizontal">
                <Radio key={'all'} value={'all'}>
                  <Typography style={{fontSize: '14px', color: '#FBFBFF'}}>
                    {t('announcements.filters.all')}
                  </Typography>
                </Radio>
                <Radio key={'sell'} value={'sell'}>
                  <Typography style={{fontSize: '14px', color: '#FBFBFF'}}>
                    {t('announcements.filters.sell')}
                  </Typography>
                </Radio>
                <Radio key={'buy'} value={'buy'}>
                  <Typography style={{fontSize: '14px', color: '#FBFBFF'}}>
                    {t('announcements.filters.buy')}
                  </Typography>
                </Radio>
              </Space>
            </Radio.Group>
          </div>
          <div
            className={'flex align-items-center'}
            style={{marginLeft: 'auto'}}
          >
            <Typography.Link
              onClick={handleClearFilter}
              style={{marginRight: '8px'}}
            >
              {t('dashboard.filters.clear')}
            </Typography.Link>
            <Badge
              count={getCountFilter}
              showZero
              color="linear-gradient(75.86deg, #088473 6.84%, #0EC6AD 107.11%)"
            />
          </div>
        </div>
      </Skeleton>
    </>
  );
};

export default AnnFilters;

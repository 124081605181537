import * as React from 'react';
import {FC} from 'react';
import {useRelay} from '../../../../shared/hooks/use-relay';
import {useTranslation} from 'react-i18next';
import DepositModal from '../DepositModal/DepositModal';
import WithdrawModal from '../WithdrawModal/WithdrawModal';
import styled from 'styled-components';
import {Button} from '@pankod/refine-antd';
import {TIOButtonType} from '../../types';

interface IProps {
  type: TIOButtonType;
}

const IOButton: FC<IProps> = ({type}) => {
  const {t} = useTranslation();

  const modal = useRelay();

  const handleClick = () => {
    modal.enable();
  };

  const getButtonText = () =>
    type === 'deposit'
      ? t('wallet.coin.deposit.button')
      : t('wallet.coin.withdraw.button');

  const getModal = () =>
    type === 'deposit' ? (
      <DepositModal onCancel={modal.disable} isOpen={modal.state}/>
    ) : (
      <WithdrawModal onCancel={modal.disable} isOpen={modal.state}/>
    );

  return (
    <>
      <StyledIOButton $type={type} onClick={handleClick}>
        {getButtonText()}
      </StyledIOButton>
      {getModal()}
    </>
  );
};

export default IOButton;

const StyledIOButton = styled(Button)<{ $type: TIOButtonType }>`
  height: 40px;
  width: 100%;

  color: white;
  background: ${(props) =>
    props.$type === 'deposit'
      ? 'linear-gradient(   75.86deg,   #6320e6 6.84%,  #7b59fd 99.53%  )'
      : 'linear-gradient(80.91deg, #088473 0%, #12BFA8 100%)'} !important;

  border: none;

  &:hover {
    background: ${(props) =>
      props.$type === 'deposit'
        ? 'linear-gradient(75.86deg, #7D50FF 6.84%, #7785FF 107.11%)'
        : 'linear-gradient(80.91deg, #16AA96 0%, #20D9C0 100%)'} !important;
    color: white !important;
    border: none;
  }

  &:active {
    background: ${(props) =>
      props.$type === 'deposit'
        ? 'linear-gradient(75.86deg, #4603CA 6.84%, #742EFD 99.53%)'
        : 'linear-gradient(80.91deg, #097163 0%, #07AB95 100%)'} !important;
    color: white !important;
  }
`;

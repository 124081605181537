import React, {Dispatch, lazy, useState} from 'react';
import { CARD_COMPONENTS as Components } from './_styles';
import { CardProps, Typography } from 'antd';
import Icon from '@ant-design/icons';
import { t } from 'i18next';

const CreateSecretModal = lazy(() =>
  import('./create-secret-modal').then((module) => ({
    default: module.CreateSecretModal,
  }))
);

const BasicTag = lazy(() =>
  import('./basic-tag').then((module) => ({
    default: module.BasicTag,
  }))
);

const DropdownSecretCard = lazy(() =>
  import('./dropdown-secret').then((module) => ({
    default: module.DropdownSecretCard,
  }))
);

const CardExitIcon = lazy(() =>
  import('./icons').then((module) => ({
    default: module.CardExitIcon,
  }))
);

const CardExitIconHover = lazy(() =>
  import('./icons').then((module) => ({
    default: module.CardExitIconHover,
  }))
);

const DisconnectSecretModal = lazy(() =>
  import('./disconnect-secret-modal').then((module) => ({
    default: module.DisconnectSecretModal,
  }))
);

interface ISecretCardProps extends CardProps {
  isMenu?: boolean; // флаг для отображения меню у карточки
  isExtendedMenu?: boolean; // флаг для отображения выпадающего меню с двумя пунктами или одним
  isConnect?: boolean;
  setSecretIsConnected:Dispatch<boolean>
}

const SecretCard = ({
  isMenu,
  isConnect,
  isExtendedMenu,
  setSecretIsConnected
}: ISecretCardProps): JSX.Element => {
  const [isShowModalSecret, setIsShowModalSecret] = useState(false);
  const [showEditModalSecret, setShowEditModalSecret] = useState(false);
  const [showTurnOffSecretModal, setShowTurnOffSecretModal] = useState(false);
  const [showDisconnectModalSecret, setShowDisconnectModalSecret] =
    useState(false);
  const [isCloseHover, setIsCloseHover] = useState(false);

  const handleHoverBtn = () => {
    setIsCloseHover(false);
  };

  const handleNotHoverBtn = () => {
    setIsCloseHover(true);
  };

  const ShowModal = () => {
    setIsShowModalSecret(true);
  };

  const showDisconnectModal = () => {
    setShowDisconnectModalSecret(true);
  };

  const emptyLink = () => {};

  return (
    <>
      <div onClick={isConnect === true ? emptyLink : ShowModal}>
        <Components.Shadow>
          <Components.CardWrapper>
            <Components.Card>
              <Components.TextBlock>
                <Components.CardHeader>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '4px',
                    }}
                  >
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      {t('p2p.settings.secret.title')}
                    </Typography.Title>
                    <Components.Tags>
                      {isConnect === true ? (
                        <BasicTag
                          text={t('p2p.settings.secret.statuses.connected')}
                          isConnected={true}
                        />
                      ) : (
                        <BasicTag
                          text={t('p2p.settings.secret.statuses.disconnected')}
                          isConnected={false}
                        />
                      )}
                    </Components.Tags>
                  </div>
                  {isMenu ? (
                    <DropdownSecretCard
                      isExtendedMenu={isExtendedMenu}
                      showEditModalSecret={showEditModalSecret}
                      setShowEditModalSecret={setShowEditModalSecret}
                      showTurnOffSecretModal={showTurnOffSecretModal}
                      setShowTurnOffSecretModal={setShowTurnOffSecretModal}
                    />
                  ) : null}
                  {isConnect ? (
                    <div
                      onMouseOver={handleHoverBtn}
                      onMouseOut={handleNotHoverBtn}
                      onClick={showDisconnectModal}
                    >
                      {isCloseHover ? (
                        <Icon component={CardExitIcon} />
                      ) : (
                        <Icon component={CardExitIconHover} />
                      )}
                    </div>
                  ) : undefined}
                </Components.CardHeader>
                <Typography.Text style={{ lineHeight: '17px' }}>
                  {t('p2p.settings.secret.description')}
                </Typography.Text>
              </Components.TextBlock>
            </Components.Card>
          </Components.CardWrapper>
        </Components.Shadow>
      </div>

      <CreateSecretModal
        setSecretIsConnected={setSecretIsConnected}
        showModalSecret={isShowModalSecret}
        setShowModalSecret={setIsShowModalSecret}
      />

      <DisconnectSecretModal
        setSecretIsConnected={setSecretIsConnected}
        showDisconnectModalSecret={showDisconnectModalSecret}
        setShowDisconnectModalSecret={setShowDisconnectModalSecret}
      />
    </>
  );
};


export { SecretCard };

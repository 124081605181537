import React from 'react';
import { Skeleton } from '@pankod/refine-antd';
import { Typography } from 'antd';
import { useTranslate } from '@pankod/refine-core';

interface IClearMyOrdersFiltersButton {
  isLoading: boolean;
  filtersLen: number | undefined;
  onButtonClick: () => void;
}

const ClearMyOrdersFiltersButton: React.FC<IClearMyOrdersFiltersButton> = ({
  isLoading,
  filtersLen,
  onButtonClick,
}) => {
  const t = useTranslate();

  return (
    <>
      <div className={'flex-row justify-content-flex-end width-100-percent'}>
        {isLoading ? (
          <Skeleton.Button
            active={isLoading}
            size={'large'}
            style={{ width: 162 }}
          />
        ) : (
          <div className={'flex-row align-items-center gap-8'}>
            <Typography.Text
              style={{ color: '#FBFBFF', fontWeight: 700, cursor: 'pointer' }}
              onClick={onButtonClick}
            >
              {t('myOrders.button.clearFilter')}
            </Typography.Text>
            {filtersLen ? (
              <Typography.Text
                className={'flex-row justify-content-center align-items-center'}
                style={{
                  background:
                    'linear-gradient(75.86deg, #088473 6.84%, #0EC6AD 107.11%)',
                  borderRadius: '20px',
                  width: '20px',
                  height: '16px',
                  color: '#FBFBFF',
                  fontSize: '12px',
                  lineHeight: '15px',
                }}
              >
                {filtersLen}
              </Typography.Text>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ClearMyOrdersFiltersButton;

import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {IP2POrderUser} from 'pages/p2p/myDeals/cards/myTrades.p';
import {CenterBox, RoundShape, SmallText} from './card';

type Props = {
  user: IP2POrderUser;
}

const UserTooltip: React.FC<Props> = (props) => {
  const {t} = useTranslation();
  return (
    <div className={'flex-column'} style={{gap: 12,}}>
      <div className={'flex-column'} style={{gap: 8,}}>
        <GapCenterBox className={'flex-row'} gap={8}>
          <LargeRoundShape color='linear-gradient(76deg, #6320E5 6.84%, #7B59FD 99.53%)'/>
          <SmallText>{t("myDealsCard.tooltip.totalAmount") + " " + props.user.total_orders_amount}</SmallText>
        </GapCenterBox>
        <CenterBox className={'flex-row'} style={{justifyContent: 'center'}}>
          <SmallRoundShape color='#58BF92'/>
          <SmallText>{t('myDealsCard.tooltip.success') + " " + props.user.success_orders_amount}</SmallText>
          <SmallText style={{margin: '0 4px'}}>/</SmallText>
          <SmallRoundShape color='#F16063'/>
          <SmallText>{t('myDealsCard.tooltip.canceled') + " " + props.user.canceled_orders_amount}</SmallText>
        </CenterBox>
      </div>
      <GapCenterBox className={'flex-row'} gap={8}>
        <LargeRoundShape color='linear-gradient(76deg, #6320E5 6.84%, #7B59FD 99.53%)'/>
        <SmallText>{t("myDealsCard.tooltip.percentage") + " " + (props.user.total_orders_amount !== 0 ? (props.user.success_orders_amount * 100 / props.user.total_orders_amount).toFixed(2) + '%' : '0.00%')}</SmallText>
      </GapCenterBox>
      <GapCenterBox className={'flex-row'} gap={8}>
        <LargeRoundShape color='linear-gradient(76deg, #6320E5 6.84%, #7B59FD 99.53%)'/>
        <SmallText>{t("myDealsCard.tooltip.agentAmount") + " " + props.user.unique_agents_amount}</SmallText>
      </GapCenterBox>
    </div>
  );
};

const SmallRoundShape = styled(RoundShape)`
  height: 4px;
  width: 4px;
`

const GapCenterBox = styled(CenterBox)<{ gap: number }>`
  gap: ${(props) => props.gap}px;
`

const LargeRoundShape = styled(RoundShape)`
  height: 8px;
  width: 8px;
`

export default UserTooltip;

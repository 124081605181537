import * as React from 'react';
import { Icons, QRCode, Typography, Tooltip, Spin } from '@pankod/refine-antd';
import { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from '../../../../shared/components/ui/button';
import { copyText } from '../../../../shared/helpers/copyText';
import { useWalletState } from '../../../../useWalletState';
import { WalletIcon } from '../../../../shared/components/icons';
import { PencilSquare } from '../../../../shared/components/ui/assets/svg/PencilSquare';
import { ReactComponent as Icon } from './icon.svg';

const { Text } = Typography;

interface IDepositQRCode {
  createWallet?: () => void;
  handleShowError?: () => void;
  walletIsPending?: boolean;
  setWalletIsPending?: (value: boolean) => void;
  failedGenerateWallet?: boolean;
}

const DepositQRCode: FC<IDepositQRCode> = ({
  createWallet = () => {},
  handleShowError = () => {},
  walletIsPending = false,
  setWalletIsPending = () => {},
  failedGenerateWallet,
}) => {
  const { t } = useTranslation();

  const [copied, setCopied] = useState(false);

  const selectedCoin = useWalletState((state) => state.selectedCoin);
  const wallet = useWalletState((state) => state.wallet);
  const selectedCoinNetwork = useWalletState(
    (state) => state.selectedCoinNetwork
  );

  const addr = wallet?.wallet ?? '';
  const checkCoinAndNetwork = !!selectedCoin?.networks?.length
    ? !!selectedCoin && !!selectedCoinNetwork
    : !!selectedCoin;

  const handleCopiedAddress = () => {
    copyText(addr).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    });
  };
  const handleCreateWallet = async () => {
    setWalletIsPending(true);
    await createWallet();
  };

  const getQRText = () => {
    return !checkCoinAndNetwork
      ? t('wallet.coin.deposit.scanQR.chooseCurrency')
      : addr
      ? t('wallet.coin.deposit.scanQR.walletExist')
      : t('wallet.coin.deposit.scanQR.walletNotExist');
  };

  const createButtonInner = () => {
    const handleClick = !checkCoinAndNetwork
      ? handleShowError
      : addr
      ? handleCopiedAddress
      : handleCreateWallet;
    const text = !checkCoinAndNetwork
      ? t('wallet.coin.deposit.walletAddress')
      : addr || t('wallet.coin.deposit.generateWalletAddress');
    const icon = !checkCoinAndNetwork ? (
      WalletIcon('rgba(251, 251, 255, 1)')
    ) : addr ? (
      <Icon />
    ) : (
      <PencilSquare />
    );

    const onClick =
      !walletIsPending && !failedGenerateWallet ? handleClick : () => {};

    return (
      <WalletAddrButton
        disabled={walletIsPending}
        $failedGenerate={!!failedGenerateWallet}
        onClick={onClick}
      >
        <WalletAddrButtonText $failedGenerate={!!failedGenerateWallet}>
          {walletIsPending ? (
            <Spin
              indicator={
                <Icons.LoadingOutlined
                  style={{ fontSize: 24, color: 'rgba(251, 251, 255, 1)' }}
                  spin
                />
              }
            />
          ) : failedGenerateWallet ? (
            t('wallet.coin.deposit.failedGenerate')
          ) : (
            text
          )}
        </WalletAddrButtonText>
        <Tooltip
          title={t('wallet.tooltips.copied')}
          placement="bottom"
          trigger={'contextMenu'}
          open={copied}
          overlayInnerStyle={{ width: 120 }}
        >
          {!walletIsPending && !failedGenerateWallet && icon}
        </Tooltip>
      </WalletAddrButton>
    );
  };

  return (
    <Container>
      <QRCodeContainer>
        <QRCodeStyled
          value={addr ? addr : 'xIh8JOScTtjNnKAoHPJjKDiMF0wMpOfG'}
          size={90}
          bordered={false}
          style={{
            marginRight: '24px',
          }}
          $isBlur={!addr}
        />
        <QRCodeContainerText>
          <ScanQRText>{getQRText()}</ScanQRText>
          {createButtonInner()}
        </QRCodeContainerText>
      </QRCodeContainer>
      <DepositParameters>
        <DepositParameter>
          <DepositParameterText $isTitle>
            {t('wallet.coin.deposit.minimumDeposit')}
          </DepositParameterText>
          <DepositParameterText>
            {parseInt(selectedCoin?.minimal_input_amount ?? '0', 10).toFixed(
              2
            ) ?? '-'}{' '}
            {selectedCoin?.currency.toUpperCase()}
          </DepositParameterText>
        </DepositParameter>
        <DepositParameter>
          <DepositParameterText $isTitle>
            {t('wallet.coin.deposit.commission')}
          </DepositParameterText>
          <DepositParameterText>
            {selectedCoin
              ? parseInt(selectedCoin?.input_commission ?? 0, 2).toFixed(8) +
                ' ' +
                selectedCoin?.currency.toUpperCase()
              : '-'}
          </DepositParameterText>
        </DepositParameter>
      </DepositParameters>
    </Container>
  );
};

export default DepositQRCode;

const Container = styled.div`
  min-height: 160px;
`;

const QRCodeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const QRCodeContainerText = styled.div`
  height: 90px;

  width: 80%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const QRCodeStyled = styled(QRCode)<{ $isBlur: boolean }>`
  filter: ${({ $isBlur }) => ($isBlur ? 'blur(4px)' : 'none')};
`;

const ScanQRText = styled(Text)`
  color: #fbfbff;
  font-size: 14px;
`;

const WalletAddrButton = styled(Button)<{ $failedGenerate: boolean }>`
  height: 40px !important;
  background: linear-gradient(80.91deg, #088473 0%, #12bfa8 100%);

  &:disabled {
    background: linear-gradient(80.91deg, #088473 0%, #12bfa8 100%) !important;
  }

  & span {
    display: flex;
    align-items: center;
  }

  &:hover {
    background: linear-gradient(80.91deg, #16aa96 0%, #20d9c0 100%);
  }

  &:active {
    background: linear-gradient(80.91deg, #097163 0%, #07ab95 100%);
  }

  & .ant-typography {
    display: flex;
  }

  ${(props) =>
    props.$failedGenerate &&
    css`
      background: rgba(43, 43, 54, 1);
      cursor: default;

      &:hover {
        background: rgba(43, 43, 54, 1) !important;
      }

      &:active {
        background: rgba(43, 43, 54, 1);
      }
    `}
}
`;

const WalletAddrButtonText = styled.div<{ $failedGenerate: boolean }>`
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 10px;
  ${(props) =>
    props.$failedGenerate &&
    css`
      margin-right: 0;
      max-width: 100%;
      color: rgba(118, 118, 135, 1);
      white-space: nowrap;
      text-overflow: unset;
      overflow: visible;
    `}
`;

const DepositParameters = styled.div`
  padding: 16px 0;
`;

const DepositParameter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

const DepositParameterText = styled(Text)<{ $isTitle?: boolean }>`
  font-size: 14px;
  color: ${(props) => (props.$isTitle ? '#767687' : '#FBFBFF')};
`;

import { LogicalFilter, useTranslate } from '@pankod/refine-core'
import { IGetListData } from '../../features/myOrders/myOrders.p'

interface IUseGetTranslateItemsMyOrder{
  setStatusKey: (text:string) => void
  setFilter: (filter:LogicalFilter) => void
  list: IGetListData[]
}

export const useGetWalletTranslatedItems = (data: IUseGetTranslateItemsMyOrder) => {
  const t = useTranslate();
  const {setFilter, setStatusKey} = data

  const statusItemsInput = [
    {
      key: 'all',
      label: t('wallet.filters.status.all'),
      onClick: () => {
        setStatusKey(t('wallet.filters.status.all'));
        setFilter({
          field: 'status',
          operator: 'contains',
          value: '',
        });
      },
    },
    {
      key: 'processing',
      label: t('wallet.filters.status.processingInput'),
      onClick: () => {
        setStatusKey(t('wallet.filters.status.processingInput'));
        setFilter({
          field: 'status',
          operator: 'eq',
          value: `processing`,
        });
      },
    },
    {
      key: 'canceled',
      label: t('wallet.filters.status.canceled'),
      onClick: () => {
        setStatusKey(t('wallet.filters.status.canceled'));
        setFilter({
          field: 'status',
          operator: 'eq',
          value: `canceled`,
        });
      },
    },
    {
      key: 'completed',
      label: t('wallet.filters.status.completed'),
      onClick: () => {
        setStatusKey(t('wallet.filters.status.completed'));
        setFilter({
          field: 'status',
          operator: 'eq',
          value: `completed`,
        });
      },
    },
  ];
  const statusItemsOutput = [
    {
      key: 'all',
      label: t('wallet.filters.status.all'),
      onClick: () => {
        setStatusKey(t('wallet.filters.status.all'));
        setFilter({
          field: 'status',
          operator: 'contains',
          value: '',
        });
      },
    },
    {
      key: 'processing',
      label: t('wallet.filters.status.processingOutput'),
      onClick: () => {
        setStatusKey(t('wallet.filters.status.processingOutput'));
        setFilter({
          field: 'status',
          operator: 'eq',
          value: `processing`,
        });
      },
    },
    {
      key: 'canceled',
      label: t('wallet.filters.status.canceled'),
      onClick: () => {
        setStatusKey(t('wallet.filters.status.canceled'));
        setFilter({
          field: 'status',
          operator: 'eq',
          value: `canceled`,
        });
      },
    },
    {
      key: 'completed',
      label: t('wallet.filters.status.completed'),
      onClick: () => {
        setStatusKey(t('wallet.filters.status.completed'));
        setFilter({
          field: 'status',
          operator: 'eq',
          value: `completed`,
        });
      },
    },
  ];
  return {
    statusItemsInput,
    statusItemsOutput
  }
}

import * as React from 'react';
import { FC } from 'react';
import { Divider, Modal, Typography } from '@pankod/refine-antd';
import DepositQRCode from '../../../../features/paymentComponents/modalContent/depositQrCode/DepositQRCode';
import { ModalWidth } from '../../constants';
import { useTranslation } from 'react-i18next';
import { TCoinName } from '../../../../entities/coin';

const { Text } = Typography;

interface IProps {
  coin: TCoinName;
  coinName: string;
  isOpen: boolean;
  onCancel: () => void;
}

const CoinDepositModal: FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { coinName, isOpen, onCancel } = props;

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      title={coinName}
      width={ModalWidth}
    >
      <DepositQRCode />
      <Divider />
      <Text>{t('wallet.coin.deposit.modalDescription')}</Text>
    </Modal>
  );
};

export default CoinDepositModal;

import {GetIcon as getBankIcon,} from '../../features/paymentComponents/modalContent/secondStep/Card/getIcon';
import MobilePaymentIcon from '../../features/paymentComponents/modalContent/firstStep/image/phone.svg';
import CashPaymentIcon from '../../features/paymentComponents/CardContainer/card/image/monetization_on.svg';
import SBP from '../../features/paymentComponents/CardContainer/card/image/SBP.svg';
import {IPaymentType, PaymentTypeEnum} from 'features/p2p/createOrder/types';
import {IPaymentsType} from "../../pages/p2p/dashboard/interface";

export const getPaymentInfoData = (userPaymentItem: IPaymentType, translate: (
  key: string,
  options?: any,
  defaultMessage?: string,
) => string, iconsData: IPaymentsType[] = []) => {
  switch (userPaymentItem.payment_type) {
    case PaymentTypeEnum.bankCardPayment:
      return userPaymentItem.payment_details.map((item) => ({
        subtext: item.commentary,
        title: item.bank_name
          ? item.bank_name
          : translate('payments.paymentBankCard'),
        value: item.card_identifier,
        icon: getBankIcon(item.bank_id, iconsData),
      }));
    case PaymentTypeEnum.mobileBalancePayment:
      return userPaymentItem.payment_details.map((item) => ({
        subtext: item.commentary,
        title: translate('payments.mobileBalanceReplenishment'),
        value: item.phone,
        icon: MobilePaymentIcon,
      }));
    case PaymentTypeEnum.cashPayment:
      return userPaymentItem.payment_details.map((item) => ({
        subtext: item.commentary,
        title: translate('payments.cashes'),
        value: '',
        icon: CashPaymentIcon,
      }));
    case PaymentTypeEnum.fastPaymentSystemPayment:
      return userPaymentItem.payment_details.map((item) => ({
        subtext: item.commentary,
        title: translate('payments.sbp'),
        value: item.phone,
        icon: SBP,
      }));
    case PaymentTypeEnum.webServicePayment:
      return userPaymentItem.payment_details.map(item => ({
          subtext: item.commentary,
          title: item.web_service_name,
          value: item.web_service_account_id,
          icon: `data:image/svg+xml;base64,${iconsData
            .find(item => item.payment_type === PaymentTypeEnum.webServicePayment)
            ?.items
            ?.find(payment => payment.name === item.web_service_name)
            ?.icon}`
        })
      )
    case PaymentTypeEnum.bankTransferPayment:
      return userPaymentItem.payment_details.map((item) => ({
        subtext: !item?.commentary?.includes('null') ? item.commentary : '',
        title: item.bank_name
          ? item.bank_name
          : translate('payments.bankTransfer'),
        value: item.bank_account_number,
        icon: getBankIcon(item.bank_id!, iconsData),
      }));
    default:
      return [];
  }
};

import React, {FC, Key, useMemo, useState} from 'react';
import {Typography} from 'antd';
import {IGetListData} from '../myTrades.p';
import {
  CancelCircle,
  CancelIcon,
  ClockCircle,
  GreenArrow,
  MarkIcon,
  ProfileCircle,
  SuccessCircle,
  WarningCircle,
} from '../../../../../shared/components/icons';
import { HttpError, useOne, useUpdate } from '@pankod/refine-core';
import dayjs from 'dayjs';
import '../../styles/styles.css';
import {Popover, Tooltip} from '@pankod/refine-antd';
import {useTranslation} from 'react-i18next';
import {Link} from '@pankod/refine-react-router-v6';
import styled from 'styled-components';
import UserTooltip from '../../../../../shared/components/ui/deal-card/tooltip'
import CancelDealPopover from "../../../../../features/p2p/cancelDealPopover";
import {ReactComponent as Cross} from "../../../../../shared/components/ui/deal-card/image/cross.svg";
import { useCookiesCustom } from '../../../../../shared/hooks/useCookieCustom';
import { useAuthState } from "../../../../../useAuthState";

const {Text} = Typography;

const MyDealsCard: FC<{
  index: Key;
  type: 'buy' | 'sell' | 'all';
  item: IGetListData;
}> = ({ type = 'buy', item, index }) => {
  const { t } = useTranslation();
  const uid = useAuthState((state) => state?.userAPI?.uid);
  const [data, setData] = useState<IGetListData>(item);
  const { mutate } = useUpdate<{ data: IGetListData }>();
  const { refetch } = useOne<IGetListData, HttpError>({
    dataProviderName: 'p2p',
    resource: 'order',
    id: data.id,
    queryOptions: {
      onSuccess: (res) => setData(res.data),
      enabled: false,
    },
  });
  const [open, setOpen] = useState(false);

  const [intervalT, setIntervalT] = useState(0);

  const now = dayjs(new Date());
  const test = dayjs(new Date(data.timer_start));

  const getIconStatus = useMemo(() => {
    switch (data.status) {
      case 'fiat_transferred':
        return ClockCircle(16, '#58BF92');
      case 'appealed':
      case 'fiat_transferred_no_2fa':
        return WarningCircle();
      case 'refused':
      case 'rejected':
        return CancelCircle();
      case 'respond':
        return ClockCircle();
      case 'respond_accepted':
        return WarningCircle(16, '#E8E264');
      case 'resolved':
      case 'success':
        return SuccessCircle();
    }
  }, [data.status]);

  const timer = useMemo(() => {
    const interval = setInterval(() => setIntervalT(intervalT + 1), 1000);
    const diff = now.diff(
      test.subtract(now.toDate().getTimezoneOffset(), 'minute')
    );
    const finalMinInMs = 1800000;
    if (
      diff >= finalMinInMs ||
      ['resolved', 'success', 'refused', 'respond_accepted'].indexOf(
        data.status
      ) !== -1
    ) {
      if (diff === finalMinInMs) {
        (async () => await refetch())();
      }
      setIntervalT(-1);
      clearInterval(interval);
      return {
        timer: '00:00',
      };
    }
    return {
      timer: dayjs(new Date(finalMinInMs - diff)).format('mm:ss'),
    };
  }, [intervalT]);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleUpdateStatusCancel = () => {
    mutate(
      {
        dataProviderName: 'p2p',
        resource: 'order',
        id: data.id,
        values: {
          status: 'refused',
        },
      },
      {
        onSuccess: (res) => setData(res.data.data),
      }
    );
  };

  const handleUpdateStatusAccept = (e: any) => {
    e.preventDefault();
    mutate(
      {
        dataProviderName: 'p2p',
        resource: 'order',
        id: data.id,
        values: {
          status: 'respond_accepted',
        },
      },
      {
        onSuccess: (res) => setData(res.data.data),
      }
    );
  };

  const getBackgroundCard = () => {
    return (data.type === 'sell')
      ? 'linear-gradient(75.86deg, #6320E6 6.84%, #7B59FD 99.53%)'
      : 'linear-gradient(80.91deg, #088473 0%, #12BFA8 100%)'
  }
  const getUserTooltipInfo = () => {
    return (data.type === 'sell' && data.seller_id === uid) ||
      (data.type === 'buy' && data.seller_id === uid)
      ? data.buyer_info
      : data.seller_info;
  };

  const getSellerBuyerNickname = () => {
    return (data.type === 'sell' && data.seller_id === uid) ||
      (data.type === 'buy' && data.seller_id === uid)
      ? data.buyer_info.nickname
      : data.seller_info.nickname;
  };

  const buttons = useMemo(() => {
    let disabled = true;
    if (
      (uid === data.seller_id &&
        data.type === 'sell') ||
      (uid === data.buyer_id && data.type === 'buy') ||
      intervalT === -1
    )
      disabled = false;
    return (
      <>
        {[
          'refused',
          'rejected',
          'resolved',
          'success',
          'respond_accepted',
          'fiat_transferred',
        ].indexOf(data.status) === -1 && (
          <>
            {((uid === data.seller_id &&
                data.type === 'sell') ||
              (uid === data.buyer_id &&
                data.type === 'buy')) && (
              <Tooltip
                title={t('myDealsCard.acceptTheDeal')}
                placement="top"
                overlayInnerStyle={{width: '148px'}}
                overlayStyle={{minWidth: '148px'}}
                arrow={{pointAtCenter: true}}
              >
                <ButtonIcon
                  className={'dealIconStatus'}
                  onClick={handleUpdateStatusAccept}
                >
                  {MarkIcon(16, '#C3C3CE')}
                </ButtonIcon>
              </Tooltip>
            )}
            <CancelDealPopover onDealCancel={handleUpdateStatusCancel}>
              <Tooltip
                placement="topRight"
                title={t('p2p.getCurrentStep.cancelDeal')}
                overlayInnerStyle={{width: '156px'}}
                overlayStyle={{minWidth: '156px'}}
                arrow={{pointAtCenter: true}}
              >
                <ButtonIcon
                  className={'dealIconStatus'}
                  disabled={disabled}
                >
                  {CancelIcon(16, '#C3C3CE')}
                </ButtonIcon>
              </Tooltip>
            </CancelDealPopover>
          </>
        )}
      </>
    );
  }, [data.status, data.seller_id, uid, intervalT === -1]);

    const timeFrom = () => {
        return `${t('from')} ${dayjs(data.created_at)
            .format('DD.MM.YYYY')
            .toString()}`
    }

    const pair = () => {
        return <span>
                        {data.type === 'sell' ? data.ccy.toUpperCase() : data.fiat.toUpperCase()} <GreenArrow/>{' '}
            {data.type === 'sell' ? data.fiat.toUpperCase() : data.ccy.toUpperCase()}
                      </span>
    }

    const fiatPrice = () => {
        return <span>
                        {data.price} {data.fiat.toUpperCase()}
                      </span>
    }

    const statusTimer = () => {
        return <StatusCol className={'flex-column gap-8'}>
            <Timer className={'text-bold'}>
                <span>{timer.timer}</span>
                {getIconStatus}
            </Timer>
            <div className={'text-gray'}>
                      <span>
                        {t(`myDeals.filters.status.${data.status}`)}
                      </span>
            </div>
        </StatusCol>
    }

    const sumInFiat = () => {
        return <div className={'flex'}>
            <span> Σ {data.fiat_sum} {data.fiat.toUpperCase()} </span>
        </div>
    }

    const sumInActive = () => {
        return <div className={'flex'}>
                        <span>
                          Σ {data.sum} {data.ccy.toUpperCase()}
                        </span>
        </div>
    }

    return (
        <MainWrapper>
            <CardWrapper>
                <BackgroundCard
                    $background={getBackgroundCard()}
                >
                    <div className={'card'}>
                        <div className={'text-white flex text-small text-bold'}>
                            <LinkStyled to={`/p2p/order/${item.id}`}>
                                <div className={'flex flex-row flex-column w-100'} style={{gap: '80px'}}>
                                    <div className={'flex-column gap-8'}>
                                        <Text className={'flex text-white text-bold'}>
                                            {t(`myDeals.cards.${data.type}`)}
                                        </Text>
                                        <Text className={'flex text-small text-gray'}>
                                            {timeFrom()}
                                        </Text>
                                    </div>

                                    <div className={'flex-column gap-8'}>
                                        <div className={'flex text-white text-bold'}>
                                            {pair()}
                                        </div>
                                        <div className={'flex text-white align-items-center'}>
                                            <Popover
                                                open={open}
                                                onOpenChange={handleOpenChange}
                                                trigger="hover"
                                                title={
                                                    <PopoverTitleWrapper onClick={(e) => e.stopPropagation()}>
                                                        {t('myDealsCard.tooltip.title')}
                                                        <CloseBtnPopover onClick={hide}>
                                                            <Cross/>
                                                        </CloseBtnPopover>
                                                    </PopoverTitleWrapper>
                                                }
                                                overlayStyle={{
                                                    border: '1px solid #767687',
                                                    borderRadius: 8,
                                                }}
                                                className={'flex text-white align-items-center'}
                                                overlayInnerStyle={{border: 'none'}}
                                                content={
                                                    <PaddingWrapper onClick={(e) => e.stopPropagation()}>
                                                        <UserTooltip
                                                            user={getUserTooltipInfo()}
                                                        ></UserTooltip>
                                                    </PaddingWrapper>
                                                }
                                            >
                                                <ProfileCircle/>
                                                <SpanStyled>
                                                    {getSellerBuyerNickname()}
                                                </SpanStyled>
                                            </Popover>
                                        </div>
                                    </div>

                                    <div className={'flex-column gap-8'}>
                                        <div className={'text-bold'}>
                                            {fiatPrice()}
                                        </div>
                                        <GreyText>
                                            {t('myDealsCard.thePriceOfOneCoin')}
                                        </GreyText>
                                    </div>
                                    {statusTimer()}
                                    <div className={'flex flex-column gap-8 flex-row text-small jc-flex-start'}>
                                        <div className={'flex-column gap-8 text-gray'}>
                                            <div>
                                                <span>{t('myDealsCard.amountInFiat')}</span>
                                            </div>
                                            <div>
                                                <span>{t('myDealsCard.amountInTheAsset')}</span>
                                            </div>
                                        </div>
                                        <div className={'flex-column text-white gap-8'}>
                                            {sumInFiat()}
                                            {sumInActive()}
                                        </div>
                                    </div>
                                </div>
                            </LinkStyled>

                            <FlexCenter>{buttons}</FlexCenter>
                        </div>
                    </div>
                </BackgroundCard>
            </CardWrapper>
        </MainWrapper>
    );
};

export default MyDealsCard;

const MainWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const CardWrapper = styled.div`
    position: relative;
    background: rgba(12, 26, 75, 0.08);
    padding-bottom: 16px;
    width: 100%;
    height: 100%;
    border-radius: 17px;
    cursor: pointer;
`;

const BackgroundCard = styled.div<{ $background?: string }>`
    border-left: 2px solid transparent;
    border-radius: 8px;
    background: ${(props) => props.$background};
`;

const Timer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const SpanStyled = styled.div`
    margin: 0;
    margin-left: 8px;
`;

const StatusCol = styled.div`
    justify-content: center;
    display: flex;
`;

const ButtonIcon = styled.button`
    background-color: transparent;
    border: none;
    padding: 8px;
    cursor: pointer;
`;

const LinkStyled = styled(Link)`
    cursor: pointer;
    width: 100%;
`;

const FlexCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
const CloseBtnPopover = styled.button`
    background: none;
    cursor: pointer;
    border: none;
    position: absolute;
    right: 16px;
    top: 24px;
`;

const PaddingWrapper = styled.div`
    padding: 0 8px 8px;
`

const PopoverTitleWrapper = styled.div`
    color: #FBFBFF;
    margin-bottom: 16px;
    padding: 8px 8px 0;
`

const GreyText = styled.div`
    line-height: 14.52px;
    font-weight: 600;
    font-size: 12px;
    color: #767687;
`;

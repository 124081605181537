import React from 'react';
import {Skeleton} from 'antd';

const SkeletonMyDeals = () => {
  return (
    <div>
      <Skeleton.Button
        block
        style={{height: '48px', marginBottom: '24px'}}
        active
      />
      <Skeleton.Button
        block
        style={{height: '54px', marginBottom: '24px'}}
        active
      />
      <Skeleton.Button
        block
        style={{height: '40px', marginBottom: '16px'}}
        active
      />
      <Skeleton.Button
        block
        style={{height: '40px', marginBottom: '24px'}}
        active
      />
      {Array(5)
        .fill(false)
        .map((_, index) => (
          <Skeleton.Button
            block
            style={{height: '80px', marginBottom: '24px'}}
            key={index}
            active
          />
        ))}
    </div>
  );
};

export default SkeletonMyDeals;

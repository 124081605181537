import React, { useEffect, useState } from 'react';
import { Col, Row, Typography } from '@pankod/refine-antd';
import { IMyDealsRow } from '../../../shared/interfaces/spot';
import { createDateAsUTC } from '../../../shared/helpers/convertTime';
import { useGetLocale } from '@pankod/refine-core';
import { useLocation } from '@pankod/refine-react-router-v6';

interface ISpotMyDealsTableRow {
  row: IMyDealsRow;
}

const SpotMyDealTableRow: React.FC<ISpotMyDealsTableRow> = ({ row }) => {
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);

  const [activePair, setActivePair] = useState(
    () => localStorage['activePair']
  );

  useEffect(() => {
    setActivePair(() => localStorage['activePair']);
  }, []);

  useEffect(() => {
    setActivePair(() => localStorage['activePair']);
  }, [location?.pathname]);

  return (
    <>
      <Row
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        key={'order-table-' + row.id}
        justify={'space-between'}
        style={{
          padding: '4.5px 0',
          cursor: 'pointer',
          backgroundColor: isHovered ? '#FFFFFF0D' : 'initial',
          paddingLeft: 16,
          paddingRight: 16,
        }}
        className={'history-orders-row'}
      >
        <Col span={2}>
          <Typography.Text>{row?.created_at}</Typography.Text>
        </Col>
        <Col span={2}>
          <Typography.Text>{activePair.replace('-', '/')}</Typography.Text>
        </Col>
        <Col span={2}>
          <Typography.Text>
            {row.type === 'buy' ? 'Покупка' : 'Продажа'}
          </Typography.Text>
        </Col>
        <Col span={2}>
          <Typography.Text>
            {!!row?.price ? (+row?.price)?.toFixed(8) : 0}
          </Typography.Text>
        </Col>
        <Col span={2}>
          <Typography.Text>
            {!!row?.amount ? (+row?.amount).toFixed(8) : 0}
          </Typography.Text>
        </Col>
        <Col span={2}>
          <Typography.Text>
            {!!row?.commission ? (+row?.commission)?.toFixed(8) : 0}
          </Typography.Text>
        </Col>
        <Col span={2}>
          <Typography.Text>
            {!!row?.dealAmount ? (+row?.dealAmount)?.toFixed(8) : 0}
          </Typography.Text>
        </Col>
        <Col span={2}>
          <Typography.Text>
            {!!row?.amountInFiat ? (+row?.amountInFiat)?.toFixed(8) : 0}
          </Typography.Text>
        </Col>
      </Row>
    </>
  );
};

export { SpotMyDealTableRow };

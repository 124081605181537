import { useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
} from '@pankod/refine-react-router-v6';
import { LogicalFilter, useTranslate } from '@pankod/refine-core';
import { Skeleton, Typography } from 'antd';
import { TableColumnProps, Tooltip } from '@pankod/refine-antd';
import dayjs, { Dayjs } from 'dayjs';
import Table from '../../../shared/components/ui/table';
import Tabs from '../../../shared/components/ui/Tabs';
import { CAFilters } from 'features/coinAccount/coinAccountFilters';
import { useGetWalletTranslatedItems } from 'shared/hooks/use-get-wallet-translated-items';
import { useGetWalletTables } from 'shared/hooks/use-get-wallet-table';
import { ReactComponent as Copy } from './icon.svg';
import styled from 'styled-components';
import { ErrorBoundary } from '../../../app/ErrorBoundary';
import { useCookiesCustom } from '../../../shared/hooks/useCookieCustom';

type Props = {};

export type RangeValue = [Dayjs | null, Dayjs | null] | null;

const currentDate = new Date();
const startMonthDate = new Date(
  new Date().setMonth(currentDate.getMonth() - 1)
);
const initDateFilter: LogicalFilter = {
  field: 'created_at',
  operator: 'between',
  value: [startMonthDate, currentDate],
};

export function HistoryShow({}: Props) {
  const location = useLocation();
  let { coin } = useParams();
  const { inputTable, outputTable } = useGetWalletTables({
    coin: coin ?? 'btc',
    initDateFilter,
  });
  const {removeCookie} = useCookiesCustom();
  const t = useTranslate();
  const [statusKey, setStatusKey] = useState(t('myOrders.filters.status.all'));
  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>(null);
  const [activeTab, setActiveTab] = useState(inputTable);
  const [activeTabKey, setActiveTabKey] = useState('inputs');
  const navigate = useNavigate();
  const list: any[] = [];

  const setFilter = (filter: LogicalFilter) => {
    if (outputTable.filters) {
      let updateFilters = outputTable.filters.filter(
        (item) => (item as LogicalFilter).field !== filter.field
      );
      if (filter.value === '') {
        outputTable.setFilters([...updateFilters], 'replace');
      } else {
        outputTable.setFilters([...updateFilters, filter]);
      }
    } else {
      outputTable.setFilters([filter]);
    }
    if (inputTable.filters) {
      let updateFilters = inputTable.filters.filter(
        (item) => (item as LogicalFilter).field !== filter.field
      );
      if (filter.value === '') {
        inputTable.setFilters([...updateFilters], 'replace');
      } else {
        inputTable.setFilters([...updateFilters, filter]);
      }
    } else {
      inputTable.setFilters([filter]);
    }
  };
  const { statusItemsInput, statusItemsOutput } = useGetWalletTranslatedItems({
    setStatusKey,
    setFilter,
    list,
  });
  const idColumn = (width?: number | string): TableColumnProps<any> => ({
    title: t('wallet.table.id'),
    dataIndex: 'id',
    key: 'id',
    width: width,
    render: (value) => {
      return (
        <>
          <div className={'flex-row gap-8'}>
            <Typography.Text className={'bold-14px-text'}>
              #{value}
            </Typography.Text>
            <CopyButton text={value} />
          </div>
        </>
      );
    },
  });

  const CopyButton = ({ text }: { text: string }) => (
    <Tooltip
      trigger="click"
      title={t('wallet.tooltips.copied')}
      placement="bottom"
      overlayInnerStyle={{ width: 'auto' }}
    >
      <IconButton
        onClick={async () => {
          await navigator.clipboard.writeText(text);
        }}
      >
        <Copy />
      </IconButton>
    </Tooltip>
  );

  const addressColumn = (width?: number | string): TableColumnProps<any> => ({
    title: t('wallet.table.address'),
    dataIndex: 'address',
    key: 'address',
    width: width,
    render: (value) => {
      return (
        <>
          <div className={'flex-row gap-8'}>
            <TypographyAddressStyled>{value}</TypographyAddressStyled>
            <CopyButton text={value} />
          </div>
        </>
      );
    },
  });

  const commissionColumn = (
    width?: number | string
  ): TableColumnProps<any> => ({
    title: t('wallet.table.commission'),
    dataIndex: 'commission',
    key: 'commission',
    width: width,
    render: (value) => {
      return (
        <>
          <Typography.Text className={'text-14px'}>
            {value ? Number(value).toFixed(8) : '-'} {value && (coin ?? 'btc').toUpperCase()}
          </Typography.Text>
        </>
      );
    },
  });

  const getColor = (value: string) => {
    return value === 'completed'
      ? '#58BF92'
      : value === 'canceled'
      ? '#F16063'
      : value === 'new'
      ? '#E8E264'
      : '#5297FF';
  };

  const outputStatusColumn = (
    width?: number | string
  ): TableColumnProps<any> => ({
    title: t('wallet.table.status.label'),
    dataIndex: 'status',
    key: 'status',
    width: width,
    render: (value) => {
      return (
        <>
          {
            <div className={'flex-row align-items-center gap-8'}>
              <ContainerStatusStyled background={getColor(value)} />
              <Typography.Text className={'text-14px'}>
                {value !== 'processing'
                  ? t(`wallet.table.status.value.${value}`)
                  : t(`wallet.table.status.value.processingOutput`)}
              </Typography.Text>
            </div>
          }
        </>
      );
    },
  });

  const inputStatusColumn = (
    width?: number | string
  ): TableColumnProps<any> => ({
    title: t('wallet.table.status.label'),
    dataIndex: 'status',
    key: 'status',
    width: width,
    render: (value) => {
      return (
        <>
          {
            <div className={'flex-row align-items-center gap-8'}>
              <ContainerStatusStyled background={getColor(value)} />
              <Typography.Text className={'text-14px'}>
                {value !== 'processing'
                  ? t(`wallet.table.status.value.${value}`)
                  : t(`wallet.table.status.value.processingInput`)}
              </Typography.Text>
            </div>
          }
        </>
      );
    },
  });

  const createdAtColumn = (width?: number | string): TableColumnProps<any> => ({
    title: t('wallet.table.time'),
    dataIndex: 'created_at',
    key: 'created_at',
    width: width,
    render: (value) => {
      return (
        <>
          <Typography.Text className={'text-14px'}>
            {dayjs(value).format('DD.MM.YY HH:mm:ss')}
          </Typography.Text>
        </>
      );
    },
  });

  const amountColumn = (width?: number | string): TableColumnProps<any> => ({
    title: t('wallet.table.amount'),
    dataIndex: 'amount',
    key: 'amount',
    width: width,
    render: (value) => {
      return (
        <>
          <Typography.Text className={'text-14px'}>
            {value} {value && (coin ?? 'btc').toUpperCase()}
          </Typography.Text>
        </>
      );
    },
  });

  const txColumn = (width?: number | string): TableColumnProps<any> => ({
    title: t('wallet.table.tx'),
    dataIndex: 'tx',
    key: 'tx',
    width: width,
    render: (value) => {
      return (
        <>
          <div className={'flex-row gap-8'}>
            <TypographyAddressStyled>{value}</TypographyAddressStyled>
            {value && <CopyButton text={value} />}
          </div>
        </>
      );
    },
  });


  const inputsHistory = (
    <TableHistoryStyled
      className={'custom-table'}
      bordered={false}
      {...inputTable.tableRes}
      columns={[
        idColumn('16%'),
        txColumn('23%'),
        amountColumn('23%'),
        createdAtColumn('14%'),
        commissionColumn(),
        inputStatusColumn(),
      ]}
    />
  );
  const outputsHistory = (
    <Table
      loading={activeTab.tableRes.loading}
      className={'wallet-custom-table'}
      style={{ marginTop: '70px' }}
      {...outputTable.tableRes}
      columns={[
        idColumn('11%'),
        txColumn('14%'),
        addressColumn('10%'),
        amountColumn('15%'),
        commissionColumn(),
        createdAtColumn('12%'),
        outputStatusColumn(),
      ]}
    />
  );
  return (
    <ErrorBoundary       logout={() => {}}
                         navigate={navigate}
                         removeCookie={removeCookie}>
      <WrapperStyled>
        <div className="flex-column">
          <Typography.Title>{t('coin.transactionHistory')}</Typography.Title>
        </div>
        <ContainerBodyStyled>
          <div
            className={'flex-row justify-content-flex-end width-100-percent'}
          >
            {!!inputTable.tableRes.loading ? (
              <SkeletonStyled
                active={!!inputTable.tableRes.loading}
                size={'large'}
              />
            ) : (
              <ContainerFiltersStyled
                className={
                  'flex-row align-items-center justify-content-space-between'
                }
              >
                <CAFilters
                  currentTabKey={activeTabKey}
                  isLoading={!!inputTable.tableRes.loading}
                  statusItemsInputMenu={{
                    items: statusItemsInput,
                  }}
                  statusItemsOutputMenu={{
                    items: statusItemsOutput,
                  }}
                  currentStatus={statusKey}
                  startDate={startMonthDate}
                  endDate={currentDate}
                  setFilter={setFilter}
                  dates={dates}
                  value={value}
                  setDates={setDates}
                  setValue={setValue}
                  statusKey={statusKey}
                />
                <ContainerTypographyFiltersStyled>
                  <TypographyFiltersStyled
                    onClick={() => {
                      navigate(`/coin-accounts/btc`);
                      inputTable.setFilters([], 'replace');
                      outputTable.setFilters([], 'replace');
                      setDates(null);
                      setValue(null);
                      setStatusKey(t('wallet.filters.status.all'));
                    }}
                  >
                    {t('myOrders.button.clearFilter')}
                  </TypographyFiltersStyled>
                  {activeTabKey === 'inputs' ? (
                    inputTable?.filters?.length ||
                    !location.pathname.includes('btc') ? (
                      <Typography.Text
                        className={
                          'flex-row justify-content-center align-items-center filters-counter'
                        }
                      >
                        {inputTable.filters &&
                          +inputTable.filters.length +
                            +!location.pathname.includes('btc')}
                      </Typography.Text>
                    ) : (
                      <></>
                    )
                  ) : outputTable?.filters?.length ||
                    !location.pathname.includes('btc') ? (
                    <Typography.Text
                      className={
                        'flex-row justify-content-center align-items-center filters-counter'
                      }
                    >
                      {outputTable.filters &&
                        +outputTable.filters.length +
                          +!location.pathname.includes('btc')}
                    </Typography.Text>
                  ) : (
                    <></>
                  )}
                </ContainerTypographyFiltersStyled>
              </ContainerFiltersStyled>
            )}
          </div>
          <TabsStyled
            defaultActiveKey={'inputs'}
            onChange={() => {
              activeTab == inputTable
                ? setActiveTab(outputTable)
                : setActiveTab(inputTable);
              activeTabKey === 'inputs'
                ? setActiveTabKey('outputs')
                : setActiveTabKey('inputs');
            }}
            items={[
              {
                key: 'inputs',
                label: `${t('wallet.coin.deposit.history')}`,
                children: inputsHistory,
              },
              {
                key: 'outputs',
                label: `${t('wallet.coin.withdraw.history')}`,
                children: outputsHistory,
              },
            ]}
          />
        </ContainerBodyStyled>
      </WrapperStyled>
    </ErrorBoundary>
  );
}

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const TypographyAddressStyled = styled(Typography.Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
  font-size: 14px;
  font-weight: bold;
`;

const TableHistoryStyled = styled(Table)`
  border-collapse: collapse;
  margin-top: 70px;
`;

const ContainerStatusStyled = styled.div<{ background: string }>`
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: ${({ background }) => background};
`;

const ContainerFiltersStyled = styled.div`
  position: absolute;
  right: 0;
  top: 100px;
  width: 100%;
`;

const ContainerTypographyFiltersStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TypographyFiltersStyled = styled(Typography.Text)`
  color: #fbfbff;
  font-weight: 700;
  cursor: pointer;
`;

const TabsStyled = styled(Tabs)`
  font-weight: 700;
`;

const WrapperStyled = styled.div`
  background-color: #14141f;
`;

const ContainerBodyStyled = styled.div`
  position: relative;
  margin-top: -10px;
`;

const SkeletonStyled = styled(Skeleton.Button)`
  width: 162px;
`;

import React, { useEffect, useState } from 'react';

interface ITimerProps {
  initSeconds?: number;
  digits?: number;
  styles?: object;
  handleFinish: () => void;
}

const Index: React.FC<ITimerProps> = ({
  styles,
  initSeconds = 60,
  digits = 4,
  handleFinish,
}) => {
  const [seconds, setSeconds] = useState(initSeconds);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (seconds > 0 && isActive) {
      setTimeout(() => setSeconds((prev) => prev - 1), 1000);
    } else if (isActive) {
      setIsActive(false);
      handleFinish();
    }
  }, [seconds, isActive]);

  const timerString = new Date(seconds * 1000)
    .toISOString()
    .substring(19 - digits - 1, 19);

  return <div style={styles}>{timerString}</div>;
};

export default Index;

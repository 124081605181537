import React from 'react';
import { notification, Space, Typography } from 'antd';
import Button from '../button';
import Icon from '@ant-design/icons';
import { CloseIcon } from '../assets/svg/close';
import {useTranslate} from "@pankod/refine-core";

interface INotification {
  key: string;
  message: React.ReactNode;
  description: React.ReactNode;
  translate(
    key: string,
    options?: any,
    defaultMessage?: string,
  ): string
}

export const openNotification = ({
  key,
  message,
  description,
  translate
}: INotification) => {
  const [api] = notification.useNotification();

  const close = () => {
    api.destroy(key);
  };
  const btn = (
    <Space>
      <Button height={40} onClick={close}>
        <Typography.Text>{translate('notifications.moreDetailed')}</Typography.Text>
      </Button>
    </Space>
  );
  api.open({
    message,
    description,
    style: {
      color: '#FBFBFF',
      backgroundColor: '#2B2B36',
    },
    closeIcon: <Icon component={CloseIcon} style={{ fontSize: 24 }} />,
    btn,
    key,
    onClose: close,
  });
};

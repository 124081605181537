import React, { FC, useState } from 'react';
import Button from '../../../shared/components/ui/button';
import { ButtonsTypes } from '../../../shared/components/ui/button/filledButton.p';
import './index.css';
import { HttpError, useCustom, useCustomMutation } from '@pankod/refine-core';
import { useNavigate, useParams } from '@pankod/refine-react-router-v6';
import Modal from '../../../features/payment/modal';
import Skeleton from '../../p2p/myDeals/skeleton';
import { useTranslation } from 'react-i18next';
import { axios } from '../../../shared/exios';
import { ErrorBoundary } from '../../../app/ErrorBoundary';
import { useAuthState } from '../../../useAuthState';
import { useCookiesCustom } from '../../../shared/hooks/useCookieCustom';

interface Props {
  props: any;
}

interface JWT {
  amount: number;
  redirect_url: string;
  reason: string;
  error: string;
  currency: string;
  balance: number;
}

interface Payment {
  amount: number;
  jwt: string;

  passphrase?: string;
}

const Course: FC<Props> = () => {
  const { t } = useTranslation();
  const logout = useAuthState((state) => state.logout);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState('');
  const [tries, setTries] = useState(1);
  const params = useParams();
  const navigate = useNavigate();
  const {removeCookie} = useCookiesCustom();

  const { data, isLoading, isError } = useCustom<JWT, HttpError>({
    url: `btc-coin/api/decode-jwt/${params.id}`,
    method: 'get',
    config: {
      headers: {
        Authorization: axios.defaults.headers.common['Authorization'],
      },
    },
  });

  const { mutate } = useCustomMutation<Payment>();

  const PaymentProduct = (secret: string) => {
    mutate(
      {
        url: `btc-coin/api/payment`,
        method: 'post',
        config: {
          headers: {
            Authorization: axios.defaults.headers.common['Authorization'],
          },
        },
        values: {
          amount: product?.amount,
          jwt: params.id,
          passphrase: secret,
        },
      },
      {
        onSuccess: () =>
          window.location?.replace(product?.redirect_url as string),
        onError: () => {
          if (tries === 3) {
            navigate('/payment-logout');
            logout();
          } else {
            setTries(tries + 1);
            setError(
              `${t('course.incorrectSecret')} ${3 - tries} ${t(
                'course.attemptsFrom'
              )} 3`
            );
          }
        },
      }
    );
  };

  const product = data?.data;

  if (isLoading) {
    return <Skeleton />;
  }

  if (isError) {
    return <div style={{ color: 'white' }}>{t('course.attemptsFrom')}</div>;
  }

  return (
    <ErrorBoundary
      logout={() => {}}
      navigate={navigate}
      removeCookie={removeCookie}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 18,
        }}
      >
        <div className={'payment-course-container'}>
          <span className={'payment-course-title'}>
            {t('course.verificationOfPaymentData')}
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 16,
            }}
          >
            <span className={'payment-course-coin-title'}>
              {t('course.cryptocurrency')}:
            </span>
            <span className={'payment-course-coin-text'}>
              {product?.currency}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 32,
            }}
          >
            <span className={'payment-course-coin-title'}>
              {t('course.product')}:
            </span>
            <div className={'payment-course-content-container'}>
              <span
                className={'payment-course-content-title'}
                style={{ marginBottom: 0 }}
              >
                {/*Криптовалютная мастерская: От новичка к эксперту*/}
                {product?.reason}
              </span>
              {/*<div className={'payment-course-content-teacher-container'}>*/}
              {/*  <img alt={'people'} src={People} />*/}
              {/*  <span className={'payment-course-content-teacher-title'}>*/}
              {/*    Сухачев Денис Иванович*/}
              {/*  </span>*/}
              {/*</div>*/}
              {/*<div className={'payment-course-content-body-text'}>*/}
              {/*  На протяжении этого курса вы погрузитесь в увлекательный мир*/}
              {/*  криптовалют, изучите основные понятия и технологии, связанные с*/}
              {/*  блокчейном и криптографией. Мы поможем вам разобраться с понятием*/}
              {/*  цифровых активов, различными типами криптовалют и их*/}
              {/*  использованием в современном финансовом мире.*/}
              {/*</div>*/}
              {/*<div style={{ display: 'flex', gap: 4 }}>*/}
              {/*  <span*/}
              {/*    className={'payment-course-content-footer-title'}*/}
              {/*    style={{ marginRight: 4 }}*/}
              {/*  >*/}
              {/*    Период проведения:*/}
              {/*  </span>*/}
              {/*  <span*/}
              {/*    className={'payment-course-content-body-text'}*/}
              {/*    style={{ marginBottom: 0 }}*/}
              {/*  >*/}
              {/*    17.05.2023*/}
              {/*  </span>*/}
              {/*  <span*/}
              {/*    className={'payment-course-content-body-text'}*/}
              {/*    style={{ marginBottom: 0 }}*/}
              {/*  >*/}
              {/*    -*/}
              {/*  </span>*/}
              {/*  <span*/}
              {/*    className={'payment-course-content-body-text'}*/}
              {/*    style={{ marginBottom: 0 }}*/}
              {/*  >*/}
              {/*    22.05.2023*/}
              {/*  </span>*/}
              {/*</div>*/}
            </div>
            <span className={'payment-course-footer-title'}>
              {t('course.amountToBePaid')}
            </span>
            <span className={'payment-course-footer-text'}>
              {product?.amount} {product?.currency}
            </span>
          </div>
          <div className={'payment-course-button-container'}>
            <Button
              style={{ width: '60%' }}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              {t('buttons.confirm2')}
            </Button>
            <Button
              style={{ minWidth: 'none', width: '36%' }}
              type={ButtonsTypes.outlined}
              onClick={() => {}}
            >
              {t('buttons.cancel2')}
            </Button>
          </div>
        </div>
        <Modal
          error={error}
          paymentProduct={PaymentProduct}
          controlsOpen={[isOpen, setIsOpen]}
        />
      </div>
    </ErrorBoundary>
  );
};

export default Course;

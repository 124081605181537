import * as React from 'react';
import { FC, useEffect } from 'react';
import { Col, Divider, Row, Spin } from '@pankod/refine-antd';
import styled from 'styled-components';
import { TCoinName } from '../../../../entities/coin';
import CoinCard from '../CoinCard/CoinCard';
import { useWalletState } from '../../../../useWalletState';

const WalletCoins: FC = () => {
  const balances = useWalletState((state) => state.balances);
  const getBalances = useWalletState((state) => state.getBalances);
  const getCurrencyConstraintsWithNetworks = useWalletState(
    (state) => state.getCurrencyConstraintsWithNetworks
  );

  useEffect(() => {
    getBalances();
    getCurrencyConstraintsWithNetworks();
  }, []);

  const {isBalanceVisible} = useWalletState();

  return (
    <div>
      <CustomDivider />
      <Loader $isLoading={false}>
        <Spin />
      </Loader>
      <Row wrap gutter={[16, 8]}>
        {!!balances?.length &&
          balances?.map((balance, idx) => (
            <Col key={idx}>
              <CoinCard
                coin={{
                  name: balance?.name as TCoinName,
                  data: {
                    amount: String(balance?.balance?.amount),
                    deposit: String(balance?.balance?.deposit),
                  },
                }}
                isVisible={isBalanceVisible}
              />
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default WalletCoins;

const CustomDivider = styled(Divider)`
  border: 1px solid #474755;
  margin: 24px 0 24px 0;
`;

const Loader = styled.div<{ $isLoading: boolean }>`
  width: 100%;
  display: ${(props) => (props.$isLoading ? 'block' : 'none')};
`;

import { IGetListData } from '../../../pages/p2p/myDeals/cards/myTrades.p';
import { openNotification } from '../../components/ui/notification';
import Icon from '@ant-design/icons';
import { Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDataProvider, useTranslate } from '@pankod/refine-core';
import {
  CONFIG_DATA_PROVIDER,
  P2P_DATA_PROVIDER,
} from '../../../packages/keycloak-client/constants';
import { Icons } from '@pankod/refine-antd';
import { useGetTimeFromConfig } from '../use-get-time-from-config';
import { useUserNickname } from '../use-user-nickname';
import { IPaymentInfo } from '../../../pages/p2p/dashboard/interface';
import { createDateAsUTC } from '../../helpers/convertTime';

const { CheckCircleOutlined } = Icons;

interface IUseGetP2POrdersData {
  id: string;
  intervalTimeInMS?: number;
}

export const useGetP2POrdersData = ({
  id,
  intervalTimeInMS,
}: IUseGetP2POrdersData) => {
  const dataProvider = useDataProvider();
  const p2pProvider = dataProvider(P2P_DATA_PROVIDER);
  const configProvider = dataProvider(CONFIG_DATA_PROVIDER);

  const [currCommission, setCurrCommission] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState<IPaymentInfo | undefined>(
    undefined
  );

  const { configValue, updateConfigValue } = useGetTimeFromConfig();

  const [currentOrder, setCurrentOrder] = useState<IGetListData | undefined>(
    undefined
  );

  const { userData, isLoading } = useUserNickname({
    onSuccessUpdateCallback: () => {},
    setValidationError(error: string) {},
  });

  const activeAppeal = currentOrder?.appeals?.find(
    (item) => item.status !== 'closed'
  );

  const createAppeal = (status: string) => {
    p2pProvider
      .create({
        resource: 'appeal',
        variables: {
          order_id: currentOrder?.id,
          description: status,
          reason: status,
        },
      })
      .then(() => {
        getOrderInfo();
      });
  };

  const updateP2PAppealStatus = (status: string) => {
    if (activeAppeal) {
      p2pProvider
        .update({
          resource: 'appeal',
          id: activeAppeal.id,
          variables: {
            status,
          },
        })
        .then(() => {
          getOrderInfo();
        });
    }
  };

  const getOrderInfo = () => {
    p2pProvider
      .getOne<IGetListData>({
        resource: 'order',
        id: `${id}?include=appeals,ad`, //TODO Бэки сказали поменять на ad с offer
      })
      .then((res) => {
        const tmpRes = res.data;
        if (!tmpRes.payment_info) {
          p2pProvider
            .getOne({
              id: tmpRes.payment_id,
              resource: 'payment',
            })
            .then((res) => {
              setPaymentInfo(res.data as IPaymentInfo);
            });
        } else {
          setPaymentInfo(tmpRes.payment_info);
        }

        setCurrentOrder(tmpRes);
        configProvider
          .getList({
            resource: 'currency-constraint',
            filters: [
              {
                field: 'currency',
                operator: 'eq',
                value: tmpRes.ccy,
              },
            ],
          })
          .then((res) => {
            const findCCy = res.data.find(
              (item) => item.currency === tmpRes.ccy
            );
            if (findCCy) {
              setCurrCommission(
                findCCy.offchain_commission_percent * tmpRes.sum
              );
            }
          });
        let configValueKey = '';
        switch (tmpRes.status) {
          case 'respond':
            configValueKey = 'respond_reaction_time';
            break;
          case 'respond_accepted':
            configValueKey = 'respond_accepted_reaction_time';
            break;
          case 'fiat_transferred':
            configValueKey = 'fiat_transferred_reaction_time';
            break;
        }

        if (configValueKey !== '') {
          updateConfigValue({
            configKey: configValueKey,
            actionAfterGetConfigValue: (timer) => {
              const endTime = +new Date(
                new Date(
                  createDateAsUTC(new Date(tmpRes.timer_start))
                ).setSeconds(
                  +timer +
                    +new Date(
                      createDateAsUTC(new Date(tmpRes.timer_start))
                    ).getSeconds()
                )
              );

              const currDate = +new Date();

              setIsTimerActive(
                endTime - currDate > 0 && !(tmpRes.status === 'refused')
              );
            },
          });
        }
      });
  };
  const translate = useTranslate();

  const updateP2POrderStatus = (status: string, useNotification?: boolean) => {
    p2pProvider
      .update<IGetListData>({
        resource: 'order',
        id,
        variables: {
          status: status,
        },
      })
      .then((res) => {
        if (useNotification) {
          const desc = translate('notifications.buyDealSuccess').split(':|:');
          openNotification({
            key: status,
            message: (
              <>
                <div className={'flex-row gap-8 align-items-center'}>
                  <Icon
                    component={() => (
                      <CheckCircleOutlined
                        style={{ fontSize: 24, color: '#58BF92' }}
                      />
                    )}
                  />
                  <Typography.Text className={'bold-16px-text'}>
                    {translate('notifications.dealCompleted')}
                  </Typography.Text>
                </div>
              </>
            ),
            description: `${desc[0]} ${currentOrder?.ccy} ${desc[1]} ${currentOrder?.fiat} ${desc[2]} ${currentOrder?.sum} ${currentOrder?.ccy} ${desc[3]}`,
            translate,
          });
        }
        setCurrentOrder((res.data as any).data);
      });
  };

  useEffect(() => {
    getOrderInfo();
    let interval = setInterval(
      () => {
        getOrderInfo();
      },
      intervalTimeInMS ? intervalTimeInMS : 5000
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  return {
    currCommission,
    updateP2PAppealStatus,
    currTimerValue: +configValue,
    isTimerActive,
    currentOrder,
    updateP2POrderStatus,
    paymentInfo,
    createAppeal,
    isSeller: userData?.uid === currentOrder?.seller_id,
    isMyAppeal: currentOrder?.appeals
      ? activeAppeal?.applier_id === userData?.uid
      : false,
  };
};

import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { Button, Divider, Modal, Typography } from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@pankod/refine-react-router-v6';
import styled from 'styled-components';
import { ModalWidth } from '../../constants';
import CoinForm from '../CoinForm/CoinForm';
import DepositQRCode from '../../../../features/paymentComponents/modalContent/depositQrCode/DepositQRCode';
import { useWalletState } from '../../../../useWalletState';
import { TWallet, TNetwork } from '../../../../entities/coin';
import { axios } from '../../../../shared/exios';

const { Text } = Typography;

interface IProps {
  isOpen: boolean;
  onCancel: () => void;
}

const DepositModal: FC<IProps> = ({ isOpen, onCancel }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [fieldNotFilled, setFieldNotFilled] = useState(false);
  const [failedGenerateWallet, setFailedGenerateWallet] = useState(false);

  const {
    selectedCoin,
    selectedCoinNetwork,
    setWallet,
    getWallets,
    findWallet,
    resetWalletData,
  } = useWalletState((state) => state);
  const [walletIsPending, setWalletIsPending] = useState(false);
  const intervalId = React.useRef<NodeJS.Timeout>();
  const handleNavigateToTransactions = (coin: string) =>
    navigate(`/coin-accounts/${coin.toLowerCase()}`);

  const handleShowError = () => {
    const checkCoinAndNetwork = !!selectedCoin?.networks?.length
      ? !!selectedCoin && !!selectedCoinNetwork
      : !!selectedCoin;
    if (!checkCoinAndNetwork) {
      setFieldNotFilled(true);
    }
  };

  const getWalletWithInterval = () => {
    let attemptsNumber = 0;
    const callbackInterval = async () => {
      attemptsNumber++;
      const res = await getWallet();
      if (res === 'successed' || attemptsNumber === 10) {
        clearInterval(intervalId.current);
        setWalletIsPending(false);
        if (attemptsNumber === 10 && res !== 'successed') {
          setFailedGenerateWallet(true);
        }
      }
    };
    intervalId.current = setInterval(callbackInterval, 1000);
    callbackInterval();

    return;
  };

  const createWallet = async () => {
    if (!!selectedCoin?.currency) {
      try {
        await axios.post<{ data: TWallet }>(
          `/${selectedCoin?.currency?.toLowerCase()}-coin/api/wallet`,
          { network: selectedCoinNetwork?.network }
        );
        getWalletWithInterval();
      } catch (e) {
        console.error('error creat wallet', e);
      }
    }
  };

  const getWallet = async () => {
    if (!!selectedCoin?.currency) {
      try {
        if (!!selectedCoin?.networks?.length && !selectedCoinNetwork) {
          return 'failed';
        }
        const wallets = await getWallets(selectedCoin);
        const wallet = findWallet(wallets, selectedCoinNetwork as any);
        setWalletIsPending(true);

        if (wallet && wallet.status === 'completed') {
          setWalletIsPending(false);
          setWallet(wallet);
          return 'successed';
        } else if (!wallet) {
          setWalletIsPending(false);
          return 'successed';
        }
        return 'failed';
      } catch (e) {
        console.log('error get wallet', e);
      }
    }
  };

  useEffect(() => {
    if (isOpen && selectedCoin) {
      setFieldNotFilled(false);
      setWalletIsPending(false);
      setFailedGenerateWallet(false);
      getWalletWithInterval();
    }
    return () => {
      clearInterval(intervalId.current);
    };
  }, [selectedCoin, selectedCoinNetwork, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      resetWalletData();
    }
  }, [isOpen]);

  return (
    <Modal
      className="deposit-modal"
      title={t('wallet.coin.deposit.modalTitle')}
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      width={ModalWidth}
    >
      <CoinSelect type={'input'} showError={fieldNotFilled} />
      {
        <div style={{marginTop:40}}>
          <DepositQRCode
            handleShowError={handleShowError}
            createWallet={createWallet}
            walletIsPending={walletIsPending}
            setWalletIsPending={setWalletIsPending}
            failedGenerateWallet={failedGenerateWallet}
          />
          <div className={'custom-button-container'}>
            <Button
              className={'custom-button-outlined-purple'}
              onClick={() => {
                handleNavigateToTransactions(selectedCoin?.currency || 'btc');
              }}
            >
              {t('wallet.coin.deposit.moveToTransactions')}
            </Button>
          </div>
        </div>
      }
      <Divider />
      <DepositParameterText>
        {t('wallet.coin.deposit.modalDescription')}
      </DepositParameterText>
    </Modal>
  );
};

export default DepositModal;

const CoinSelect = styled(CoinForm)`
  margin-bottom: 40px;
`;

const DepositParameterText = styled(Text)<{ $isTitle?: boolean }>`
  display: block;
  white-space: pre-wrap;
  width: 430px;
  font-size: 12px;
  color: ${(props) => (props.$isTitle ? '#767687' : '#FBFBFF')};
`;

import React, { lazy, useEffect, useState } from 'react';
import { Typography } from 'antd';
import { HttpError, LogicalFilter } from '@pankod/refine-core';
import { Skeleton, useTable } from '@pankod/refine-antd';
import { getUniqList } from '../shared/helpers/getUniqOptionList';
import { IGetListData, RangeValue } from '../features/myOrders/myOrders.p';
import { useGetMyOrderTranslatedItems } from '../shared/hooks/use-get-MyOrder-translated-items';
import { useGetTranslateMyOrderTableColumns } from '../shared/hooks/use-get-translate-MyOrder-table-columns';
import ClearMyOrdersFiltersButton from '../features/myOrders/clearMyOrdersFiltersButton';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '../app/ErrorBoundary';
import { useAuthState } from '../useAuthState';
import { useCookiesCustom } from '../shared/hooks/useCookieCustom';

const MyOrdersTable = lazy(() =>
  import('../features/myOrders/myOrdersTable').then((module) => ({
    default: module.default,
  }))
);

const MyOrdersFilters = lazy(() =>
  import('../features/myOrders/myOrdersFilters').then((module) => ({
    default: module.default,
  }))
);

const MyOrders = () => {
  const {removeCookie} = useCookiesCustom();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentDate = new Date();
  const startMonthDate = new Date(
    new Date().setMonth(currentDate.getMonth() - 1)
  );

  const initDateFilter: LogicalFilter = {
    field: 'created_at',
    operator: 'between',
    value: [startMonthDate, currentDate],
  };

  const [inputCoinKey, setInputCoinKey] = useState(
    t('myOrders.filters.receive')
  );
  const [outputCoinKey, setOutputCoinKey] = useState(t('myOrders.filters.get'));
  const [statusKey, setStatusKey] = useState(t('myOrders.filters.status.all'));

  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>(null);

  const [currPage, setCurrPage] = useState(1);
  const [saveDataSource, setSaveDataSource] = useState<IGetListData[]>([]);

  const {
    tableProps: inputTableProps,
    setFilters,
    filters,
    setCurrent,
  } = useTable<IGetListData, HttpError>({
    resource: `orders`,
    // dataProviderName: `exchange`,
    dataProviderName: `offchain-exchange`,
    onLiveEvent: (event) => {
      console.log(event);
    },
    initialPageSize: 15,
    initialCurrent: currPage,
    initialFilter: [initDateFilter],
  });

  const setFilter = (filter: LogicalFilter) => {
    if (filters) {
      let updateFilters = filters.filter(
        (item) => (item as LogicalFilter).field !== filter.field
      );
      if (filter.value === '') {
        setFilters([...updateFilters], 'replace');
      } else {
        setFilters([...updateFilters, filter]);
      }
    } else {
      setFilters([filter]);
    }
  };

  let inputList: IGetListData[] = [];
  let outputList: IGetListData[] = [];

  if (inputTableProps.dataSource) {
    let test = inputTableProps.dataSource as IGetListData[];
    inputList = getUniqList(test, 'input_currency');
    outputList = getUniqList(test, 'output_currency');
  }

  const [savedData, setSavedData] = useState({
    inputList,
    outputList,
  });

  useEffect(() => {
    if (savedData.outputList.length === 0 || savedData.inputList.length === 0) {
      setSavedData({
        inputList,
        outputList,
      });
    }
  }, [inputTableProps.dataSource]);

  useEffect(() => {
    if (inputTableProps.dataSource) {
      const tmp = inputTableProps.dataSource as IGetListData[];
      setSaveDataSource(getUniqList([...saveDataSource, ...tmp], 'id'));
    }
  }, [inputTableProps.dataSource, currPage]);

  const { inputCoinItems, outputCoinItems, statusItems } =
    useGetMyOrderTranslatedItems({
      setInputCoinKey,
      setOutputCoinKey,
      setStatusKey,
      setFilter,
      inputList: savedData.inputList,
      outputList: savedData.outputList,
    });

  const {
    idColumn,
    getAmountColumn,
    sendAmountColumn,
    createdAtColumn,
    courseColumn,
    statusColum,
  } = useGetTranslateMyOrderTableColumns();

  return (
    <ErrorBoundary
      logout={() => {}}
      navigate={navigate}
      removeCookie={removeCookie}
    >
      <div className={'flex-column'} style={{ gap: 26 }}>
        <div className={'flex-column gap-32'}>
          <Skeleton
            active={!!inputTableProps.loading}
            loading={!!inputTableProps.loading}
            title={{
              style: {
                height: 48,
              },
            }}
            paragraph={{
              rows: 0,
            }}
          >
            <Typography.Title style={{ color: '#FBFBFF' }}>
              {t('myOrders.title')}
            </Typography.Title>
          </Skeleton>

          <MyOrdersFilters
            isLoading={!!inputTableProps.loading}
            inputCoinItemsMenu={{
              items: inputCoinItems,
            }}
            outputCoinItemsMenu={{
              items: outputCoinItems,
            }}
            statusItemsMenu={{
              items: statusItems,
            }}
            currentInputCoin={inputCoinKey}
            currentOutputCoin={outputCoinKey}
            currentStatus={statusKey}
            startDate={startMonthDate}
            endDate={currentDate}
            setFilter={setFilter}
            dates={dates}
            value={value}
            setDates={setDates}
            setValue={setValue}
          />
        </div>

        <ClearMyOrdersFiltersButton
          isLoading={!!inputTableProps.loading}
          filtersLen={filters?.length}
          onButtonClick={() => {
            setFilters([initDateFilter], 'replace');
            setDates(null);
            setValue(null);
            setInputCoinKey(t('myOrders.filters.receive'));
            setOutputCoinKey(t('myOrders.filters.get'));
            setStatusKey(t('myOrders.filters.status.all'));
          }}
        />

      <MyOrdersTable
        fetchData={() => {
          if (
            saveDataSource.length ===
            (inputTableProps?.dataSource?.length ?? 0) * currPage
          ) {
            setCurrent(currPage + 1);
            setCurrPage(currPage + 1);
          }
        }}
        isLoading={!!inputTableProps.loading}
        tableProps={inputTableProps}
        dataSource={saveDataSource}
        onRowClick={(id) => {
          navigate(`/exchange-outputOrder/${id}`);
        }}
        columns={[
          idColumn,
          createdAtColumn,
          sendAmountColumn,
          getAmountColumn,
          courseColumn,
          statusColum,
        ]}
      />
      </div>
    </ErrorBoundary>
  );
};

export default MyOrders;

export const coinArr = [
    { id: 'btc', name: 'Bitcoin' ,
    networks: [
      { id: 'eth', name: 'ETH' },
    ],},
    { id: 'ltc', name: 'LTC' ,
    networks: [
      { id: 'eth', name: 'ETH' },
      { id: 'bnb', name: 'BNB' },
    ],},
    { id: 'eth', name: 'Etherium' ,
    networks: [
      { id: 'eth', name: 'ETH' },
    ],},
    {
      id: 'usdt',
      name: 'Tether',
      networks: [
        { id: 'eth', name: 'ETH' },
        { id: 'bnb', name: 'BNB' },
      ],
    },
    {
      id: 'usdc',
      name: 'USD Coin',
      networks: [
        { id: 'eth', name: 'ETH' },
        { id: 'bnb', name: 'BNB' },
      ],
    },
    {
      id: 'matic',
      name: 'Polygon',
      networks: [
        { id: 'eth', name: 'ETH' },
        { id: 'polygon', name: 'Polygon' },
      ],
    },
  ];
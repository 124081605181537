import { Typography } from "@pankod/refine-antd";
import { styled } from "styled-components";

export const BookTableContent = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    column-gap: 0;
`
export const ContentCell = styled.div`
    display: flex;
    padding: 9px 12px 9px 12px;
    position: relative;
`
export const TitleCell = styled.div`
    display: flex;
    padding: 8px 12px 8px 12px;
    height: 32px;
    align-items: flex-end;
    margin-bottom: 12px;
`
export const BookTableHeader = styled.div`
    display: flex;
    padding-left: 12px;
    margin-bottom: 16px;
`
export const BookTableRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 32px;
    transition: all .2s ease;
    position: relative;
    span{
        font-size: 12px;
        line-height: 15px;
        color: #FBFBFF;
    }
    &:hover{
        background: rgba(255, 255, 255, 0.05);
    }
`
export const BookTableRowBg = styled.div`
    height: 100%;
    position: absolute;
    content: '';
    top: 0;
    &.fromRight{
        right: 0;
    }
    &.fromLeft{
        left: 0;
    }
`
export const OrderBookTableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px 15px 25px 10px;
    border: 1px solid #2B2B36;
    width: 50%;
    justify-content: flex-start;
`
export const Columns = styled.div`
    display: flex;
    justify-content: space-between;
`
export const TableTitle = styled(Typography.Text)`
    font-size: 14px;
    line-height: 17px;
    color: #FBFBFF;
`
import React from 'react';
import UserBalance from '../user-balance';
import { UserDropdown } from '../user-dropdown';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAuthState } from '../../../../../useAuthState';

interface IUserComponent {
  isAuth: boolean;
}

const Index: React.FC<IUserComponent> = ({ isAuth }) => {
  const { t } = useTranslation();
  const login = useAuthState((state) => state.login);
  const userAPI = useAuthState((state) => state.userAPI);

  if (!window.location.pathname.includes('payment-logout')) {
    if (isAuth) {
      return (
        <>
          {userAPI?.status !== 'banned' && <UserBalance />}
          <UserDropdown />
        </>
      );
    }

    return (
      <LoginWrapper onClick={() => login()} data-name="signin">
        <Typography.Text>{t('header.login')}</Typography.Text>
      </LoginWrapper>
    );
  }
  return null;
};

const LoginWrapper = styled.div`
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
`;

export default Index;

import {ConfigProvider, Form, Icon, InputNumber, Radio, Slider, Tag, Tooltip, Typography,} from '@pankod/refine-antd';
import React, {FC, lazy, useEffect} from 'react';
import {AdPriceType, AdTypesEnum, IFiat} from '../types';
import {ICryptoCurrencyConstraintItem} from 'widgets/order/types';
import {ReactComponent as ArrowForwardIcon} from '../../../../shared/components/ui/assets/svg/arrowForward.svg';
import '../index.css';
import {getMoneyIcon} from 'shared/helpers/getMoneyIcon';
import {ReactComponent as QuestionOutlinedIcon} from '../../../../shared/components/ui/assets/svg/questionOutlined.svg';
import {useTranslation} from 'react-i18next';
import {AccountBalanceWallet, CashIcon, ChevronDown, ExclamationCircle} from '../../../../shared/components/icons';
import CustomSelect from "../../../../shared/components/ui/select";
import styled from 'styled-components';

const SellVariant = lazy(() => import('./chooseAdType/SellVariant'));
const BuyVariant = lazy(() => import('./chooseAdType/BuyVariant'));
const ChooseP2PAdTypeCard = lazy(
  () => import('./chooseAdType/ChooseP2PAdTypeCard')
);

interface IChooseAdTypeProps {
  adType: AdTypesEnum;

  handleChangeAdType(type: AdTypesEnum): void;

  fiatList?: Array<IFiat>;
  currencyList?: Array<ICryptoCurrencyConstraintItem>;
  chosenCurrency?: ICryptoCurrencyConstraintItem;
  chosenFiat?: IFiat;

  handleChangeCurrency(currency?: ICryptoCurrencyConstraintItem): void;

  handleChangeFiat(fiat?: IFiat): void;

  isCurrencyListLoading: boolean;
  isFiatListLoading: boolean;
  activePrice: number;

  handleChangeAdCurrencyPrice(value: number): void;

  adCurrencyPrice: number;
  isNextBtnClick?: boolean;
  existingAds?: string[];
}

const ChooseAdType: FC<IChooseAdTypeProps> = (props) => {
  const {
    adCurrencyPrice,
    adType,
    activePrice,
    handleChangeAdType,
    handleChangeAdCurrencyPrice,
    fiatList,
    currencyList,
    handleChangeCurrency,
    isCurrencyListLoading,
    chosenCurrency,
    isFiatListLoading,
    handleChangeFiat,
    chosenFiat,
    isNextBtnClick,
    existingAds,
  } = props;

  const {t} = useTranslation();


  const getIcon = (isCash = false) => {
    const color = '#FBFBFF';

    const getIconComponent = (currency:string) => (
      <Icon
        component={getMoneyIcon({
          name: currency,
          size: 16,
        })}
      />
    );

    if (isCash) {
      return adType === AdTypesEnum.BUY
        ? (chosenCurrency ? getIconComponent(chosenCurrency.currency) : <CashIcon color={color} />)
        : (chosenFiat ? getIconComponent(chosenFiat.currency) : <AccountBalanceWallet color={color} />);
    } else {
      return adType === AdTypesEnum.BUY
        ? (chosenFiat ? getIconComponent(chosenFiat.currency) : <AccountBalanceWallet color={color} />)
        : (chosenCurrency ? getIconComponent(chosenCurrency.currency) : <CashIcon color={color} />);
    }
  };


  const getText = (isCash = false) => {
    if (adType === AdTypesEnum.SELL) {
      return isCash ? `${
        chosenFiat
          ? chosenFiat.currency
          : t('p2p.chooseAdType.fiat').toUpperCase()
      }` : `${
        chosenCurrency
          ? chosenCurrency.currency
          : t('p2p.chooseAdType.active').toUpperCase()
      }`
    }
    else {
      return isCash ? `${
        chosenCurrency
          ? chosenCurrency.currency
          : t('p2p.chooseAdType.active').toUpperCase()
      }` : `${
        chosenFiat
          ? chosenFiat.currency
          : t('p2p.chooseAdType.fiat').toUpperCase()
      }`
    }
  }


  return (
    <div className="create-ad-cards-wrapper">
      <div className="choose-p2p-ad-type-card">
        <div className="p2p-ad-card-content">
          <Typography className="create-p2p-ad-card-title">
            {t('p2p.chooseAdType.title')}
          </Typography>
          <div className="p2p-ad-card-content">
            <Radio.Group
              className="choose-p2p-ad-type-radio-group-dark"
              value={adType}
              size="large"
              onChange={(e) => handleChangeAdType(e.target.value)}
            >
              <RadioButtonStyled
                backgroundColor={
                  adType === AdTypesEnum.BUY ? '#474755' : '#2B2B36'
                }
                value={AdTypesEnum.BUY}
              >
                <Typography className="choose-p2p-ad-type-radio-group-dark-button-text">
                  {t('p2p.chooseAdType.radio.buy')}
                </Typography>
              </RadioButtonStyled>
              <RadioButtonStyled
                backgroundColor={
                  adType === AdTypesEnum.SELL ? '#474755' : '#2B2B36'
                }
                value={AdTypesEnum.SELL}
              >
                <Typography className="choose-p2p-ad-type-radio-group-dark-button-text">
                  {t('p2p.chooseAdType.radio.sell')}
                </Typography>
              </RadioButtonStyled>
            </Radio.Group>
            {adType === AdTypesEnum.SELL ? (
              <SellVariant {...props} />
            ) : (
              <BuyVariant {...props} />
            )}
          </div>

          {isNextBtnClick === true &&
            !(chosenCurrency && chosenFiat && activePrice !== 0) && (
              <ContainerCircleStyled>
                <ExclamationCircle/>
                <ValidationTypographyStyled>
                  {t('p2p.chooseAdType.validationError')}
                </ValidationTypographyStyled>
              </ContainerCircleStyled>
            )
          }

          {
            existingAds?.includes(`${chosenCurrency?.currency}${chosenFiat?.currency}`) &&
            <AdExistsWarning>
              <ExclamationCircle/>
              {t('announcements.adAlreadyExistsWarning')}
            </AdExistsWarning>
          }

          {
            existingAds?.includes(`${chosenFiat?.currency}${chosenCurrency?.currency}`) &&
            <AdExistsWarning>
              <ExclamationCircle/>
              {t('announcements.adAlreadyExistsWarning')}
            </AdExistsWarning>
          }
        </div>
        <div className="choose-p2p-ad-type-bottom-block">
          <div className="centered-block">
            <div className="row-item">
              {getIcon()}
              <Typography.Text className="create-p2p-ad-medium-text">
                {getText()}
              </Typography.Text>
            </div>
            <ArrowForwardIcon/>
            <div className="row-item">
              {getIcon(true)}
              <Typography.Text className="create-p2p-ad-medium-text">
                {getText(true)}
              </Typography.Text>
            </div>
          </div>
        </div>
      </div>
      <ChooseP2PAdTypeCard {...props} />
    </div>
  );
};

export default ChooseAdType;

const AdExistsWarning = styled(Typography.Text)`
  font-size: 12px;
  display: flex;
  gap: 8px;

  svg path {
    fill: #fbfbff;
  }
`;

const RadioButtonStyled = styled(Radio.Button)<{ backgroundColor: string }>`
  background-color: ${({backgroundColor}) => backgroundColor};
  width: 100%;
  border: none;
`;

const ContainerCircleStyled = styled.div`
  display: flex;
  height: 16px;
  align-items: center;
  gap: 8px;
`;

const ValidationTypographyStyled = styled(Typography)`
  margin-bottom: 12px;
  margin-top: 0;
  margin-inline: 0;
  color: #f16063;
`;

import {
  Beak,
  CloseIco,
  File,
  Pencil,
  UploadIco,
} from 'shared/components/icons';
import { Button, Typography } from '@pankod/refine-antd';
import { useUpdate } from '@pankod/refine-core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@pankod/refine-react-router-v6';

export const AnnButtons = ({
  status,
  disabled,
  itemId,
  itemType,
  itemCcy,
  itemFiat
}: {
  status: string;
  disabled: boolean;
  itemId: number | undefined;
  itemType: string;
  itemCcy: string;
  itemFiat: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex-row gap-4">
      <AnnButton
        itemId={itemId}
        disabled={status === 'executed' ? true : disabled}
        type={'edit'}
        title={t('announcements.buttons.editAd')}
        status={status}
        itemType={itemType}
        itemCcy={itemCcy}
        itemFiat={itemFiat}
      />
      {status !== 'template' ? (
        <AnnButton
          status={status}
          itemId={itemId}
          disabled={status === 'executed' ? true : disabled}
          type={'draft'}
          title={t('announcements.buttons.moveToDrafts')}
        />
      ) : (
        <AnnButton
          status={status}
          itemId={itemId}
          disabled={disabled}
          type={'upload'}
          title={t('announcements.buttons.postAd')}
        />
      )}
      <AnnButton
        itemId={itemId}
        disabled={disabled}
        type={'delete'}
        title={t('announcements.buttons.deleteAd')}
      />
    </div>
  );
};

const AnnButton = ({
  type,
  title,
  disabled,
  itemId,
  status,
  itemType,
  itemCcy,
  itemFiat
}: {
  type: string;
  title: string;
  disabled?: boolean;
  itemId: number | undefined;
  status?: string,
  itemType?: string;
  itemCcy?: string,
  itemFiat?: string
}) => {
  const { t } = useTranslation();

  const { mutate } = useUpdate();
  const changeStatus = (deleteAnn?: boolean) => {
    let newStatus = '';
    deleteAnn
      ? (newStatus = 'deleted')
      : status === 'template'
      ? (newStatus = 'created')
      : (newStatus = 'template');
    mutate({
      resource: 'offer',
      dataProviderName: 'p2p',
      id: `${itemId}`,
      values: {
        status: `${newStatus}`,
      },
    });
  };
  const navigate = useNavigate();
  const handleEditButton = (type: string) => {
    type !== 'edit' 
      ? changeStatus() 
      : navigate(`/p2p/edit-offer/${itemType}/${itemId}/${itemCcy}${itemFiat}`)
  }
  return (
    <>
      {type === 'delete' ? (
        <Button
          type='text'
          className={disabled ? 'disabled ann-button' : 'ann-button'}
          onClick={() => changeStatus(true)}
        >
          {
            <div
              style={{
                minHeight: disabled ? '46px' : '30px',
                bottom: 'unset',
                top: disabled ? '-170%' : '-130%',
              }}
              className={'customTitle upper'}
            >
              <Typography.Text
                style={{
                  color: '#2B2B36',
                  fontSize: '14px',
                  lineHeight: '17px',
                  fontWeight: 500,
                  textAlign: 'start',
                }}
              >
                {disabled ? t('announcements.status.haveOpenDeals') : title}
                {disabled && <br />}
                {disabled ? t('announcements.status.cannotEditAd') : ''}
              </Typography.Text>
              <Beak />
            </div>
          }
          <CloseIco />
        </Button>
      ) : (
        <Button
          className={disabled ? 'disabled ann-button' : 'ann-button'}
          onClick={() => handleEditButton(type)}
        >
          {
            <div
              style={{
                minHeight: disabled ? '46px' : '30px',
                bottom: disabled ? '-170%' : '-130%',
                top: 'unset',
              }}
              className={'customTitle lower'}
            >
              <Typography.Text
                style={{
                  color: '#2B2B36',
                  fontSize: '14px',
                  lineHeight: '17px',
                  fontWeight: 500,
                  textAlign: 'start',
                }}
              >
                {status === 'executed' 
                  ? t('announcements.status.adExecuted') 
                  : disabled 
                    ? t('announcements.status.haveOpenDeals') : title}
                {disabled && <br />}
                {disabled ? t('announcements.status.cannotEditAd') : ''}
              </Typography.Text>
              <Beak />
            </div>
          }
          {type === 'edit' ? (
            <Pencil />
          ) : type === 'draft' ? (
            <File />
          ) : (
            type === 'upload' && <UploadIco />
          )}
        </Button>
      )}
    </>
  );
};

import {Button, Form, Typography, useSimpleList} from '@pankod/refine-antd';
import {lazy, Suspense, useEffect, useMemo, useState} from 'react';
import {HttpError, useApiUrl, useCreate, useCustom, useList,} from '@pankod/refine-core';
import {IP2PUser} from 'interfaces';
import {ICryptoCurrencyConstraintItem} from 'widgets/order/types';
import {
  AdPriceType,
  AdTypesEnum,
  CreateP2PAdSteps,
  ICoinAccountWallet,
  IConfigCurrencyPair,
  ICreateP2PAdInfo,
  IFiat,
  IPaymentType,
  IRegion,
  P2PAdStatus,
} from 'features/p2p/createOrder/types';
import { ErrorBoundary } from '../../../app/ErrorBoundary';
import { ReactComponent as FileIcon } from '../../../shared/components/ui/assets/svg/fileIcon.svg';
import Modal from 'shared/components/ui/modal';
import i18n from 'i18next';
import {useTranslation} from 'react-i18next';
import {IPaymentsType, IPaymentsTypeBuyModified} from '../dashboard/interface';
import {validateP2PTotalSumNumber} from '../../../shared/helpers/valideteP2PTotalSumNumber';
import styled from "styled-components";
import ChooseAdType from "../../../features/p2p/createOrder/stepsContent/chooseAdType";
// import ChooseAdType from "../../../features/p2p/createOrder/stepsContent/chooseAdType/chooseAdType";
import { notification } from 'antd';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { useAuthState } from '../../../useAuthState';
import { FullScreenLoader } from 'shared/components/full-screen-loader';
import { useCookiesCustom } from '../../../shared/hooks/useCookieCustom';

const PageStepper = lazy(() =>
  import('features/p2p/createOrder/pageStepper').then((module) => ({
    default: module.default,
  }))
);

const PageHeading = lazy(() =>
  import('features/p2p/createOrder/pageHeading').then((module) => ({
    default: module.default,
  }))
);

const CheckAdData = lazy(() =>
  import('features/p2p/createOrder/stepsContent/checkAdData/checkAdData').then(
    (module) => ({
      default: module.default,
    })
  )
);

const ChooseLimitsAndPaymentType = lazy(() =>
  import(
    'features/p2p/createOrder/stepsContent/chooseLimitsAndPaymentType/chooseLimitsAndPaymentType'
  ).then((module) => ({
    default: module.default,
  }))
);

const SetAdditionalParams = lazy(() =>
  import('features/p2p/createOrder/stepsContent/setAdditionalParams').then(
    (module) => ({
      default: module.default,
    })
  )
);

export interface IPaymentLimitsErrors {
  region: string | null;
  payment: string | null;
}

interface IOfferLocal {
  type?: AdTypesEnum;
  fiat?: IFiat;
  currency?: ICryptoCurrencyConstraintItem;
  currencyPrice?: number;
  expire?: Date;
  totalSum?: string;
  minLimit?: string;
  maxLimit?: string;
  region?: IRegion;
  userPaymentList?: IPaymentType[];
  userPaymentListBuy?: Array<IPaymentsTypeBuyModified>;
  deal?: string;
  response?: string;
}

const Index = () => {
  const navigate = useNavigate();
  const {removeCookie} = useCookiesCustom();
  const { t } = useTranslation();
  const navigation = useNavigate();
  const apiUrl = useApiUrl();
  const [userData, setUserData] = useState<IP2PUser>();

  const [createAdType, setCreateAdType] = useState(AdTypesEnum.BUY);
  const [currentCreateAdStep, setCurrentCreateAdStep] = useState(
    CreateP2PAdSteps.CHOOSE_AD_TYPE
  );
  const [chosenFiat, setChosenFiat] = useState<IFiat>();
  const [chosenCurrency, setChosenCurrency] =
    useState<ICryptoCurrencyConstraintItem>();

  const [isOpenAddPaymentTypeSellModal, setIsOpenAddPaymentTypeSellModal] =
    useState(false);
  const [isOpenAddPaymentTypeBuyModal, setIsOpenAddPaymentTypeBuyModal] =
    useState(false);
  const [isNextBtnClick, setIsNextBtnClick] = useState(false);
  const [existingAds, setExistingAds] = useState<string[]>();

  const [form] = Form.useForm();
  const {
    listProps: adList,
  } = useSimpleList({
    resource: `offer`,
    dataProviderName: `p2p`,
    initialPageSize: 30,
    syncWithLocation: false,
    initialFilter: [
      {field: 'status', operator: 'ne', value: `deleted`},
      {
        field: 'owner_id',
        operator: 'eq',
        value: `${userData?.uid}`,
      },
    ],
    queryOptions: {
      onSuccess: ({data}) => {
        // setExistingAds(data.filter((item) => item.type === "sell").map((ad) => `${ad.ccy}${ad.fiat}`));

        // TODO: вот тут поменял

        if (data?.length) {
          data[0].type === 'sell'
              ? setExistingAds(data.filter((item) => item.type === "sell").map((ad) => `${ad.ccy}${ad.fiat}`))
              : setExistingAds(data.filter((item) => item.type === "buy").map((ad) => `${ad.fiat}${ad.ccy}`))
        }
      }
    }
  });

  const PAGE_STEPS = [
    {
      stepUnderText: t('createAd.pageSteps.adTypeAndPrice'),
      width: 117,
      marginLeft: 0,
    },
    {
      stepUnderText: t('createAd.pageSteps.totalAmountAndPaymentMethod'),
      width: 120,
      marginLeft: -47,
    },
    {
      stepUnderText: t('createAd.pageSteps.additionalParams'),
      width: 123,
      marginLeft: -40,
    },
    {
      stepUnderText: t('createAd.pageSteps.dataVerification'),
      width: 50,
      marginLeft: -20,
    },
  ];

  const formTotalSum = Form.useWatch('total-sum', form);
  const [chosenRegion, setChosenRegion] = useState<IRegion | undefined>(
    undefined
  );
  const [activePrice, setActivePrice] = useState(50);
  const [adCurrencyPrice, setAdCurrencyPrice] = useState(0);
  const [coinWalletBalance, setCoinWalletBalance] = useState(0);

  const [errors, setErrors] = useState<IPaymentLimitsErrors>(
    {} as IPaymentLimitsErrors
  );

  useList<IP2PUser, HttpError>({
    resource: 'account',
    dataProviderName: 'account',
    config: {
      pagination: {
        current: 1,
        pageSize: 1,
      },
    },
    queryOptions: {
      onSuccess: ({ data }) => {
        if (!!data[0]) {
          setUserData(data[0]);
        }
      },
    },
  });

  useCustom<{ data: ICoinAccountWallet[] }>({
    url: `${apiUrl}/${chosenCurrency?.currency?.toLowerCase()}-coin/api/account`,
    method: 'get',
    queryOptions: {
      enabled: chosenCurrency !== undefined,
      onSuccess: ({ data }) => {
        setCoinWalletBalance(
          !!data?.data?.length
            ? Math.max(...data?.data?.map((coinWallet) => coinWallet?.amount))
            : 0
        );
      },
      onError: () => {
        console.error('WALLET NOT FOUND');
        setCoinWalletBalance(0);
      },
      retry: false,
    },
  });

  const [userPaymentList, setUserPaymentList] = useState<Array<IPaymentType>>(
    []
  );
  const [userPaymentListBuy, setUserPaymentListBuy] = useState<
    Array<IPaymentsTypeBuyModified>
  >([]);
  const [pageUserPayments, setPageUserPayments] = useState(1);
  const [userPaymentListData, setUserPaymentListData] = useState<
    IPaymentType[]
  >([]);

  const { data: userPaymentListAPI } = useList<IPaymentType>({
    resource: 'payment',
    dataProviderName: 'p2p',
    config: {
      pagination: {
        current: pageUserPayments,
        pageSize: 25,
      },
      filters: [
        {
          field: 'account_id',
          operator: 'eq',
          value: userData?.uid,
        },
        {
          field: 'payment_type',
          operator: 'ne',
          value: 'cashPayment',
        },
      ],
    },
    queryOptions: {
      enabled: userData !== undefined,
      onSuccess: (res) => {
        if (pageUserPayments === 1) {
          setUserPaymentListData([...res.data]);
        } else setUserPaymentListData([...userPaymentListData, ...res.data]);
      },
    },
  });

  const { data: paymentTypesBuyData } = useList<IPaymentsType>({
    dataProviderName: 'p2p',
    resource: 'payment-types',
    queryOptions: {
      retry: false,
    },
  });

  const paymentTypesBuyDataModified = paymentTypesBuyData?.data.map(
    (payment) => ({
      id: payment.id,
      uniq_id: `${payment.payment_type}-${payment.id}`,
      payment_type: payment.payment_type,
      icon: payment.icon,
      items: payment.items?.map((item) => ({
        id: item.id,
        uniq_id: `${payment.payment_type}-${item.id}`,
        payment_type: payment.payment_type,
        name: item.name,
        icon: item.icon,
      })),
    })
  );
  const paymentTypesBuyDataSorted = paymentTypesBuyDataModified?.sort(function (
    a,
    b
  ) {
    return a.id.toString().localeCompare(b.id.toString());
  });

  const { data: fiatList, isLoading: isFiatListLoading } = useList<IFiat>({
    resource: 'fiat',
    dataProviderName: 'config',
    config: {
      pagination: {
        current: 1,
        pageSize: 15,
      },
    },
  });

  const {
    data: currencyConstraintDataList,
    isLoading: isLoadingCurrencyConstraintList,
  } = useList<ICryptoCurrencyConstraintItem, HttpError>({
    resource: 'currency-constraint',
    dataProviderName: 'config',
    config: {
      pagination: {
        current: 1,
        pageSize: 15,
      },
      filters: [
        {
          field: 'is_available_p2p',
          operator: 'eq',
          value: true,
        },
      ],
    },
  });

  const REFETCH_CURRENCY_PAIR_LIST_INTERVAL = 60000;

  const { data: currencyPairsList } = useList<IConfigCurrencyPair, HttpError>({
    resource: 'currency-pair',
    dataProviderName: 'config',
    config: {
      pagination: {
        current: 1,
        pageSize: 30,
      },
      filters: [
        {
          field: 'is_available_p2p',
          operator: 'eq',
          value: true,
        },
      ],
    },
    queryOptions: {
      refetchInterval: REFETCH_CURRENCY_PAIR_LIST_INTERVAL,
    },
  });

  const { data: regionList } = useList<IRegion, HttpError>({
    resource: 'regions',
    dataProviderName: 'p2p',
    config: {
      pagination: {
        current: 1,
        pageSize: 30,
      },
    },
  });

  const { mutate: createAd, isLoading: isLoadingCreate } = useCreate<
    any,
    HttpError,
    ICreateP2PAdInfo
  >();

  function translateBackErrors(message: string) {
    if (message.includes('Unable to publish Ad, you already have one')) {
      if (i18n.language === 'ru')
        return 'Невозможно опубликовать объявление, оно у вас уже есть';
      else return message;
    }

    if (message.includes('The max_sum_limit must not exceed the total sum')) {
      return t('messages.error.maxLimitExceed');
    }
    return message;
  }

  const onSubmitOrder = (status: P2PAdStatus) => {
    userData &&
    userData.id &&
    chosenCurrency &&
    chosenFiat &&
    createAdType &&
    activePrice !== 0 &&
    createAd(
      {
        dataProviderName: 'p2p',
        resource: 'offer',
        values: {
          status,
          ccy: chosenCurrency?.currency,
          fiat: chosenFiat?.currency,
          price: adCurrencyPrice,
          price_type: AdPriceType.FIXED,
          min_sum_limit: +form.getFieldValue('min-limit'),
          max_sum_limit: +form.getFieldValue('max-limit'),
          total_sum: +form.getFieldValue('total-sum'),
          payment_info:
            createAdType === AdTypesEnum.SELL
              ? userPaymentList.map((item) => item.id)
              : undefined,
          buy_payment_info:
            createAdType === AdTypesEnum.BUY ? parseDataPayment() : undefined,
          type: createAdType,
          region_id: chosenRegion?.id,
          deal_terms:
            form.getFieldValue('deal-terms') === ''
              ? undefined
              : form.getFieldValue('deal-terms'),
          auto_response:
            form.getFieldValue('auto-response') === ''
              ? undefined
              : form.getFieldValue('auto-response'),
        },
      },
      {
        onSuccess: () => {
          notification.success({
            message: t('messages.success.adCreatedSuccessfully'),
          });
          navigation('/p2p/my-announcements');
          sessionStorage.removeItem('offer-object');
        },
        onError: (error) =>
          notification.error({
            message: t('messages.error.adCreateError'),
            description: translateBackErrors(error.message),
          }),
      }
    );
  };

  const parseDataPayment = () => {
    return userPaymentListBuy.map((payment) =>
      payment.payment_type === 'bankCardPayment' ||
      payment.payment_type === 'bankTransferPayment'
        ? {
            bank_id: payment.id,
            payment_type: payment.payment_type,
            bank_name: payment.name,
            icon: payment.icon,
          }
        : payment.payment_type === 'webServicePayment'
        ? {
            id: payment.id,
            payment_type: payment.payment_type,
            web_service_name: payment.name,
            icon: payment.icon,
          }
        : {
            id: payment.id,
            payment_type: payment.payment_type,
            name: payment.name,
            icon: payment.icon,
          }
    );
  };

  const iconsData = useList<IPaymentsType, HttpError>({
    dataProviderName: `p2p`,
    resource: `payment-types`,
  });

  const [totalSum, setTotalSum] = useState('');
  const [minLimit, setMinLimit] = useState('');
  const [maxLimit, setMaxLimit] = useState('');
  const [deal, setDeal] = useState('');
  const [response, setResponse] = useState('');

  useEffect(() => {
    const jsonStr = sessionStorage.getItem('offer-object');
    if (jsonStr) {
      const parsed = JSON.parse(jsonStr) as IOfferLocal;
      if (parsed.expire && new Date() > new Date(parsed.expire))
        sessionStorage.removeItem('offer-object');
      else {
        parsed.type && setCreateAdType(parsed.type);
        parsed.fiat && setChosenFiat(parsed.fiat);
        parsed.currency && setChosenCurrency(parsed.currency);
        parsed.currencyPrice && setAdCurrencyPrice(parsed.currencyPrice);
        parsed.totalSum && setTotalSum(parsed.totalSum);
        parsed.minLimit && setMinLimit(parsed.minLimit);
        parsed.maxLimit && setMaxLimit(parsed.maxLimit);
        parsed.region && setChosenRegion(parsed.region);
        parsed.userPaymentListBuy &&
          setUserPaymentListBuy(parsed.userPaymentListBuy);
        parsed.userPaymentList && setUserPaymentList(parsed.userPaymentList);
        parsed.deal && setDeal(parsed.deal);
        parsed.response && setResponse(parsed.response);
      }
    }
  }, []);

  const handleSaveToDraft = <T extends keyof IOfferLocal>(
    field: T,
    value: IOfferLocal[T]
  ) => {
    const jsonStr = sessionStorage.getItem('offer-object');
    const expire = new Date();
    expire.setHours(expire.getHours() + 1);
    if (jsonStr) {
      const parsed = JSON.parse(jsonStr) as IOfferLocal;
      sessionStorage.setItem(
        'offer-object',
        JSON.stringify({
          ...parsed,
          [field]: value,
          expire,
        } as IOfferLocal)
      );
    } else {
      sessionStorage.setItem(
        'offer-object',
        JSON.stringify({ [field]: value, expire } as IOfferLocal)
      );
    }
  };

  const handleChangeType = (type: AdTypesEnum) => {
    setCreateAdType(type);
    handleSaveToDraft('type', type);
  };
  const handleChangeFiat = (fiat: IFiat) => {
    setChosenFiat(fiat);
    handleSaveToDraft('fiat', fiat);
  };
  const handleChangeCurrency = (fiat: ICryptoCurrencyConstraintItem) => {
    setChosenCurrency(fiat);
    handleSaveToDraft('currency', fiat);
  };
  const handleChangeCurrencyPrice = (value: number) => {
    setAdCurrencyPrice(value);
    handleSaveToDraft('currencyPrice', value);
  };
  const handleChangeTotalSum = (value: string) => {
    setTotalSum(value === '' ? '0' : validateP2PTotalSumNumber(value));
    handleSaveToDraft(
      'totalSum',
      value === '' ? '0' : validateP2PTotalSumNumber(value)
    );
  };
  const handleChangeMinLimit = (value: string) => {
    setMinLimit(value === '' ? '0' : value);
    handleSaveToDraft('minLimit', value === '' ? '0' : value);
  };
  const handleChangeMaxLimit = (value: string) => {
    setMaxLimit(value === '' ? '0' : value);
    handleSaveToDraft('maxLimit', value === '' ? '0' : value);
  };
  const handleChangeRegion = (region: IRegion) => {
    setChosenRegion(region);
    handleSaveToDraft('region', region);
  };

  const handleChangeList = (value: IPaymentType[]) => {
    setUserPaymentList(value);
    handleSaveToDraft('userPaymentListBuy', []);
    handleSaveToDraft('userPaymentList', value);
  };

  const handleChangeDeal = (value: string) => {
    setDeal(value);
    handleSaveToDraft('deal', value);
  };
  const handleChangeResponse = (value: string) => {
    setResponse(value);
    handleSaveToDraft('response', value);
  };
  const getCreateStepComponent = () => {
    switch (currentCreateAdStep) {
      case CreateP2PAdSteps.CHOOSE_AD_TYPE:
        return (
          <ChooseAdType
            adCurrencyPrice={adCurrencyPrice}
            handleChangeAdCurrencyPrice={handleChangeCurrencyPrice}
            activePrice={activePrice}
            isCurrencyListLoading={isLoadingCurrencyConstraintList}
            isFiatListLoading={isFiatListLoading}
            currencyList={currencyConstraintDataList?.data}
            fiatList={fiatList?.data}
            adType={createAdType}
            chosenFiat={chosenFiat}
            chosenCurrency={chosenCurrency}
            handleChangeAdType={handleChangeType}
            handleChangeCurrency={handleChangeCurrency}
            handleChangeFiat={handleChangeFiat}
            isNextBtnClick={isNextBtnClick}
            existingAds={existingAds}
          />
        );
      case CreateP2PAdSteps.CHOOSE_LIMITS_AND_PAYMENT_TYPE:
        return (
          <ChooseLimitsAndPaymentType
            totalSum={totalSum}
            handleChangeTotalSum={handleChangeTotalSum}
            minLimit={minLimit}
            maxLimit={maxLimit}
            handleChangeMinLimitMain={handleChangeMinLimit}
            handleChangeMaxLimitMain={handleChangeMaxLimit}
            errors={errors}
            setErrors={setErrors}
            createAdType={createAdType}
            handleOpenChoosePaymentTypeBuyModal={() =>
              setIsOpenAddPaymentTypeBuyModal(true)
            }
            handleOpenChoosePaymentTypeSellModal={() =>
              setIsOpenAddPaymentTypeSellModal(true)
            }
            form={form}
            adCurrencyPrice={adCurrencyPrice}
            activePrice={activePrice}
            currencyBalance={coinWalletBalance}
            chosenCurrency={chosenCurrency}
            chosenFiat={chosenFiat}
            regionList={regionList ? regionList.data : []}
            chosenRegion={chosenRegion}
            onSelectRegion={handleChangeRegion}
            userPaymentList={userPaymentList}
            userPaymentListBuy={userPaymentListBuy}
            setUserPaymentListBuy={setUserPaymentListBuy}
            deletePaymentType={(deletePaymentTypeId: number) =>
              setUserPaymentList((list) =>
                list.filter((item) => item.id !== deletePaymentTypeId)
              )
            }
            iconsData={iconsData.data?.data}
            deletePaymentTypeBuy={(deletePaymentTypeBuyId: string) =>
              setUserPaymentListBuy((list) =>
                list.filter((item) => item.uniq_id !== deletePaymentTypeBuyId)
              )
            }
          />
        );
      case CreateP2PAdSteps.SET_ADDITIONAL_PARAMS:
        return (
          <SetAdditionalParams
            form={form}
            deal={deal}
            handleChangeDeal={handleChangeDeal}
            handleChangeResponse={handleChangeResponse}
            response={response}
          />
        );
      case CreateP2PAdSteps.CHECK_AD_DATA:
        return (
          <CheckAdData
            additionalParamsForm={form}
            adPriceType={AdPriceType.FIXED}
            adCurrencyPrice={adCurrencyPrice}
            chosenCurrency={chosenCurrency}
            chosenFiat={chosenFiat}
            chosenRegion={chosenRegion}
            totalSum={
              form.getFieldValue('total-sum')
                ? form.getFieldValue('total-sum')
                : 0
            }
            minLimit={
              form.getFieldValue('min-limit')
                ? form.getFieldValue('min-limit')
                : 0
            }
            maxLimit={
              form.getFieldValue('max-limit')
                ? form.getFieldValue('max-limit')
                : 0
            }
            createAdType={createAdType}
            userPaymentList={userPaymentList}
            userPaymentListBuy={userPaymentListBuy}
            iconsData={iconsData.data?.data}
            existingAds={existingAds}
          />
        );
    }
  };

  const isNextStepButtonDisabled = () => {
    switch (currentCreateAdStep) {
      case CreateP2PAdSteps.CHOOSE_AD_TYPE:
        return !(
          userData &&
          userData.id &&
          chosenCurrency &&
          chosenFiat &&
          activePrice !== 0
        );
      case CreateP2PAdSteps.CHOOSE_LIMITS_AND_PAYMENT_TYPE:
        // TODO: Добавить описание ошибок при нажатии на кнопку, вернуть проверки
        // TODO: Проверить подгрузку способов оплаты
        // TODO: payment_info для продажи, buy_payment_info для покупки
        return !(
          chosenRegion &&
          +form.getFieldValue('max-limit') &&
          +form.getFieldValue('min-limit') &&
          +form.getFieldValue('max-limit') >=
            +form.getFieldValue('min-limit') &&
          +form.getFieldValue('total-sum') &&
          chosenFiat &&
          +form.getFieldValue('min-limit') >= +chosenFiat.dollar_exchange * 5 &&
          +form.getFieldValue('max-limit') <= activePrice * +totalSum &&
          +form.getFieldValue('total-sum') > 0 &&
          ((coinWalletBalance >= +formTotalSum &&
            userPaymentList.length <= 5 &&
            userPaymentList.length > 0 &&
            createAdType === AdTypesEnum.SELL &&
            coinWalletBalance >= +formTotalSum) ||
            (userPaymentListBuy.length <= 5 &&
              userPaymentListBuy.length > 0 &&
              createAdType === AdTypesEnum.BUY)) &&
          form.getFieldError('max-limit').length === 0
        );
      case CreateP2PAdSteps.CHECK_AD_DATA:
        return isLoadingCreate;
      default:
        return false;
    }
  };

  const limitsAndPaymentValidation = () => {
    if (
      currentCreateAdStep === CreateP2PAdSteps.CHOOSE_LIMITS_AND_PAYMENT_TYPE
    ) {
      if (!chosenRegion) {
        setErrors((prevState) => ({
          ...prevState,
          region: `${t(
            'p2p.chooseLimitsAndPaymentsType.inputs.chooseCountryPayment'
          )}`,
        }));
      }
      if (
        (createAdType === AdTypesEnum.BUY && userPaymentListBuy.length === 0) ||
        (createAdType === AdTypesEnum.SELL && userPaymentList.length === 0)
      ) {
        setErrors((prevState) => ({
          ...prevState,
          payment: `${t(
            'p2p.chooseLimitsAndPaymentsType.inputs.choosePayment'
          )}`,
        }));
      }
    }
  };

  const handleNext = () => {
    if (currentCreateAdStep === CreateP2PAdSteps.CHOOSE_LIMITS_AND_PAYMENT_TYPE)
      form.validateFields(['total-sum', 'min-limit', 'max-limit']).then();
    setIsNextBtnClick(true);
    limitsAndPaymentValidation();
    //TODO `возможно понадобиться`

    // if (!chosenFiat) {
    //   notification.warning({
    //     message: t('messages.warning.fiatNotSelected'),
    //   });
    // }
    // if (!chosenCurrency) {
    //   notification.warning({
    //     message: t('messages.warning.activeNotSelected'),
    //   });
    // }
    if (isNextStepButtonDisabled()) return;
    currentCreateAdStep === CreateP2PAdSteps.CHECK_AD_DATA
      ? onSubmitOrder(P2PAdStatus.CREATED)
      : setCurrentCreateAdStep((state) => state + 1);
  };

  // update price on pair change
  useEffect(() => {
    form.resetFields(['max-limit', 'min-limit', 'total-sum']);
    if (currencyPairsList) {
      const pairName = `${chosenCurrency?.currency}${chosenFiat?.currency}`;
      const pair = currencyPairsList.data.find((item) => item.pair == pairName);
      setActivePrice(pair?.latest_price ? +pair.latest_price : 0);
      setAdCurrencyPrice(pair?.latest_price ? +pair.latest_price : 0)
      if (
        sessionStorage.getItem('offer-object') &&
        !JSON.parse(sessionStorage.getItem('offer-object') ?? '').currencyPrice
      )
        setAdCurrencyPrice(pair?.latest_price ? +pair.latest_price : 0);
    }
  }, [chosenCurrency, chosenFiat, currencyPairsList]);

  const disableDraft = useMemo(
    () =>
      !(
        currentCreateAdStep === CreateP2PAdSteps.CHECK_AD_DATA &&
        !isLoadingCreate &&
        userData &&
        userData.id &&
        chosenCurrency &&
        chosenFiat &&
        activePrice !== 0 &&
        chosenRegion &&
        +form.getFieldValue('max-limit') &&
        +form.getFieldValue('min-limit') &&
        +form.getFieldValue('max-limit') >= +form.getFieldValue('min-limit') &&
        +form.getFieldValue('total-sum') &&
        +form.getFieldValue('min-limit') >= +chosenFiat.dollar_exchange * 5 &&
        +form.getFieldValue('max-limit') <=
          +chosenFiat.dollar_exchange * 50000 &&
        +form.getFieldValue('total-sum') > 0 &&
        ((userPaymentList.length <= 5 &&
          userPaymentList.length > 0 &&
          createAdType === AdTypesEnum.SELL &&
          coinWalletBalance >= +form.getFieldValue('total-sum')) ||
          (userPaymentListBuy.length <= 5 &&
            userPaymentListBuy.length > 0 &&
            createAdType === AdTypesEnum.BUY))
      ),
    [
      activePrice,
      chosenCurrency,
      chosenFiat,
      chosenRegion,
      coinWalletBalance,
      createAdType,
      currentCreateAdStep,
      form,
      isLoadingCreate,
      userData,
      userPaymentList.length,
      userPaymentListBuy.length,
    ]
  );
  const fileIconWrapperClass = disableDraft ? 'disabled-draft' : 'active-draft';

  return (
      <ErrorBoundary
          logout={() => {}}
          navigate={navigate}
          removeCookie={removeCookie}
      >
    <Suspense fallback={<FullScreenLoader />}>
      <Wrapper>
        <PageHeading
          onClose={() => navigation('/p2p/dashboard')}
          adType={createAdType}
        />
        <PageStepper
          labelPlacement="vertical"
          initial={1}
          steps={PAGE_STEPS}
          current={currentCreateAdStep + 1}
        />
        <Form
          validateTrigger={['onChange', 'onBlur']}
          name="choose-limits-form"
          form={form}
          layout="vertical"
        >
          {getCreateStepComponent()}
        </Form>
        <ButtonsWrapper>
          <BackButtonWrapper>
            <ButtonStyled
              type="primary"
              onClick={handleNext}
              disabled={
                currentCreateAdStep === CreateP2PAdSteps.CHECK_AD_DATA &&
                (existingAds?.includes(`${chosenCurrency?.currency}${chosenFiat?.currency}`) ||
                  existingAds?.includes(`${chosenFiat?.currency}${chosenCurrency?.currency}`))
              }
            >
              {currentCreateAdStep === CreateP2PAdSteps.CHECK_AD_DATA
                ? t('buttons.publish')
                : t('buttons.next')}
            </ButtonStyled>
            <ButtonStyled
              disabled={currentCreateAdStep === CreateP2PAdSteps.CHOOSE_AD_TYPE}
              type="dashed"
              onClick={() => {
                setCurrentCreateAdStep((state) => state - 1);
                setErrors((prevState) => ({
                  ...prevState,
                  payment: null,
                  region: null,
                }));
              }}
            >
              {t('buttons.back')}
            </ButtonStyled>
          </BackButtonWrapper>
          <Button
            disabled={disableDraft}
            type="link"
            onClick={() => onSubmitOrder(P2PAdStatus.TEMPLATE)}
          >
            <ButtonIcon
              className={disableDraft ? 'disabled-draft' : 'active-draft'}
            >
              <FileIcon />
              <Typography.Text>{t('buttons.saveAsDraft')}</Typography.Text>
            </ButtonIcon>
          </Button>
        </ButtonsWrapper>

        {/* Модалка добавления способа оплаты для покупки */}
        <Modal.AddPaymentTypeBuy
          handleClickAddNew={() => navigation('/p2p/settings/payment')}
          handleClickAddChosenPaymentTypesBuy={(payments) => {
            setUserPaymentListBuy(payments);
            setErrors((prevState) => ({
              ...prevState,
              payment: null,
              region: null,
            }));
            setErrors((prevState) => ({ ...prevState, payment: null }));
          }}
          userPaymentListBuy={userPaymentListBuy}
          open={isOpenAddPaymentTypeBuyModal}
          onCancel={() => setIsOpenAddPaymentTypeBuyModal(false)}
          title={t('payments.addingAPaymentMethod')}
          paymentTypesBuyData={
            paymentTypesBuyDataSorted ? paymentTypesBuyDataSorted : []
          }
        />

        {/* Модалка добавления способа оплаты для продажи */}
        <Modal.AddPaymentTypeSell
          userPaymentList={userPaymentList}
          open={isOpenAddPaymentTypeSellModal}
          onCancel={() => setIsOpenAddPaymentTypeSellModal(false)}
          title={t('createAd.choosingAPaymentMethod')}
          userPaymentTypes={userPaymentListData}
          handleClickAddChosenPaymentTypes={(payments) => {
            setUserPaymentList(payments);
            setErrors((prevState) => ({
              ...prevState,
              payment: null,
              region: null,
            }));
            handleChangeList(payments);
            setErrors((prevState) => ({ ...prevState, payment: null }));
          }}
          handleClickAddNew={() => navigation('/p2p/settings/payment')}
          iconsData={iconsData.data?.data}
          setPage={setPageUserPayments}
          userPaymentListAPI={userPaymentListAPI}
        />
      </Wrapper>
    </Suspense>
</ErrorBoundary>
  );
};

export { Index };

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 32px;
`;

const BackButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const ButtonStyled = styled(Button)`
  min-width: 180px;
`;

const ButtonIcon = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

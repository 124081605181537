import { TVChartContainer } from '../../features/spot/chart/TVChartContainer';
import styled from 'styled-components';

const MobileSpotChart = () => {
  return (
    <ChartWrapperStyled>
      <TVChartContainer symbol={''} interval={'5'} isMobile={true} />
    </ChartWrapperStyled>
  );
};

const ChartWrapperStyled = styled.div`
  height: 100vh;
`;

export default MobileSpotChart;

import { ACCOUNT_URL } from '../../../../packages/keycloak-client/constants';
import { NavLink, useLocation } from '@pankod/refine-react-router-v6';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import React from 'react';
import { IconComponent, ISidebarElement } from './useGetSideBarElements';

interface Props {
  item: ISidebarElement;
  DrawSideBarMenuElement: (
    title: string,
    icon: IconComponent,
    isSelected: boolean,
    isSubmenu?: boolean | undefined,
    hasChild?: boolean | undefined
  ) => JSX.Element;
}

const Item = ({ item, DrawSideBarMenuElement }: Props) => {
  let loc = useLocation();
  const isSelected = loc.pathname.includes(item.pathKey);

  return {
    key: item.pathKey,
    label: (
      <>
        {item.pathKey.includes(ACCOUNT_URL) && !!ACCOUNT_URL ? (
          <a
            href={item.pathKey}
            onClick={() => !!item?.onClick && item?.onClick()}
          >
            {DrawSideBarMenuElement(
              item.title,
              () => item.iconComponent(isSelected ? '#FBFBFF' : '#767687'),
              isSelected,
              false,
              !!item.childerns
            )}
          </a>
        ) : (
          <NavLink
            to={
              item.pathKey.includes(ACCOUNT_URL) && !!ACCOUNT_URL
                ? ''
                : item.pathKey
            }
            style={{ paddingLeft: 0 }}
            onClick={() => !!item?.onClick && item?.onClick()}
          >
            {DrawSideBarMenuElement(
              item.title,
              () => item.iconComponent(isSelected ? '#FBFBFF' : '#767687'),
              isSelected,
              false,
              !!item.childerns
            )}
          </NavLink>
        )}
      </>
    ),
    children: item.childerns
      ? item.childerns.map((item): ItemType => {
          const isSelected = loc.pathname.includes(item.pathKey);
          return {
            key: item.pathKey,
            label: (
              <>
                <a href={item.pathKey}>
                  <div style={{ paddingLeft: 10 }}>
                    {DrawSideBarMenuElement(
                      item.title,
                      () => item.iconComponent(isSelected ? '' : '#767687'),
                      isSelected,
                      true
                    )}
                  </div>
                </a>
              </>
            ),
          };
        })
      : undefined,
  };
};

export default Item;

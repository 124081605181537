import { balanceFractionDigits } from '../constants';

// Приводит число к виду с 9-ю числами в дробной части
export const getFractionNumber = (balance: number): string => {
  if (!!String(balance)?.length) {
    return balance?.toFixed(balanceFractionDigits);
  }

  return 0?.toFixed(balanceFractionDigits);
};

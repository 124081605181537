import Wallet from '../../../widgets/wallet/ui/Wallet/Wallet';
import { ErrorBoundary } from '../../../app/ErrorBoundary';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { useCookiesCustom } from '../../../shared/hooks/useCookieCustom';

export function CoinAccountsList() {
  const navigate = useNavigate();
  const {removeCookie} = useCookiesCustom();

  return (
    <ErrorBoundary
      logout={() => {}}
      navigate={navigate}
      removeCookie={removeCookie}
    >
      <Wallet />
    </ErrorBoundary>
  );
}

import {Progress, Typography} from "@pankod/refine-antd"
import {useTranslate} from "@pankod/refine-core";
import styled from "styled-components"
import {IP2PCollapseArrayElement, IP2PCollapseOpen} from "./interface";
import {ProfileCircle} from "shared/components/icons";
import {toFixed} from "shared/helpers/toFixed";

export const CollapseOpenLeftContent: React.FC<IP2PCollapseOpen> = ({
                                                                      dataAd,
                                                                      countDeals,
                                                                      methodArray,
                                                                      regions
                                                                    }) => {
  const translate = useTranslate();

  const getImplementedSum = () => {
    return `${toFixed(
      +(+dataAd.total_sum - +dataAd.sum_left),
      8
    ) || 0
    } ${dataAd.ccy}`;
  }

  return (
    <>
      <ContentContainer>
        <FlexRowConteinerFirst>
          <ProfileWidth>
            <ProfileWrapper>
              <ProfileCircle size={24}/>
              <NicknameText>
                {dataAd.owner_info.nickname}
              </NicknameText>
            </ProfileWrapper>
            <DealsText>
              {countDeals}
            </DealsText>
          </ProfileWidth>
          <FlexDealInfo>
            <MainDealInfoContainer>
              <NumberWithTextContainer>
                <NormalText>
                  {getImplementedSum()}
                </NormalText>
                <SmallText $color={'#C3C3CE'}>
                  {translate('dashboard.collapseHeader.implemented')}
                </SmallText>
              </NumberWithTextContainer>
              <NumberWithTextContainer>
                <NormalText>
                  {`${toFixed(Number(dataAd.sum_left), 8)} ${dataAd.ccy}`}
                </NormalText>
                <SmallText $textAlign={'end'}>
                  {translate('dashboard.collapseHeader.available')}
                </SmallText>
              </NumberWithTextContainer>
            </MainDealInfoContainer>
            <Progress
              percent={
                (+dataAd.total_sum - +dataAd.sum_left) /
                (+dataAd.total_sum / 100)
              }
              trailColor={'#474755'}
              strokeColor={{
                direction: '80.91deg',
                from: '#088473',
                to: '#12BFA8',
              }}
              showInfo={false}
            />
          </FlexDealInfo>
        </FlexRowConteinerFirst>
        <FlexRowConteiner>
          <ContainerAddiotionInfo>
            <NumberWithTextContainer>
              <NormalText>
                {`${toFixed(Number(dataAd.total_sum), 8)} ${dataAd.ccy}`}
              </NormalText>
              <SmallText $color={'#C3C3CE'}>
                {translate('dashboard.collapseHeader.totalVolume')}
              </SmallText>
            </NumberWithTextContainer>
            <NumberWithTextContainer>
              <NormalText>
                {translate(`p2p.regions.${regions.find((item) => item.id === dataAd.region_id)?.name}`)}
              </NormalText>
              <SmallText $color={'#C3C3CE'}>
                {translate('p2p.checkAdData.paymentCountry')}
              </SmallText>
            </NumberWithTextContainer>
            <NumberWithTextContainer>
              <NormalText $color={'#FAFAFC'}>
                30 {translate('dashboard.collapseBody.time')}
              </NormalText>
              <SmallText $color={'#C3C3CE'}>
                {translate('dashboard.collapseHeader.timeForPayment')}
              </SmallText>
            </NumberWithTextContainer>
          </ContainerAddiotionInfo>
        </FlexRowConteiner>
        <FlexColumnConteiner style={{gap: 8,}}>
          <NormalText>
            {translate('p2p.checkAdData.paymentMethods')}
          </NormalText>
          <CollapseList methodArray={methodArray}/>
        </FlexColumnConteiner>
        {dataAd.deal_terms && (
            <FlexColumnConteiner style={{gap: 8}}>
              <NormalText>
                {translate('p2p.checkAdData.termsOfATransaction')}
              </NormalText>
              <DealTerms>
                <NormalText $color={'#C3C3CE'}>
                  {dataAd.deal_terms}
                </NormalText>
              </DealTerms>
            </FlexColumnConteiner>
        )}
      </ContentContainer>
    </>
  )
}

interface ICollapseList {
  methodArray: (icon?: boolean, method?: boolean) => IP2PCollapseArrayElement[];
}

export const CollapseList: React.FC<ICollapseList> = ({methodArray}) => {
  return (
    <MapWrapper>
      {methodArray().length && methodArray().map((item, index) => {
          return (
            <MapItem item={item} index={index}/>
          )
        }
      )}
    </MapWrapper>
  );
}

interface IMapItem {
  item: any;
  index: number;
}

export const MapItem: React.FC<IMapItem> = ({item, index}) => {
  return (
    <CustomTag key={`dashboard-collapse-open-tab-${index}`}>
      <SmallText $color={'#FBFBFF'} $fontWeight={500}>
        {item.title ? item.title : item?.label}
      </SmallText>
      <SmallText $color={'#767687'} $fontWeight={500}>
        {item.type}
      </SmallText>
    </CustomTag>
  );
}


const ContainerAddiotionInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const NumberWithTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const MainDealInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ContentContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 60%;
  width: 100%;
  border-right: 1px solid #474755;
`

const FlexRowConteinerFirst = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 50px;
  border-bottom: 1px solid #413D5080;
`


const FlexRowConteiner = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const FlexColumnConteiner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const SmallText = styled(Typography.Text)<{
  $color?: string
  $fontWeight?: number,
  $textAlign?: string
}>`
  font-size: 12px;
  line-height: normal;
  color: ${(props) => props.$color};
  font-weight: ${(props) => props.$fontWeight}px;
  text-align: ${(props) => props.$fontWeight};
`

const NormalText = styled(Typography.Text)<{ $color?: string }>`
  line-height: normal;
  color: ${(props) => props.$color};
`

const CustomTag = styled.div`
  border-radius: 8px;
  background-color: #2B2B36;
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const DealTerms = styled.div`
  max-height: 100px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #C3C3CE;
    border-radius: 4px;
  }
`

const ProfileWidth = styled.div`
  max-width: max-content;
  width: 100%;
`;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const FlexDealInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const NicknameText = styled(Typography.Text)`
  margin: 0px;
  margin-left: 8px;
  color: #FAFAFC;
`;

const DealsText = styled(Typography.Text)`
  color: #C3C3CE;
  line-height: normal;
`;

const MapWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  min-height: 84px;
  align-content: flex-start;
`;

import React, {Dispatch, useEffect, useState} from 'react';
import CountryPhoneInput, {ConfigProvider, CountryPhoneInputValue,} from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
import 'antd/dist/reset.css';
import 'antd-country-phone-input/dist/index.css';
import 'flagpack/dist/flagpack.css';
import {PayService} from '../../../../../pages/p2p/settings/payment/payment';
import Comment from '../Comment';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

interface Props {
  controlsTemplatePay: [PayService, Dispatch<PayService>];
  setIsValid: Dispatch<boolean>;
  clickSubmit: boolean;
  phoneMask: (phone: string | undefined) => string | undefined;
  errorHasPayment: boolean;
}

const SBP = ({
               controlsTemplatePay: [templatePay, setTemplatePay],
               setIsValid,
               clickSubmit,
               phoneMask,
               errorHasPayment,
             }: Props) => {
  const {t} = useTranslation();

  const [value, setValue] = useState<CountryPhoneInputValue>({short: 'RU'});
  const [error, setError] = useState('num');
  const getFlag = (short: string) => {
    const data = require(`world_countries_lists/data/flags/24x24/${short?.toLowerCase()}.png`);
    // for dumi
    if (typeof data === 'string') {
      return data;
    }
    // for CRA
    return data.default;
  };

  const onChange = (value: string, key: keyof PayService) => {
    setTemplatePay({
      ...templatePay,
      [key]: value.replace(/[()\s-]/g, ''),
    });
  };

  useEffect(() => {
    setError(
      !templatePay.num ||
      (templatePay.num &&
        (templatePay.num?.length < 10 ||
          templatePay.num.length > 20 ||
          value.phone?.length !== 15))
        ? 'num'
        : templatePay.comment && templatePay.comment.length > 40
          ? 'comment'
          : ''
    );
  }, [templatePay, clickSubmit]);

  const validateText = () =>
    !value.phone?.length
      ? t('payments.enterMobileNumber')
      : t('payments.incorrectMobileNumber');

  useEffect(() => {
    setIsValid(error === '');
  }, [error]);

  return (
    <ConfigProvider
      areaMapper={(area) => {
        return {
          ...area,
          emoji: <ImageStyled alt="flag" src={getFlag(area.short)}/>,
        };
      }}
      locale={en}
    >
      <div className={'bank-container'}>
        <span className={'bank-title'}>{t('payments.types.sbp')}</span>
        <div className={'bank-form'}>
          <div className={'bank-container-input'}>
            <span className={'bank-input-label'}>
              {t('payments.enterMobileNumber')}
            </span>
            <CountryPhoneInput
              maxLength={14}
              status={(error === 'num' && clickSubmit) || errorHasPayment ? 'error' : undefined}
              placeholder={'1 (702) 123-4567'}
              inline
              value={value}
              onChange={(v) => {
                v.phone = phoneMask(v.phone);
                setValue(v);
                if (v.phone && v.phone?.length <= 15) {
                  onChange(`+${value.code}${v.phone}`, 'num');
                }
              }}
            />
            {error === 'num' && clickSubmit && (
              <span className={'bank-input-label-error'}>
                {validateText()}
              </span>
            )}
            {errorHasPayment && (
              <span className={'bank-input-label-error'}>
                {t('payments.errorAddSbpPayment')}
              </span>
            )}
          </div>
          <Comment
            templatePay={templatePay}
            error={error}
            clickSubmit={clickSubmit}
            onChange={onChange}
          />
        </div>
      </div>
    </ConfigProvider>
  );
};

const ImageStyled = styled.img`
  width: 18px;
  height: 18px;
  vertical-align: sub;
`;

export default SBP;

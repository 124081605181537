import { IParsedCurrencyData, TCoinName } from '../types';

export const getCoinFromCurrencyConstraintData = (
  coinName: TCoinName,
  currencyConstraint: IParsedCurrencyData[]
): IParsedCurrencyData | undefined => {
  if (currencyConstraint?.length) {
    const coin = currencyConstraint?.filter(
      (currency) =>
        currency?.currency?.toLowerCase() === coinName?.toLowerCase()
    );

    if (!!coin?.length) return coin[0];
  }
};

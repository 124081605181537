import {
  CoinIcon,
  WhiteBitcoinIcon,
  WhiteBnbIcon,
  WhiteEthIcon,
  WhiteEurIcon,
  WhitePolygonIcon,
  WhiteRubIcon,
  WhiteTetherIcon,
  WhiteUsdcIcon,
  WhiteUsdIcon,
} from '../../../shared/components/icons';

// Возвращает иконку монеты по ее названию
export const getIconByCoinName = (coinShortName: string, size?: number) => {
  switch (coinShortName) {
    case 'btc':
      return <WhiteBitcoinIcon size={size} />;
    case 'usdt':
      return <WhiteTetherIcon size={size}/>;
    case 'eth':
      return <WhiteEthIcon size={size}/>;
    case 'usdc':
      return <WhiteUsdcIcon size={size}/>;
    case 'matic':
      return <WhitePolygonIcon size={size}/>;
    case 'eur':
      return <WhiteEurIcon size={size}/>;
    case 'rub':
      return <WhiteRubIcon size={size}/>;
    case 'usd':
      return <WhiteUsdIcon size={size}/>;
    case 'bnb':
      return <WhiteBnbIcon size={size}/>;
    default:
      return <CoinIcon/>;
  }
};

import {
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from '@pankod/refine-antd';
import Styles from './index.module.css';
import { PlusIcon } from 'shared/components/ui/assets/svg/plusIcon';

interface IUploadFilesAppealProps {
  value?: UploadFile[];
  onChange?: (value: UploadFile[]) => void;
  fileList: UploadFile[] | undefined;
  setFileList: (value: UploadFile[]) => void;
}

const UploadFilesAppeal = (props: IUploadFilesAppealProps): JSX.Element => {
  const { fileList, setFileList } = props;

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (props.onChange) props.onChange(newFileList);
  };

  const uploadButton = (
    <div>
      <PlusIcon />
      <div style={{ marginTop: 8 }}>
        <Typography.Text>Загрузить</Typography.Text>
      </div>
    </div>
  );

  return (
    <div className={Styles.files_container}>
      <div className={Styles.files_container__header}>
        <Typography.Text>Загрузите доказательства</Typography.Text>
        <Typography.Text>
          {fileList ? fileList.length : 0}/
          <span className={Styles.files_container__max}>5</span>
        </Typography.Text>
      </div>
      <Typography.Text className={Styles.files_container__description}>
        Общий размер файлов не должен превышать 50 Мб. Поддерживаемые форматы:
        .png, .jpg
      </Typography.Text>
      <Upload
        /*action={(file) => {
          console.log(file);
          uploadImageOnServer();
          return 'asd';
        }}*/
        listType="picture-card"
        onChange={handleChange}
        /*        onChange={(file:  UploadProps['onChange']) => {
          if (fileList) {
            setFileList([...fileList, file]);
          }
        }}*/
        className={Styles.files_upload}
        fileList={fileList}
        beforeUpload={(file) => {
          if (fileList) {
            setFileList([...fileList, file]);
          }

          return false;
        }}
        onRemove={(file) => {
          if (fileList) {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
          }
        }}
      >
        {fileList ? (fileList.length >= 5 ? null : uploadButton) : uploadButton}
      </Upload>
    </div>
  );
};

export { UploadFilesAppeal };

import image1 from 'shared/components/ui/assets/svg/emptyListIcon.svg';
import image2 from 'shared/components/ui/assets/svg/emptyP2P.svg';
import styled, { css } from 'styled-components';

type TProps = {
  text: string;
  mode?: string;
};
const EmptyComponent = ({ text, mode = 'default' }: TProps) => {
  const EmptyComponentContainer = styled.div`
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  const EmptyComponentImg = styled.img<{ $mode?: string }>`
    margin-bottom: 30px;

    ${(props) =>
      props.$mode !== 'default' &&
      css`
        width: 15rem;
        height: 15rem;
      `}
  `;

  const EmptyComponentText = styled.div`
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: white;
  `;

  return (
    <EmptyComponentContainer>
      <EmptyComponentImg src={mode === 'default' ? image1 : image2} alt="" />
      <EmptyComponentText>{text}</EmptyComponentText>
    </EmptyComponentContainer>
  );
};

export default EmptyComponent;

import React from 'react';
import { Tabs } from 'antd';
import { TabsProps } from '@pankod/refine-antd';
import tab from './tabs.module.css';
import "./index.css"

interface ITabs extends TabsProps {}

const Index: React.FC<ITabs> = ({ ...props }) => {
  return (
    <>
      <Tabs
        tabBarStyle={{ padding: '12px 0 36px 0' }}
        className={tab.myTab + ' wallet-custom-tabs'}
        {...props}
      />
    </>
  );
};

export default Index;

import { Form, FormInstance, Input, UploadFile } from '@pankod/refine-antd';
import { IAppealFormValues } from './interface';
import { SelectAppealReason } from './reason';
import { UploadFilesAppeal } from './files';

interface IAppealModalFormProps {
  form: FormInstance<IAppealFormValues>;
  reasonList: { key: string; placeholder: string }[];
  fileList: UploadFile[];
  setFileList: (data: UploadFile[]) => void;
}

const AppealModalForm = (props: IAppealModalFormProps) => {
  const { fileList } = props;
  return (
    <Form form={props.form} layout="vertical" requiredMark={false}>
      <Form.Item
        label="Выберите причину апелляции"
        name={'reason'}
        rules={[{ required: true, message: 'Выберите причину аппеляции' }]}
      >
        <SelectAppealReason list={props.reasonList} />
      </Form.Item>

      <Form.Item
        label="Введите описание проблемы"
        name={'description'}
        rules={[
          { required: true, message: 'Введите описание проблемы' },
          { max: 500, message: 'Максимальное кол-во символов 500' },
        ]}
      >
        <Input.TextArea
          placeholder="Описание проблемы"
          autoSize={{ minRows: 6, maxRows: 10 }}
        />
      </Form.Item>

      <Form.Item
        name={'files'}
        rules={[
          {
            required: true,
            message: 'Доказательства обязательны для заполнения',
          },
        ]}
      >
        <UploadFilesAppeal
          fileList={fileList}
          setFileList={props.setFileList}
        />
      </Form.Item>
    </Form>
  );
};

export { AppealModalForm };

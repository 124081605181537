import styled from 'styled-components';

const CARD_COMPONENTS = {
  Shadow: styled.div`
    filter: drop-shadow(0 3px 8px rgba(50, 50, 71, 0.1));
    cursor: pointer;
  `,
  CardWrapper: styled.div`
    position: relative;
    background: rgba(12, 26, 75, 0.08);
    width: 362px;
    height: 226px;
    border-radius: 17px;
    //clip-path: polygon(0 0, 100% 0, 100% 95%, 85% 100%, 0 100%);
    cursor: pointer;

    & :hover {
      background: linear-gradient(80.47deg, #2B2B36 6.62%, #2B2B36 148.62%) padding-box, linear-gradient(150deg, #5297FF 0%, rgba(82, 151, 255, 0) 70%) border-box;
    }
  `,
  Card: styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 24px;
    max-width: 362px;
    max-height: 226px;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    border-width: 2px 0 0 2px;
    border-style: solid;
    border-color: transparent;
    background: linear-gradient(80.47deg, #18181F 6.62%, #2D2D3A 148.62%) padding-box, linear-gradient(150deg, #5297FF 0%, rgba(82, 151, 255, 0) 55%) border-box;
    border-radius: 16px;
  `,
  Tags: styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
    max-width: 312px;
    gap: 13px;
  `,
  TextBlock: styled.div`
    display: flex;
    flex-direction: column;
  `,
  CardHeader: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  `,
};

export {CARD_COMPONENTS};

import {Popover, Tag, Tooltip, Typography} from "@pankod/refine-antd";
import {FC, useMemo, useState} from "react";
import {ProfileCircle} from "shared/components/icons";
import {IP2PCollapseArrayElement, IP2PCollapseClose} from "./interface";
import {IPaymentInfo} from "pages/p2p/dashboard/interface";
import {ButtonsTypes} from "shared/components/ui/button/filledButton.p";
import {toFixed} from "shared/helpers/toFixed";
import {useTranslate} from "@pankod/refine-core";
import Button from "shared/components/ui/button";
import styled from "styled-components";
import {ReactComponent as Cross} from "../../../../shared/components/ui/deal-card/image/cross.svg";
import UserTooltip from "../../../../shared/components/ui/deal-card/tooltip";
import {IP2POrderUser} from "../../../../pages/p2p/myDeals/cards/myTrades.p";

export const CollapseClose: FC<IP2PCollapseClose & { onClick: () => void }> = ({
                                                                                       dataAd,
                                                                                       countDeals,
                                                                                       methodArray,
                                                                                       user,
                                                                                       setOpenModal,
                                                                                       setFromCard,
                                                                                       typeText,
                                                                                       onClick
                                                                                     }) => {

  const translate = useTranslate();
  const [open, setOpen] = useState(false);

  const plusCount = useMemo(() => {
    const count =
      dataAd.type === 'buy'
        ? dataAd?.buy_payment_info?.length ?? 0
        : (dataAd?.payment_info as IPaymentInfo[])
          .map((item) => item.payment_details)
          .flat().length;
    return (
      <>
        {count - 3 > 0 && (
          <span
            style={{
              color: '#FBFBFF',
              fontWeight: '500',
              fontSize: '14px',
            }}
          >
            +{count - 3}
          </span>
        )}
      </>
    );
  }, [dataAd]);


  const handleClickBtn = () => {
    if (!!user) {
      if (!user.nickname?.length) {
        setOpenModal(true);
        setFromCard(true);
      }
    }
  }

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };


  return (
    <>
      <MainCloseContainer onClick={onClick}>
        <div>
          <WidthWrapper>
            <ProfileWrapper>
              <Popover
                open={open}
                onOpenChange={handleOpenChange}
                trigger="hover"
                title={
                  <PopoverTitleWrapper onClick={(e) => e.stopPropagation()}>
                    {translate('myDealsCard.tooltip.title')}
                    <CloseBtnPopover onClick={hide}>
                      <Cross/>
                    </CloseBtnPopover>
                  </PopoverTitleWrapper>
                }
                overlayStyle={{
                  border: '1px solid #767687',
                  borderRadius: 8,
                }}
                className={'flex text-white align-items-center'}
                overlayInnerStyle={{border: 'none'}}
                content={
                  <PaddingWrapper onClick={(e) => e.stopPropagation()}>
                    <UserTooltip
                      user={dataAd.owner_info as IP2POrderUser}
                    ></UserTooltip>
                  </PaddingWrapper>
                }
              >
                <ProfileCircle/>
                <NicknameText>
                  {dataAd.owner_info.nickname}
                </NicknameText>
              </Popover>
            </ProfileWrapper>
            <SmallText>
              {countDeals}
            </SmallText>
          </WidthWrapper>
        </div>
        <DealInfoContainer>
          <MinWidthContainer $minWidth={140}>
            <FlexContainer>
              <Typography.Text>
                {`${toFixed(Number(dataAd.price), 2)} ${dataAd.fiat}`}
              </Typography.Text>
              <SmallText>
                {translate('dashboard.collapseHeader.priceCoin')}
              </SmallText>
            </FlexContainer>
          </MinWidthContainer>
          <MinWidthContainer $minWidth={140}>
            <FlexContainer>
              <Typography.Text>
                {`${toFixed(Number(dataAd.sum_left), 8)} ${dataAd.ccy}`}
              </Typography.Text>
              <SmallText>
                {translate('dashboard.collapseHeader.available')}
              </SmallText>
            </FlexContainer>
          </MinWidthContainer>
          <MinWidthContainer $minWidth={240}>
            <FlexContainer>
              <Typography.Text>
                {toFixed(Number(dataAd.min_sum_limit), 2)} -{' '}
                {toFixed(Number(dataAd.max_sum_limit), 2)}{' '}
                {dataAd.fiat}
              </Typography.Text>
              <SmallText>
                {translate('dashboard.collapseHeader.limit')}
              </SmallText>
            </FlexContainer>
          </MinWidthContainer>
          <div>
            <TooltipListWrapper>
              {methodArray().length &&
                methodArray()
                  .slice(0, 3)
                  .map((item, index) => (
                      <TooltipItemComponent item={item} index={index}/>
                    )
                  )
              }
              <Tooltip
                color={'#2B2B36'}
                title={
                  methodArray().length && methodArray().map((item, index) => (
                    <TextItemComponent item={item} index={index}/>
                  ))
                }
              >
                {plusCount}
              </Tooltip>
            </TooltipListWrapper>
          </div>
        </DealInfoContainer>
        <div>
          <ButtonStyled
            onClick={handleClickBtn}
            type={ButtonsTypes.outlined}
            height={'32px'}
          >
            <TypographyTextStyled>
              {typeText.text}
            </TypographyTextStyled>
          </ButtonStyled>
        </div>
      </MainCloseContainer>
    </>
  )
}

interface ITooltipItem {
  item: IP2PCollapseArrayElement;
  index: number;
}

const TooltipItemComponent: FC<ITooltipItem> = ({item, index}) => {
  return (
    <Tooltip
      color={'#2B2B36'}
      title={
        <SmallPadding>
          <Typography>
            {item?.title ?? item.label}
          </Typography>
          <TypographyStyled>
            {item?.type}
          </TypographyStyled>
        </SmallPadding>
      }
    >
      <TagStyled
        color={'#474755'}
        key={`dashboard-collapse-tab-${index}`}
      >
        {item?.label}
      </TagStyled>
    </Tooltip>
  )
}

const TextItemComponent: FC<ITooltipItem> = ({item, index}) => {
  return (
    <SmallPadding key={`dashboard-collapse-tab-plus-${index}`}>
      <Typography>
        {item?.title ?? item?.label}
      </Typography>
      <TypographyStyled>
        {item?.type}
      </TypographyStyled>
    </SmallPadding>
  )
}

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: max-content;
  height: 40px;
  line-height: normal;
`

const SmallText = styled(Typography.Text)`
  font-size: 12px;
  color: #C3C3CE;
`
const DealInfoContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`

const MainCloseContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  padding: 24px 16px;
`

const ButtonStyled = styled(Button)`
  width: 84px;
`

const TypographyTextStyled = styled(Typography.Text)`
  font-size: 12px;
  font-weight: 600;
`

const MinWidthContainer = styled.div<{ $minWidth?: number }>`
  min-width: ${(props) => props.$minWidth}px;
`
const WidthWrapper = styled.div`
  width: max-content;
`

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

const NicknameText = styled(Typography.Text)`
  margin: 0;
  margin-left: 8px;
  color: #FAFAFC;
`

const SmallPadding = styled.div`
  padding: 4px;
`
const TypographyStyled = styled(Typography)`
  color: #767687;
`

const TagStyled = styled(Tag)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
`

const TooltipListWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
`

const CloseBtnPopover = styled.button`
  background: none;
  cursor: pointer;
  border: none;
  position: absolute;
  right: 16px;
  top: 24px;
`;

const PaddingWrapper = styled.div`
  padding: 0 8px 8px;
`

const PopoverTitleWrapper = styled.div`
  color: #FBFBFF;
  margin-bottom: 16px;
  padding: 8px 8px 0;
`

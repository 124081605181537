import { DatePicker, MenuProps, Select, Skeleton, Typography } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { coinArr } from 'pages/coin-account/coinArr';
import React, { useEffect, useState } from 'react';
import { SelectIcon } from 'shared/components/icons';
import { LogicalFilter } from '@pankod/refine-core';
import { RangeValue } from 'features/myOrders/myOrders.p';
import { Icon } from '@pankod/refine-antd';
import { DashCircle } from 'shared/components/ui/assets/svg/svgIcons';
import dayjs from 'dayjs';
import {
  useLocation,
  useNavigate,
  useParams,
} from '@pankod/refine-react-router-v6';
import styled from 'styled-components';
import { getIconByCoinName } from '../../entities/coin';
import { useAuthState } from '../../useAuthState';

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  // Can not select days after today
  return current && current > dayjs().endOf('day');
};

interface ICAFilters {
  isLoading: boolean;
  statusItemsInputMenu: MenuProps;
  statusItemsOutputMenu: MenuProps;
  currentStatus: string | JSX.Element | undefined;
  startDate: Date;
  endDate: Date;
  setFilter: (filter: LogicalFilter) => void;
  dates: RangeValue;
  value: RangeValue;
  setValue: (arg: RangeValue) => void;
  setDates: (arg: RangeValue) => void;
  currentTabKey: string;
  statusKey: string;
}

export const CAFilters: React.FC<ICAFilters> = ({
  isLoading,
  statusItemsInputMenu,
  statusItemsOutputMenu,
  currentTabKey,
  currentStatus,
  setFilter,
  dates,
  value,
  setDates,
  setValue,
}) => {
  const currentCoinName = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpenCoinSelect, setIsOpenCoinSelect] = useState(false);
  const [isOpenStatusSelect, setIsOpenStatusSelect] = useState(false);
  const [isOpenDateSelect, setIsOpenDateSelect] = useState(false);
  const balance = useAuthState((state) => state.balance);

  const myCoins = coinArr.filter((coin) => {
    // TODO: Проверить как работает баланс
    if (balance) {
      // @ts-ignore
      return balance[coin?.id?.toUpperCase()];
    } else {
      return [];
    }
  });

  const getInitValue = (value: string | undefined) => {
    var res = '';
    myCoins.forEach((item) => {
      if (item.id === value) {
        res = item.name;
      }
    });
    return res;
  };
  const [selectedOption, setSelectedOption] = useState(
    getInitValue(currentCoinName.coin)
  );

  const statusesArray =
    currentTabKey === 'inputs' ? statusItemsInputMenu : statusItemsOutputMenu;

  const getNewPath = (path: unknown) => {
    let res = '';
    myCoins.forEach((item) => {
      if (item.name === path) {
        res = item.id;
      }
    });
    navigate(`/coin-accounts/${res}`);
  };

  const handleChangeStatusOpen = (value: boolean) =>
    setIsOpenStatusSelect(value);
  const handleChangeCoinOpen = (value: boolean) => setIsOpenCoinSelect(value);
  const handleChangeDateOpen = (value: boolean) => setIsOpenDateSelect(value);

  const createLabelCoin = (coinId: string, coinName: string) => (
    <SelectItem>
      {getIconByCoinName(coinId)}
      <Typography.Text>{coinName}</Typography.Text>
    </SelectItem>
  );

  const SuffixIcon = ({ transform }: { transform: string }) => (
    <ContainerArrow transform={transform}>
      <SelectIcon />
    </ContainerArrow>
  );

  useEffect(() => {
    if (location && location.pathname)
      setSelectedOption(
        myCoins.find((coin) => coin?.id === location.pathname.split('/').pop())
          ?.name || 'Bitcoin'
      );
  }, [location.pathname]);

  return (
    <>
      <Skeleton
        active={isLoading}
        loading={isLoading}
        title={false}
        className={'custom-skeleton-elements'}
        paragraph={{
          rows: 1,
          width: '100%',
        }}
      >
        <div
          className={
            'flex-row justify-content-space-between position-absolute filters-container'
          }
        >
          <div className={'flex-row gap-8 align-items-center'}></div>
          <div className={'ant-modal align-items-center flex gap-16'}>
            <SelectStyled
              onDropdownVisibleChange={handleChangeCoinOpen}
              onChange={(e) => getNewPath(e)}
              optionLabelProp={'label'}
              defaultValue={selectedOption}
              value={selectedOption}
              suffixIcon={
                <SuffixIcon
                  transform={`rotate(${isOpenCoinSelect ? 180 : 0}deg)`}
                />
              }
              className={'select-style'}
            >
              {myCoins.map((coin) => (
                <Select.Option
                  key={coin.name}
                  value={coin.name}
                  label={createLabelCoin(coin.id, coin.name)}
                >
                  <SelectItem>
                    {getIconByCoinName(coin.id, 24)}
                    <SelectedItemInnerStyled>
                      <Typography.Text style={{ fontSize: 14 }}>
                        {coin.name}
                      </Typography.Text>
                      <SelectItemDescription>
                        {coin.id.toUpperCase()}
                      </SelectItemDescription>
                    </SelectedItemInnerStyled>
                  </SelectItem>
                </Select.Option>
              ))}
            </SelectStyled>
            <SelectStyled
              onDropdownVisibleChange={handleChangeStatusOpen}
              defaultValue={currentStatus}
              value={currentStatus}
              suffixIcon={
                <SuffixIcon
                  transform={`rotate(${isOpenStatusSelect ? 180 : 0}deg)`}
                />
              }
              className={'select-style'}
            >
              {statusesArray?.items?.map((status: any) => (
                <SelectOptionStyled key={status.key} value={status.label}>
                  <ContainerItemSelectStyled
                    onClick={() => {
                      if (currentStatus !== status.key) status.onClick();
                    }}
                  >
                    <Typography.Text className={'text-14px'}>
                      {status && status.label}
                    </Typography.Text>
                  </ContainerItemSelectStyled>
                </SelectOptionStyled>
              ))}
            </SelectStyled>
            <DatePicker.RangePicker
              onOpenChange={handleChangeDateOpen}
              suffixIcon={
                <SuffixIcon
                  transform={`rotate(${isOpenDateSelect ? 180 : 0}deg)`}
                />
              }
              clearIcon={<Icon component={() => DashCircle()} />}
              separator={<>-</>}
              disabledDate={disabledDate}
              value={value || dates || undefined}
              onCalendarChange={(val) => {
                setDates(val);
              }}
              onChange={(val) => {
                setValue(val);
                if (val === null) {
                  setFilter({
                    field: 'created_at',
                    operator: 'contains',
                    value: '',
                  });
                } else {
                  setFilter({
                    field: 'created_at',
                    operator: 'between',
                    value: val,
                  });
                }
              }}
            />
          </div>
        </div>
      </Skeleton>
    </>
  );
};

const ContainerArrow = styled.div<{ transform: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  transform: ${({ transform }) => transform};
`;

const SelectItem = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  gap: 10px;

  & svg {
    height: 24px;
    width: 24px;
  }
`;
const SelectedItemInnerStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectItemDescription = styled(Typography.Text)`
  color: rgba(118, 118, 135, 1);
  font-size: 14px;
`;

const SelectStyled = styled(Select)`
  width: 270px;
  background: rgb(43, 43, 54);
  border-radius: 8px;
`;

const SelectOptionStyled = styled(Select.Option)`
  display: flex;
  align-items: center;
`;

const ContainerItemSelectStyled = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

import React, {lazy, useEffect, useState} from 'react';
import {Tabs, Typography} from 'antd';
import {CrudFilter, GetListResponse, HttpError, QueryObserverResult, useCustom, useList,} from '@pankod/refine-core';
import {ErrorBoundary} from '../../../app/ErrorBoundary';
import {IGetListData} from './cards/myTrades.p';
import './styles/styles.css';
import tab from '../../../shared/components/ui/Tabs/tabs.module.css';
import * as _ from 'lodash';
import {ICurrencyTemp} from '../dashboard/interface';
import {useTranslation} from 'react-i18next';
import {UserCards} from '../../../shared/components/ui/deal-card/card';
import {API_URL} from '../../../packages/keycloak-client/constants';
import {useAuthState} from '../../../useAuthState';
import {useNavigate} from '@pankod/refine-react-router-v6';
import {DealsList} from 'features/p2p/dealsList/dealsList';
import styled from 'styled-components';
import {useCookiesCustom} from '../../../shared/hooks/useCookieCustom';

const Deals = lazy(() =>
    import('./tabs-content/Deals').then((module) => ({
        default: module.default,
    }))
);

const SkeletonMyDeals = lazy(() =>
    import('./skeleton').then((module) => ({
        default: module.default,
    }))
);

export type IFilterDeals = Record<string, CrudFilter>;

export const initFilter: IFilterDeals = {};

export interface IDealData {
    type: 'buy' | 'sell' | 'all';

    filter: IFilterDeals;

    setFilter(value: IFilterDeals): void;

    data: QueryObserverResult<GetListResponse<IGetListData>, HttpError>;
    currency: QueryObserverResult<GetListResponse<ICurrencyTemp>, HttpError>;
    deals?: IGetListData[];
}

type FilterFields = 'buyer_id' | 'seller_id' | 'type';

const MyDeals = () => {
    const navigate = useNavigate();
    const {cookie, removeCookie} = useCookiesCustom();
    const {t} = useTranslation();
    const [filter, setFilter] = useState<IFilterDeals>({});
    const [type, setType] = useState<IDealData['type']>('all');
    const [page, setPage] = useState(1);
    const [deals, setDeals] = useState<IGetListData[]>([]);
    const userAPI = useAuthState((state) => state?.userAPI);
    const userId = userAPI?.uid || cookie.uid || localStorage.getItem('uid');

    const currencyData = useList<ICurrencyTemp, HttpError>({
        dataProviderName: 'config',
        resource: 'currency-constraint',
        config: {
            filters: [
                {
                    field: 'is_available_p2p',
                    operator: 'eq',
                    value: true,
                },
            ],
            pagination: {
                current: 1,
                pageSize: 25,
            },
        },
    });

    const setTypeFilter = (
        type: string,
        field: FilterFields,
        value: string
    ): void => {
        setFilter((prevState) =>
            Object.assign(prevState, {
                [type]: {
                    field: field,
                    operator: 'eq',
                    value: value,
                },
            })
        );
    };

    const data = useList<IGetListData, HttpError>({
        dataProviderName: `p2p`,
        resource: `order`,
        config: {
            pagination: {
                current: page,
            },
            filters: [...Object.values(filter)],
            sort: [{field: 'updated_at', order: 'desc'}],
        },
        queryOptions: {
            onSuccess: (res) => {
                if (page === 1) {
                    setDeals([...res.data]);
                } else setDeals([...deals, ...res.data]);
            },
        },
    });

    interface IUserData {
        nickname: string;
        p2p_total_orders_amount: number;
        p2p_success_orders_amount: number;
        p2p_canceled_orders_amount: number;
        p2p_unique_agents_amount: number;
        created_at: string;
    }

    const user_data = useCustom<{ account: IUserData }>({
        url: `${API_URL}/account/api/me`,
        method: 'get',
    });

    useEffect(() => {
        setPage(1);
    }, [filter]);

    const onHandleChangeType = (value: string) => {
        setType(value as IDealData['type']);
        switch (value) {
            case 'all':
                setFilter({...initFilter});
                break;
            case 'sell':
                setFilter(_.omit(filter, 'id2'));
                setTypeFilter('deal', 'type', value);
                setTypeFilter('id', 'buyer_id', userId);

                break;
            case 'buy':
                setFilter(_.omit(filter, 'id'));
                setTypeFilter('deal', 'type', value);
                setTypeFilter('id', 'seller_id', userId);

                break;
        }

        setDeals([]);
    };

    useEffect(() => {
        if (!data.isLoading || !user_data.isLoading) {
            setTimeout(() => setIsLoading(data.isLoading), 1000);
        }
    }, [data]);

    const [isLoading, setIsLoading] = useState(true);

    if (isLoading) {
        return <SkeletonMyDeals/>;
    }

    const dealData: IDealData = {
        data,
        currency: currencyData,
        deals: data.data?.data,
        filter: filter,
        setFilter,
        type,
    };

    const handleNextPage = (visibility: boolean) => {
        if (
            visibility &&
            data.data?.data !== undefined &&
            data.data.data.length > 0
        ) {
            setPage((prevState) => prevState + 1);
        }
    };

    return (
        <ErrorBoundary
            logout={() => {
            }}
            navigate={navigate}
            removeCookie={removeCookie}
        >
            <Column className={'flex-column'}>
                <div className={'flex-column gap-24'}>
                    <Typography.Title style={{margin: 0}}>
                        {t('myDeals.title')}
                    </Typography.Title>
                    {user_data.isSuccess ? (
                        <UserCards user={user_data.data?.data.account}/>
                    ) : (
                        <></>
                    )}
                    <Tabs
                        className={tab.myTab + ' tabs-margin-0'}
                        onChange={onHandleChangeType}
                        items={[
                            {
                                key: 'all',
                                label: t('myDeals.filters.all'),
                            },
                            {
                                key: 'sell',
                                label: t('myDeals.filters.sell'),
                            },
                            {
                                key: 'buy',
                                label: t('myDeals.filters.buy'),
                            },
                        ]}
                    />
                </div>
                <Deals {...dealData} />
                <DealsList
                    type={type}
                    handleNextPage={handleNextPage}
                    isLoading={isLoading}
                    deals={deals}
                />
            </Column>
        </ErrorBoundary>
    );
};

export default MyDeals;

const Column = styled.div`
    //gap: 26px;
    height: 100%;
`;

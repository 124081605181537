import { Typography } from '@pankod/refine-antd';
import STYLES from './index.module.css';
import { ExclamationCircleIcon } from 'shared/components/ui/assets/svg/exclamation-circle';

const AppealModalAlert = () => {
  return (
    <div className={STYLES.container}>
      <div>
        <ExclamationCircleIcon />
      </div>
      <div className={STYLES.text_container}>
        <Typography.Text>
          Причина апелляции и доказательства доступны обеим сторонам и службе
          поддержки. Не загружайте персональные и конфиденциальные данные.
        </Typography.Text>
        <Typography.Text>
          Обратите внимание, что злоупотребление жалобами приведет к блокировке
          аккаунта. Переговоры по апелляции проводятся через чат.
        </Typography.Text>
      </div>
    </div>
  );
};

export { AppealModalAlert };

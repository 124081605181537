import XLSX from 'sheetjs-style';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';

export const saveAsXLSX = <T extends {}>(data: T[], filename: string) => {
  const titles = Object.values(data[0]);
  data.shift();
  const ws = XLSX.utils.aoa_to_sheet([
    titles,
    ...data.map((i) => Object.values(i)),
  ]);
  for (const i in ws) {
    if (typeof ws[i] != 'object') continue;
    ws[i].s = {
      border: {
        right: {
          style: 'thin',
          color: '000000',
        },
        left: {
          style: 'thin',
          color: '000000',
        },
        top: {
          style: 'thin',
          color: '000000',
        },
        bottom: {
          style: 'thin',
          color: '000000',
        },
      },
    };
  }
  const excelBuffer = XLSX.write(
    {
      Sheets: { [dayjs(new Date()).format('DD.MM.YYYY')]: ws },
      SheetNames: [dayjs(new Date()).format('DD.MM.YYYY')],
    },
    {
      bookType: 'xlsx',
      type: 'array',
    }
  );
  const dataS = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  FileSaver.saveAs(dataS, `${filename}.xlsx`);
};

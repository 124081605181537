import { create, Mutate, StoreApi, UseBoundStore } from 'zustand';
import { axios } from './shared/exios';
import { API_URL } from './packages/keycloak-client/constants';
import { IParsedCurrencyData, TNetwork, TWallet } from './entities/coin';

type TCoinData = {
  name: string;
  balance: {
    amount: number;
    deposit: number;
    invest_amount: number;
  };
};

type TWalletState = {
  balances: TCoinData[];
  setBalances: (balances: TCoinData[]) => void;
  getBalances: () => void;
  getCurrencyConstraintsWithNetworks: () => void;
  currencyConstraintsWithNetworks: [];
  selectedCoin?: IParsedCurrencyData | undefined;
  selectedCoinNetwork?: TNetwork | undefined;
  setSelectedCoin: (coin: IParsedCurrencyData) => void;
  setSelectedCoinNetwork: (network: TNetwork | undefined) => void;
  setWallet: (wallet?: TWallet | undefined) => void;
  wallet?: TWallet;
  isBalanceVisible: boolean;
  setBalanceVisible: () => void;
  findWallet: (
    wallets: TWallet[],
    network: TNetwork | undefined
  ) => TWallet | undefined;
  getWallets: (selectedCoin: IParsedCurrencyData) => Promise<TWallet[]>;
  resetWalletData: () => void;
};

export const useWalletState: UseBoundStore<Mutate<StoreApi<TWalletState>, []>> =
  create((set) => ({
    wallet: undefined,
    walletTries: 0,
    setWallet: (wallet?: TWallet) => set({ wallet }),
    balances: [],
    currencyConstraintsWithNetworks: [],
    selectedCoin: undefined,
    selectedCoinNetwork: undefined,
    isBalanceVisible: true,
    setBalanceVisible: () =>
      set((state) => ({ isBalanceVisible: !state.isBalanceVisible })),
    setBalances: (balances: TCoinData[]) => set({ balances }),
    setSelectedCoin: (coin: IParsedCurrencyData | null) =>
      //@ts-ignore
      set({ selectedCoin: coin }),
    // @ts-ignore
    setSelectedCoinNetwork: (network: TNetwork | null | undefined) =>
      //@ts-ignore
      set({ selectedCoinNetwork: network }),
    getBalances: async () => {
      try {
          const res = await axios.get(`${API_URL}/account/api/me`);
        const arr: TCoinData[] = [];
        Object.entries(res?.data?.balance).map(([key, value]) => {
          return arr.push({ name: key, balance: value } as TCoinData);
        });
        set({ balances: arr });
      } catch (e) {
        console.error('getBalances e', e);
      }
    },
    getCurrencyConstraintsWithNetworks: async () => {
      try {
        const res = await axios.post(
          `${API_URL}/config/api/currency-constraint/search?limit=10&page=1`,
          {
            includes: [
              {
                relation: 'networks',
              },
            ],
          }
        );
        set({ currencyConstraintsWithNetworks: res.data.data });
      } catch (e) {
        console.error('getCurrencyConstraintsWithNetworks error', e);
      }
    },
    getWallets: async (selectedCoin: IParsedCurrencyData) => {
      try {
        const { data } = await axios.get<{ data: TWallet[] }>(
          `/${selectedCoin?.currency?.toLowerCase()}-coin/api/wallet`
        );
        return data.data;
      } catch (e) {
        console.error('getWallets error', e);
        return [];
      }
    },
    findWallet: (
      wallets: TWallet[],
      network: TNetwork | undefined = undefined
    ) => {
      let wallet: TWallet | undefined;
      if (network) {
        wallet = wallets.find((wallet) => wallet.network === network.network);
      } else {
        wallet = wallets[0];
      }
      return wallet;
    },
    resetWalletData: () => {
      set({
        selectedCoin: undefined,
        selectedCoinNetwork: undefined,
        wallet: undefined,
      });
    },
  }));

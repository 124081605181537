import React, {useEffect, useState} from 'react';
import Icon from '@ant-design/icons';
import {notification, Typography} from 'antd';
import {UserIconSmall,} from '../../../../shared/components/icons';
import {IGetListData} from '../../../../pages/p2p/myDeals/cards/myTrades.p';
import { useGetLocale } from '@pankod/refine-core';
import {getP2POrderInfo} from '../../../../shared/helpers/getP2POrderInfo';
import {getCurrentStepP2P} from '../../../../shared/helpers/getCurrentStepP2P';
import {useGetPaymentInfoIconData} from '../../../../shared/hooks/use-get-payment-info-icon-data';
import {IPaymentInfo} from '../../../../pages/p2p/dashboard/interface';
import TransferInfo from '../transferInfo';
import {createDateAsUTC} from '../../../../shared/helpers/convertTime';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {DotStepperBlock} from '../dotStepper';
import {BackLink} from './backLink'
import {OrderTimer} from "../orderTimer";
import {OrderButtons} from "./buttons";
import { useCookiesCustom } from '../../../../shared/hooks/useCookieCustom';
import {useAuthState} from "../../../../useAuthState";

const BuySellOrderCard = React.lazy(() => import('../components/BuySellOrderCard'));

interface IOrderDeals {
  isSeller: boolean;
  currentOrder: IGetListData;
  currTimerValue: number;
  isTimerActive: boolean;
  currCommission: number;
  isMyAppeal: boolean;
  updateP2POrderStatus: (status: string, useNotification?: boolean) => void;
  handleOnOpenAppealModal: () => void;
  handleOnOpenAppealCloseModal: () => void;
  openConfirmModalBuyer: () => void;
  openConfirmModalSellerSeller: () => void;
  paymentInfo: IPaymentInfo | undefined;
  updateP2PAppealStatus: (status: string) => void;
  adId?: number;
}

const OrderDeals: React.FC<IOrderDeals> = ({
                                             isSeller,
                                             currentOrder,
                                             currTimerValue,
                                             isTimerActive,
                                             isMyAppeal,
                                             updateP2POrderStatus,
                                             handleOnOpenAppealModal,
                                             handleOnOpenAppealCloseModal,
                                             openConfirmModalSellerSeller,
                                             openConfirmModalBuyer,
                                             paymentInfo,
                                             adId
                                           }) => {
  const {t} = useTranslation();
  const currLocale = useGetLocale();
  const uid = useAuthState((state) => state?.userAPI?.uid);

  const p2pOrderStatusDataIconText = getP2POrderInfo(currentOrder.status);

  const currentStepStatusButtons = getCurrentStepP2P({
    dealType: currentOrder.type,
    isSeller,
    currentStatus: currentOrder.status,
    isMyAppeal,
    appealsAmount: currentOrder.appeals?.length,
    currentOrder,
    userId: uid || '',
  });

  const calledEndTime = new Date(
    new Date(createDateAsUTC(new Date(currentOrder.timer_start))).setSeconds(
      +currTimerValue +
      +new Date(
        createDateAsUTC(new Date(currentOrder.timer_start))
      ).getSeconds()
    )
  );

  const [isDisableAppeal, setIsDisableAppeal] = useState(true);
  const currentStep = currentStepStatusButtons.currentStep;
  const {paymentData} = useGetPaymentInfoIconData(paymentInfo);

  useEffect(() => {
    setIsDisableAppeal(+calledEndTime - +new Date() < 0);
  }, []);

  const timerProps = {
    currentOrder,
    isTimerActive,
    handleFinish: setIsDisableAppeal,
    initSeconds: +calledEndTime - +new Date() > 0
      ? new Date(+calledEndTime - +new Date()).getMinutes() *
      60 +
      new Date(+calledEndTime - +new Date()).getSeconds()
      : 0
  }

  const getPercentOfCompletedDeals = () => {
    return currentOrder.seller_info?.total_orders_amount === 0
      ? 0
      : +(
        +(+currentOrder.seller_info?.success_orders_amount /
          +currentOrder.seller_info?.total_orders_amount
        ) * 100
      )?.toFixed()
  }

  const leftButtonOnclick = () => {
    switch (currentStepStatusButtons.currentStep) {
      case 0:
        updateP2POrderStatus('respond_accepted');
        break;
      case 1:
        if (!isSeller) {
          openConfirmModalBuyer();
        } else {
          if (currentOrder.type === 'buy') {
            openConfirmModalBuyer();
          }
        }
        break;
      case 2:
        if (isSeller) {
          openConfirmModalSellerSeller();
        } else {
          if (currentOrder.type === 'buy') {
            openConfirmModalSellerSeller();
          }
        }
    }
  }
  const rightButtonOnClick = () => {
    switch (currentStepStatusButtons.currentStep) {
      default:
        if (isMyAppeal) {
          handleOnOpenAppealCloseModal();
        } else {
          handleOnOpenAppealModal();
        }
    }
  }

  const getSumInFiat = () => {
    return `${
      !!currentOrder?.price && !!currentOrder?.sum
        ? numberWithSpaces(+(+currentOrder.price * +currentOrder.sum).toFixed(2))
        : 0
    } ${currentOrder.fiat}`
  }

  const getComissionsDeals = () => {
    return `${(currentOrder.sum - +currentOrder.sum_minus_commission)
      ?.toFixed(10)
      .replace(/\.?0+$/, '')} ${currentOrder.ccy}`
  }

  const numberWithSpaces = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  const getCoinPrice = () => {
    return `
      1 ${currentOrder.ccy?.toUpperCase()} =
      ${!!currentOrder.price
      ? numberWithSpaces(+((+currentOrder.price).toFixed(2)))
      : 0
    }
    ${currentOrder.fiat}
    `
  }
  const [contextHolder] = notification.useNotification();


  return (
    <>
      {/*{contextHolder}*/}
      <TitleWrapper>
        <BackLink isSeller={isSeller} adId={adId}/>
        <div className={'flex-row gap-8'}>
          <GreyText>
            {t('p2p.orderDeals.createdAt')}
          </GreyText>
          <GreyText>
            {new Date(currentOrder.created_at).toLocaleString(currLocale())}
          </GreyText>
        </div>
        <GreyText>
          {`№ ${currentOrder.id}`}
        </GreyText>
      </TitleWrapper>

      <div className={'flex-column gap-16'}>
        <div className={'flex-row justify-content-space-between'}>
          <div className={'flex-column gap-8'}>
            <div className={'flex-row gap-8'}>
              {p2pOrderStatusDataIconText.icon}
              <Typography.Text className={'bold-18px-text'}>
                {p2pOrderStatusDataIconText.text}
              </Typography.Text>
            </div>
            <div>
              <GreyText>
                {t('p2p.orderDeals.dealStatus')}
              </GreyText>
            </div>
          </div>
          <OrderTimer {...timerProps} />
        </div>
        <DotStepperBlock currentOrder={currentOrder} currentStep={currentStep}/>
      </div>

      <div className={'flex-column gap-8'}>
        <div className={'flex-row gap-8'} style={{height: '112px'}}>
          <CardMini className={'flex-column'}>
            <div className={'flex-column gap-8'}>
              <div className={'flex-row gap-4'}>
                {currentOrder.type === 'buy'
                  ? <BuySellOrderCard currentOrder={currentOrder} type={currentOrder.type}/>
                  : <BuySellOrderCard currentOrder={currentOrder} type={currentOrder.type}/>
                }
              </div>
            </div>
            <div className={'flex-column gap-4'}>
              <Text>
                {t('p2p.orderDeals.coinPrice')}
              </Text>
              <Typography.Text className={'middle-bold-14px-text'}>
                {getCoinPrice()}
              </Typography.Text>
            </div>
          </CardMini>

          <CardMini className={'flex-column'}>
            <div className={'flex-column gap-4'}>
              <Text>
                {t('p2p.orderDeals.sumInActive')}
              </Text>

              <Typography.Text className={'middle-bold-14px-text'}>
                {`${currentOrder.sum} ${currentOrder.ccy}`}
              </Typography.Text>
            </div>

            <div className={'flex-column gap-4'}>
              <Text>
                {t('p2p.orderDeals.sumInFiat')}
              </Text>

              <Typography.Text className={'middle-bold-14px-text'}>
                {getSumInFiat()}
              </Typography.Text>
            </div>
          </CardMini>

          <CardMini className={'flex-column'}>
            <div className={'flex-column gap-4'}>
              <div className={'flex-row gap-8'}>
                <Text>
                  {`${currentOrder.seller_info?.total_orders_amount} ${t('p2p.orderDeals.deals')}`}
                </Text>
                <Text>
                  {`${getPercentOfCompletedDeals()}% ${t('p2p.orderDeals.completed')}`}
                </Text>
              </div>

              <SellerWrapper>
                <Icon component={() => UserIconSmall()}/>
                <Typography.Text className={'pre-bold-12px-text'}>
                  {currentOrder.seller_info?.nickname}
                </Typography.Text>
              </SellerWrapper>
            </div>

            <div className={'flex-column gap-4'}>
              <Text>
                {t('p2p.orderDeals.commissionDeals')}
              </Text>
              <Typography.Text className={'bold-14px-text'}>
                {getComissionsDeals()}
              </Typography.Text>
            </div>
          </CardMini>
        </div>

        <div className={'flex-row gap-16'}>
          <TransferInfo
            paymentData={paymentData}
            hideTransferData={currentOrder.status === 'respond'}
            isDealSuccess={currentOrder.status === 'success'}
            isColumnDisplay={false}
            isCopy={true}
          />
        </div>
      </div>

      <div className={'flex-row gap-16'} style={{height: '40px'}}>
        <OrderButtons
          rightOnClick={rightButtonOnClick}
          leftOnClick={leftButtonOnclick}
          leftDisabled={currentStepStatusButtons.leftButton.isDisabled}
          rightDisabled={currentStepStatusButtons.rightButton.isDisabled}
          currentStepStatusButtons={currentStepStatusButtons}
          isSeller={isSeller}
          updateP2POrderStatus={updateP2POrderStatus}
          currentOrder={currentOrder}
          isMyAppeal={isMyAppeal}
        />
      </div>
    </>
  );
};

export default OrderDeals;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgba(65, 61, 80, 0.5);
  padding-bottom: 16px;
`
const Text = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #C3C3CE;
`
const CardMini = styled.div`
  background: #2B2B36;
  border-radius: 6px;
  padding: 16px 12px;
  width: 100%;
`
const GreyText = styled(Typography)`
  color: #767687;
  font-weight: 500;
`
const SellerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 8px;
`;

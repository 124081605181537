import create from 'zustand';
import {devtools} from 'zustand/middleware';
import {produce} from 'immer';
import {jwtDecode} from 'jwt-decode';
import {ACCOUNT_URL, ACCOUNT_URL_REDIRECT, API_URL, EDENEX_ACCOUNT_URL,} from './packages/keycloak-client/constants';
import {axios} from './shared/exios';
import {deleteCookieByName, getCookieByName, setCookieByName} from './shared/helpers/controlCookies';
import {AxiosResponse} from 'axios';


type TUserKC = {
    access_token: string | undefined;
    refresh_token: string | undefined;
    auth_time: number;
    profile: {
        email: string;
        email_verified: boolean;
        iat: number;
        sub: string;
    };
};

type TUserAPI = {
    email: string;
    status: string;
    userId: number;
    uid: string;
    total: number;
    totalFiat: number;
};

type TGetUserKC = {
    session: any;
    setCookie: any;
};

export type TDecodedToken = {
    acr: string;
    aud: string;
    azo: string;
    email: string;
    email_verified: boolean;
    exp: number;
    iat: number;
    iss: string;
    jti: string;
    preferred_username: string;
    scope: string;
    session_state: string;
    sid: string;
    sub: string;
    typ: string;
};

type TClientCardState = {
    token: string;
    isAuth: boolean;
    setIsAuth: (value: boolean) => void;
    isLoading: boolean;
    serverTime: number;
    userKC: TUserKC;
    userAPI: TUserAPI;
    balance: {
        BTC: {
            amount: number | string;
        };
        ETH: {
            amount: number | string;
        };
    };
    total: any;
    totalFiat: any;
    logout: () => void;
    getUserKC: ({session, setCookie}: TGetUserKC) => TUserKC | any;
    refreshToken: (config: AxiosResponse) => Promise<AxiosResponse | undefined>;
    getServerTime: (accessToken: string) => Promise<any>;
    setServerTime: (value: number) => void;
    login: () => void;
    getUserProfileFromAPI: () => Promise<void>;
    clearLocalStorage: () => void;
    register: () => void;
};

export const useAuthState = create<TClientCardState>()(
    devtools(
        (set, get) => ({
            token: '',
            isAuth: false,
            isLoading: false,
            serverTime: 0,
            balance: {
                BTC: {
                    amount: 0,
                },
                ETH: {
                    amount: 0,
                },
            },
            total: '',
            totalFiat: '',
            userKC: {
                access_token: undefined,
                refresh_token: undefined,
                auth_time: 0,
                profile: {
                    email: '',
                    email_verified: false,
                    iat: 0,
                    sub: '',
                },
            },
            userAPI: {
                email: '',
                status: 'active',
                userId: 0,
                uid: '',
                total: 0,
                totalFiat: 0,
            },
            setIsAuth: (value: boolean) => {
                set(
                    produce((draft) => {
                        draft.isAuth = value;
                    }),
                    false,
                    {
                        type: 'useAuthState => setIsAuth',
                    }
                );
            },
            clearLocalStorage: async () => {
                if (!!localStorage.getItem('oidc.user')) {
                    localStorage.removeItem('oidc.user');
                }

                if (!!localStorage.getItem('refresh_token')) {
                    localStorage.removeItem('refresh_token');
                }

                if (!!localStorage.getItem('loginEmail')) {
                    localStorage.removeItem('loginEmail');
                }

                if (!!localStorage.getItem('token')) {
                    localStorage.removeItem('token');
                }

                if (!!localStorage.getItem('email')) {
                    localStorage.removeItem('email');
                }

                if (!!localStorage.getItem('uid')) {
                    localStorage.removeItem('uid');
                }

                if (!!localStorage.getItem('email_verified')) {
                    localStorage.removeItem('email_verified');
                }
            },
            register: () => {
                const currentUrl = window.location.href;
                const redirectUrl = encodeURIComponent(currentUrl);
                set(
                    produce((draft) => {
                        draft.clearLocalStorage().then(() => {
                            window.location.href = `${ACCOUNT_URL_REDIRECT}/register?redirect_url=${redirectUrl}`;
                        });
                    })
                );
            },
            login: () => {
                const currentUrl = window.location.href;
                const redirectUrl = encodeURIComponent(currentUrl);
                set(
                    produce((draft) => {
                        draft.clearLocalStorage().then(() => {
                            window.location.href = `${ACCOUNT_URL_REDIRECT}/login-page?redirect_url=${redirectUrl}`;
                        });
                    })
                );
            },
            refreshToken: async (config: AxiosResponse) => {
                try {
                    if (!getCookieByName('refresh_token')) return;

                    const res = await axios.post(
                        `${ACCOUNT_URL}/server/edenex-account/api/refresh-token`,
                        {
                            // @ts-ignore
                            refresh_token: getCookieByName('refresh_token')?.replace('Bearer ', '')
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: ''
                            }
                        }
                    );

                    const {access_token, refresh_token} = res!.data?.data;
                    const decodedToken: TDecodedToken = jwtDecode(access_token);

                    if (config && !!refresh_token?.length && !!access_token?.length) {
                        setCookieByName(
                            'oidc.user',
                            JSON.stringify({
                                // @ts-ignore
                                ...getCookieByName('oidc.user'),
                                access_token: access_token ? `Bearer ${access_token}` : '',
                                refresh_token
                            })
                        );

                        setCookieByName('email', decodedToken?.email);
                        setCookieByName('uid', decodedToken?.sub);
                        setCookieByName('token', access_token);
                        setCookieByName('refresh_token', refresh_token);

                        return await axios(config);
                    }
                } catch (error) {
                    console.error(error, 'error');
                    useAuthState.getState().logout();
                }
            },
            getServerTime: async (accessToken: string) => {
                try {
                    const res = await axios.get(
                        `${EDENEX_ACCOUNT_URL}/edenex-account/api/server-time`,
                        {
                            headers: {
                                Authorization: `${accessToken}`,
                            },
                        }
                    );
                    const {
                        data: {server_time},
                    } = res.data;
                    set(
                        produce((draft) => {
                            draft.setServerTime(server_time);
                        })
                    );
                } catch (e) {
                    console.error('getServerTime error', e);
                }
            },
            setServerTime: (value: number) => {
                set(
                    produce((draft) => {
                        draft.serverTime = value;
                    })
                );
            },
            getUserKC: ({session, setCookie}: TGetUserKC) => {

                const userKC = !!session ? session : null;

                if (!userKC) {
                    return null;
                }

                const decodedToken: TDecodedToken = jwtDecode(userKC?.access_token);
                const AccessToken = userKC?.access_token ? `Bearer ${userKC?.access_token.replace('Bearer ', '')}` : '';
                const RefreshToken = userKC?.refresh_token;

                localStorage.setItem('email', decodedToken?.email);

                setCookie('email-header', decodedToken?.email);
                setCookie('email', decodedToken?.email);
                setCookie('uid', decodedToken?.sub);
                setCookie('token', AccessToken);
                setCookie('refresh_token', RefreshToken);

                get().getUserProfileFromAPI();
                return userKC;
            },

            logout: () => {
                localStorage.clear();
                deleteCookieByName('token');
                deleteCookieByName('email-header');
                deleteCookieByName('refresh_token');
                deleteCookieByName('uid');
                deleteCookieByName('email');
                deleteCookieByName('oidc.user');
                deleteCookieByName('remainingTime');
                deleteCookieByName('refreshTokenTimestamp');
                deleteCookieByName('currentServerTime');
                deleteCookieByName('inviteToken');
                deleteCookieByName('email_verified');
                deleteCookieByName('inviteLink');
                deleteCookieByName('fromEdenex');
                deleteCookieByName('fromFinms');
                deleteCookieByName('fromPortal');
                deleteCookieByName('currentPointId');

                window.location.href = `${window.location.protocol}//${window.location.host}/spot`
            },
            getUserProfileFromAPI: async () => {
                const response = await axios.get(`${API_URL}/account/api/me`);
                if (!!response) {
                    set(
                        produce((draft) => {
                            draft.userAPI = response?.data?.account;
                            draft.balance = response?.data?.balance;
                            draft.total = response?.data?.total;
                            draft.totalFiat = response?.data?.total_fiat;
                        }),
                        false,
                        {
                            type: 'useAuthStore => getUserProfileFromAPI',
                        }
                    );
                }
            },
        }),
        {
            anonymousActionType: 'useAuthState action',
            name: 'useClientStateV2',
        }
    )
);

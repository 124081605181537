import { axios } from '../../shared/exios';
import { IBaseHandlerParam } from '../../shared/interfaces';

interface IOutput<T> extends IBaseHandlerParam<T> {
  coin: string;
}

export const coinHandlers = {
  output: <T>({ onSuccess, onError, data, coin }: IOutput<T>) => {
    //TODO в будущем вынести в хук когда, будет улучшен refine и будут все провайдеры по коинам
    if (!!coin?.length) {
      axios
        .post(`/${coin.toLowerCase()}-coin/api/output`, data)
        .then(() => {
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch(() => {
          if (onError) {
            onError();
          }
        });
    }
  },
};

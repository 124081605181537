import React, {lazy, useEffect, useLayoutEffect, useState} from 'react';
import {ErrorComponent} from '@pankod/refine-antd';
import '@pankod/refine-antd/dist/reset.css';
import {Authenticated, I18nProvider, IRouterProvider, Refine, ResourceProps,} from '@pankod/refine-core';
import routerProvider, {Navigate, RefineRouteProps,} from '@pankod/refine-react-router-v6';
import {Login} from '../pages/login';
import Ui from '../pages/dev-pages/ui';
import {useTranslation} from 'react-i18next';
import {CoinAccountsList} from '../pages/coin-account/list';
import {HistoryShow} from '../pages/coin-account/show';
import {Header} from '../shared/components/layout/header';
import {Layout} from '../shared/components/layout';
import ExchangeOrder from '../pages/exchangeOrder';
import CryptoCurrencyExchange from '../pages/cryptoCurrencyExchange/cryptoCurrencyExchange';
import OutputOrder from '../pages/output/outputOrder';
import { Index as CreateP2PAd } from '../pages/p2p/createAd';
import EditP2PAd from '../pages/p2p/editAd';
import MyOrders from '../pages/myOrders';
import DashBoard from '../pages/p2p/dashboard';
import Settings from '../pages/p2p/settings';
import MyAnnouncements from '../pages/p2p/myAnnouncements';
import MyDeals from '../pages/p2p/myDeals';
import BuyDeal from '../pages/p2p/buyDeal';
import Payment from '../pages/p2p/settings/payment/payment';
import {P2PChatPage} from 'pages/dev-pages/p2p-chat';
import {AppealModalPage} from 'pages/p2p/appeal';
import {AnnouncementPage} from 'pages/p2p/myAnnouncements/announcement';
import Course from '../pages/payment/course';
import PaymentLogout from '../pages/paymentLogout';
import { SpotDashboard } from '../pages/spot/spotDashboard/spotDashboard';
import { MySpotDeals } from '../pages/spot/my-deals';
import { OrderBookExpanded } from 'pages/spot/orderBookExpanded/orderBookExpanded';
import { Grid } from 'antd';
import DashboardMobile from '../pages/p2p/dashboard/mobile';
import useDataProvider from './useDataProvider';
import Blocked from '../pages/blocked/Blocked';
import CookiePolicy from '../pages/cookiePolicy/CookiePolicy';
import MobileSpotChart from '../pages/mobileSpotChart/MobileSpotChart';
import ProtectedRoute from '../context/ProtectedRoute';
import {axios} from "../shared/exios";
import {COOKIE_CONFIG_DEV, COOKIE_CONFIG_PROD} from "../shared/constants";
import { useAuthState } from 'useAuthState';
import { useCookiesCustom } from '../shared/hooks/useCookieCustom';
import { API_URL } from '../packages/keycloak-client/constants';
// import PrivacyPolicy from '../pages/privacyPolicy/PrivacyPolicy'; // TODO: Починить страницу политика приватности

const Title = lazy(() =>
  import('../shared/components/layout/title').then((module) => ({
    default: module.Title,
  }))
);

function App() {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { t, i18n } = useTranslation();
  const loader = document.querySelector('.ant-spin');
  const [resetTimestamp, setResetTimestamp] = useState<number | null>(null);
  const {cookie, setCookie, removeCookie} = useCookiesCustom();
  const getUserKC = useAuthState((state) => state.getUserKC);
  const isAuth = useAuthState((state) => state.isAuth);
  const serverTime = useAuthState((state) => state.serverTime);
  const currentServerTimeFromLocalstorage = localStorage.getItem(
    'currentServerTime'
  )
    ? Number(cookie?.currentServerTime)
    : 0;
  const [currentServerTime, setCurrentServerTime] = useState<number>(
    currentServerTimeFromLocalstorage || serverTime
  );

  const [coinsList, setCoinsList] = useState<any>([
    {
      currency: '',
      is_available_input: false,
    },
  ]);

  const dataProvider = useDataProvider({
    controlsCoinsList: [coinsList, setCoinsList],
  });

  const i18nProvider: I18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };
  const [resources] = useState<ResourceProps[]>([
    {
      name: 'main',
      list: SpotDashboard,
      show: SpotDashboard,
      options: {
        dataProviderName: 'main',
      },
    },
  ]);

  if (loader) {
    loader.remove();
  }

  const routes: RefineRouteProps[] = [
    {
      element: (
        <ProtectedRoute>
          <CoinAccountsList />
        </ProtectedRoute>
      ),
      path: '/coin-accounts',
      layout: true,
    },
    {
      element: (
        <ProtectedRoute>
          <HistoryShow />
        </ProtectedRoute>
      ),
      path: '/coin-accounts/:coin',
      layout: true,
    },
    {
      element: (
        <ProtectedRoute>
          <Layout>
            <OutputOrder />
          </Layout>
        </ProtectedRoute>
      ),
      path: '/output/confirm/:token',
    },
    {
      path: '/cryptocurrency-exchange',
      element: (
        <Layout>
          <CryptoCurrencyExchange />
        </Layout>
      ),
    },
    {
      path: '/',
      element: (
        <Layout>
          <SpotDashboard />
        </Layout>
      ),
    },
    {
      path: '/:pair',
      element: (
        <Layout>
          <SpotDashboard />
        </Layout>
      ),
    },
    {
      path: '/exchange-outputOrder/:id',
      element: (
        <Layout>
          <ExchangeOrder />
        </Layout>
      ),
    },
    {
      path: '/p2p/create-offer',
      element: (
        <ProtectedRoute>
          <CreateP2PAd />
        </ProtectedRoute>
      ),
      layout: true,
    },
    {
      path: '/p2p/edit-offer/:type/:id/:currencyPair',
      element: (
        <Authenticated fallback={<Navigate to="/login"/>}>
          <EditP2PAd/>
        </Authenticated>
      ),
      layout: true,
    },
    {
      path: '/payment/btc/:id',
      element: <Course props={''}/>,
      layout: true,
    },
    {
      path: '/payment-logout',
      element: <PaymentLogout/>,
      layout: true,
    },
    {
      path: '/p2p/settings',
      element: (
        <ProtectedRoute>
          <Settings />
        </ProtectedRoute>
      ),
      layout: true,
    },
    {
      path: '/p2p/settings/payment',
      element: (
        <ProtectedRoute>
          <Payment />
        </ProtectedRoute>
      ),
      layout: true,
    },
    {
      path: '/p2p/my-announcements',
      element: (
        <ProtectedRoute>
          <MyAnnouncements />
        </ProtectedRoute>
      ),
      layout: true,
    },
    {
      path: '/p2p/my-announcements/:id',
      element: (
        <ProtectedRoute>
          <AnnouncementPage />
        </ProtectedRoute>
      ),
      layout: true,
    },
    {
      path: '/p2p/my-announcements-deals/:id',
      element: (
        <Authenticated fallback={<Navigate to="/login"/>}>
          <BuyDeal/>
        </Authenticated>
      ),
      layout: true,
    },
    {
      path: '/p2p/my-deals',
      element: (
        <ProtectedRoute>
          <MyDeals />
        </ProtectedRoute>
      ),
      layout: true,
    },
    {
      path: '/p2p/my-deals/:id',
      element: (
        <ProtectedRoute>
          <BuyDeal />
        </ProtectedRoute>
      ),
      layout: true,
    },
    {
      path: '/p2p/appeal',
      element: (
        <ProtectedRoute>
          <AppealModalPage />
        </ProtectedRoute>
      ),
      layout: true,
    },
    {
      path: '/my-orders',
      element: (
        <ProtectedRoute>
          <MyOrders />
        </ProtectedRoute>
      ),
      layout: true,
    },
    {
      path: '/p2p/dashboard',
      element: !screens.md ? (
        <Layout>
          <DashboardMobile/>
        </Layout>
      ) : (
        <Layout>
          <DashBoard/>
        </Layout>
      ),
    },
    {
      path: `/p2p/order/:id`,
      element: (
        <ProtectedRoute>
          <BuyDeal />
        </ProtectedRoute>
      ),
      layout: true,
    },
    {
      path: `/login/:pair`,
      element: (
        <Layout>
          <SpotDashboard/>
        </Layout>
      ),
      layout: true,
    },
    {
      path: `/spot/dashboard/order-book-expanded/:ccy-:fiat`,
      element: (
        <Layout>
          <OrderBookExpanded/>
        </Layout>
      ),
    },
    {
      path: '/spot/dashboard/:pair',
      element: (
        <Layout>
          <SpotDashboard/>
        </Layout>
      ),
    },
    {
      path: '/spot/dashboard/my-deals/:pair',
      element: (
        <Layout>
          <MySpotDeals/>
        </Layout>
      ),
    },
    {
      path: '/blocked',
      element: (
        <ProtectedRoute>
          <Blocked />
        </ProtectedRoute>
      ),
      layout: true,
    },
    {
      path: '/cookie-policy',
      element: (
        <Layout>
          <CookiePolicy/>
        </Layout>
      ),
    },
    {
      path: '/mobile-spot-chart',
      element: <MobileSpotChart/>,
    },
  ];

  if (process.env.NODE_ENV === 'development') {
    routes.push({
      element: <Ui/>,
      path: '/ui',
    });

    routes.push({
      path: '/p2p/chat',
      element: (
        <ProtectedRoute>
          <P2PChatPage />
        </ProtectedRoute>
      ),
    });
  }

  useEffect(() => {
    const savedTimestamp = cookie.refreshTokenTimestamp;

    if (parseInt(savedTimestamp) > 0) {
      setResetTimestamp(parseInt(savedTimestamp));
    }

    const handleBeforeUnload = () => {
      const ResetTimestamp = resetTimestamp ? resetTimestamp.toString() : '0';

      localStorage.setItem('currentServerTime', currentServerTime.toString());

      setCookie('refreshTokenTimestamp', ResetTimestamp);
      setCookie('currentServerTime', currentServerTime.toString());
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [
    resetTimestamp,
    cookie.refreshTokenTimestamp,
    serverTime,
    currentServerTime,
  ]);

  useEffect(() => {

    if (!!cookie['email']) {
      localStorage.setItem('email', cookie['email']);
    }

    if (isAuth) {
      getUserKC({ session: cookie['oidc.user'], setCookie });
    }
  }, [isAuth])

  if (loader) {
    loader.remove();
  }

  return (
    <Refine
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      LoginPage={Login}
      Layout={Layout}
      Header={Header}
      Title={Title}
      catchAll={<ErrorComponent/>}
      routerProvider={{...routerProvider, routes} as IRouterProvider}
      resources={resources}
      options={{
        syncWithLocation: true,
        warnWhenUnsavedChanges: false,
        disableTelemetry: true,
      }}
    />
  );
}

export default App;

import { useTable } from "@pankod/refine-antd";
import { HttpError, LogicalFilter } from "@pankod/refine-core";
type Input = {};
type Props = {
    coin?: string,
    initDateFilter: LogicalFilter,
    type?: string
}
export const useGetWalletTable = ({coin, initDateFilter, type} : Props) => {
    const { tableProps: tableRes, filters, setFilters } = useTable({
        resource: type,
        dataProviderName: `${coin}-coin`,
        permanentSorter: [{ field: 'id', order: 'desc' }],
        initialPageSize: 99,
        hasPagination: false,
        initialFilter: []
      });
    return { tableRes, filters, setFilters }
}

export const useGetWalletTables = ({coin, initDateFilter} : Props) => {
    const inputTableProps = useGetWalletTable({coin, initDateFilter, type: 'input'});
    const outputTableProps = useGetWalletTable({coin, initDateFilter, type: 'output'});
    return {inputTable: inputTableProps, outputTable: outputTableProps}
}
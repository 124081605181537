import React, { useState } from 'react';
import { useCreate, useRouterContext } from '@pankod/refine-core';
import { parseJwt } from '../../../shared/helpers/parseJWT';
import Output from '../../../features/output';
import { IKeyValue } from '../../../shared/interfaces';
import { useTranslation } from 'react-i18next';
import { TOutputConfirmInfo } from '../../../features/output/models';
import { ErrorBoundary } from '../../../app/ErrorBoundary';
import { useAuthState } from '../../../useAuthState';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { useCookiesCustom } from '../../../shared/hooks/useCookieCustom';

enum States {
  waitingAction,
  confirm,
  cancel,
}

const Index = () => {
  const navigate = useNavigate();
  const {removeCookie} = useCookiesCustom();
  const { useParams } = useRouterContext();
  const { t } = useTranslation();

  const params = useParams<{ token: string }>();
  const paramData = parseJwt<TOutputConfirmInfo>(params.token);

  const [currentState, setCurrentState] = useState(States.waitingAction);

  const { mutate } = useCreate();

  const onClickConfirm = async () => {
    mutate(
      {
        resource: 'output-confirm',
        dataProviderName: `${paramData?.ccy?.toLowerCase()}-coin`,
        values: {
          token: params.token,
        },
      },
      {
        onError: (error, variables, context) => {
          // An error occurred!
        },
        onSuccess: (data, variables, context) => {
          // Let's celebrate!
          setCurrentState(States.confirm);
        },
      }
    );
  };

  const onClickCancel = async () => {
    mutate(
      {
        resource: 'output-cancel',
        dataProviderName: `${paramData?.ccy?.toLowerCase()}-coin`,
        values: {
          token: params.token,
        },
      },
      {
        onError: (error, variables, context) => {
          // An error occurred!
        },
        onSuccess: (data, variables, context) => {
          // Let's celebrate!
          setCurrentState(States.cancel);
        },
      }
    );
  };

  const array: IKeyValue[] = [
    {
      key: t('outputOrder.cryptocurrency'),
      value: paramData?.ccy ?? '',
    },
    {
      key: t('outputOrder.outputAddress'),
      value: paramData?.address ?? '',
    },
    {
      key: t('outputOrder.outputAddress'),
      value: paramData?.address ?? '',
    },
    {
      key: t('outputOrder.youSend'),
      value: `${paramData?.amount} ${paramData?.ccy}`,
    },
    {
      key: t('outputOrder.commission'),
      value: `0 ${paramData?.ccy}`,
    },
    {
      key: t('outputOrder.withdrawalAmount'),
      value: `${paramData?.amount} ${paramData?.ccy}`,
    },
  ];

  return (
    <ErrorBoundary
      logout={() => {}}
      navigate={navigate}
      removeCookie={removeCookie}
    >
      {paramData && currentState === States.waitingAction && (
        <Output.OutputMainBloc
          outputValues={paramData}
          onClickCancel={onClickCancel}
          onClickConfirm={onClickConfirm}
        />
      )}
      {currentState === States.confirm && <Output.OnConfirm />}
      {currentState === States.cancel && <Output.OnCanceled />}
    </ErrorBoundary>
  );
};

export default Index;

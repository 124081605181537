import React, {Dispatch, useEffect, useMemo, useState} from 'react';
import '../Card/styles.css';
import {Select} from 'antd';
import Caret from '../Card/image/caret.svg';
import {Input} from '@pankod/refine-antd';
import {PayService} from '../../../../../pages/p2p/settings/payment/payment';
import Comment from '../Comment';
import {useTranslation} from 'react-i18next';
import {IPaymentsType} from '../../../../../pages/p2p/dashboard/interface';
import {PaymentTypeEnum} from '../../../../p2p/createOrder/types';

const {Option} = Select;

interface Props {
  controlsTemplatePay: [PayService, Dispatch<PayService>];
  clickSubmit: boolean;
  setIsValid: Dispatch<boolean>;
  errorHasPayment: boolean;
  iconsData?: IPaymentsType[];
}

type Payment = 'QIWI' | 'ЮMoney' | 'Payeer' | 'AdvCash';

const Service = ({
                   controlsTemplatePay: [templatePay, setTemplatePay],
                   clickSubmit,
                   setIsValid,
                   errorHasPayment,
                   iconsData = [],
                 }: Props) => {
  const {t} = useTranslation();

  const [openDrop, setOpenDrop] = useState(false);
  const [pickService, setPickService] = useState(null);
  const [error, setError] = useState('bank');
  const onChange = (value: string, key: keyof PayService) => {
    setTemplatePay({
      ...templatePay,
      [key]: value,
    });
  };

  const getValidSymbols = (service: Payment) => {
    switch (service) {
      case 'Payeer':
        return [9, 9];
      case 'QIWI':
        return [10, 20];
      case 'ЮMoney':
        return [15, 15];
      case 'AdvCash':
        return [13, 13];
      default:
        return [0, 0];
    }
  };

  useEffect(() => {
    if (!!(templatePay?.service !== 'AdvCash' && templatePay.num?.length)) {
      setError(
        ((templatePay?.num?.length < getValidSymbols(templatePay?.service as Payment)[0]) ||
          (templatePay?.num?.length > getValidSymbols(templatePay?.service as Payment)[1]) ||
          isNaN(parseInt(templatePay?.num, 10)))
          ? 'length'
          : ''
      );
    } else if (!!templatePay?.service && templatePay?.service === 'AdvCash') {
      setError(
        !!templatePay?.service &&
        templatePay?.service === 'AdvCash' &&
        !templatePay.email
          ? 'email'
          : !templatePay?.num ||
          (templatePay?.num &&
            templatePay?.service &&
            (templatePay?.num?.length <
              getValidSymbols(templatePay?.service as Payment)[0] ||
              templatePay?.num?.length >
              getValidSymbols(templatePay?.service as Payment)[1] ||
              isNaN(parseInt(templatePay?.num, 10))))
            ? 'length'
            : templatePay?.comment && templatePay?.comment?.length > 40
              ? 'comment'
              : ''
      );
    } else if (!!templatePay?.service && templatePay?.service !== 'AdvCash') {
      setError(
        !templatePay?.num ||
        (templatePay?.num &&
          templatePay?.service &&
          (templatePay?.num?.length <
            getValidSymbols(templatePay?.service as Payment)[0] ||
            templatePay?.num?.length >
            getValidSymbols(templatePay?.service as Payment)[1] ||
            isNaN(parseInt(templatePay?.num, 10))))
          ? 'length'
          : templatePay?.comment && templatePay?.comment?.length > 40
            ? 'comment'
            : ''
      );
    } else {
      setError(
        !Array.isArray(templatePay?.service)
          ? 'service'
          : ''
      );
    }
  }, [templatePay, clickSubmit]);

  useEffect(() => {
    setIsValid(error === '');
  }, [error]);

  const srcIcon = useMemo(
    () => (type: PaymentTypeEnum, name: string) =>
      iconsData
        .find((item) => item.payment_type === type)
        ?.items?.find((payment) => payment.name === name)?.icon,
    [iconsData]
  );

  const options = [
    {
      value: 'ЮMoney',
      label: (
        <div className={'bank-selector-item'}>
          <img
            style={{width: 16, height: 16}}
            src={`data:image/svg+xml;base64,${srcIcon(
              PaymentTypeEnum.webServicePayment,
              'YooMoney'
            )}`}
          />
          ЮMoney
        </div>
      ),
    },
    {
      value: 'QIWI',
      label: (
        <div className={'bank-selector-item'}>
          <img
            style={{width: 16, height: 16}}
            src={`data:image/svg+xml;base64,${srcIcon(
              PaymentTypeEnum.webServicePayment,
              'QIWI'
            )}`}
          />
          QIWI
        </div>
      ),
    },
    {
      value: 'Payeer',
      label: (
        <div className={'bank-selector-item'}>
          <img
            style={{width: 16, height: 16}}
            src={`data:image/svg+xml;base64,${srcIcon(
              PaymentTypeEnum.webServicePayment,
              'Payeer'
            )}`}
          />
          Payeer
        </div>
      ),
    },
    {
      value: 'AdvCash',
      label: (
        <div className={'bank-selector-item'}>
          <img
            style={{width: 16, height: 16}}
            src={`data:image/svg+xml;base64,${srcIcon(
              PaymentTypeEnum.webServicePayment,
              'AdvCash'
            )}`}
          />
          AdvCash
        </div>
      ),
    },
  ];

  return (
    <div className={'bank-container'}>
      <span className={'bank-title'}>{t('payments.electronServices')}</span>
      {errorHasPayment && (
        <span style={{marginTop: 4}} className={'bank-input-label-error'}>
          {t('payments.errorAddPayment')}
        </span>
      )}
      <div style={{marginBottom: 200}} className={'bank-form'}>
        <div className={'bank-container-input'}>
          <span className={'bank-input-label'}>
            {t('payments.chooseElectronServices')}
          </span>
          <Select
            status={error === 'service' && clickSubmit ? 'error' : undefined}
            onSelect={(value) => {
              setPickService(value);
              onChange(value, 'service');
            }}
            onDropdownVisibleChange={() => setOpenDrop(!openDrop)}
            placeholder={t('payments.electronService')}
            suffixIcon={
              <img
                style={{
                  transform: `rotate(${openDrop ? 0 : 180}deg)`,
                  transition: '0.2s',
                }}
                alt={'caret'}
                src={Caret}
              />
            }
            dropdownStyle={{
              background: '#474755',
              border: '1px solid #474755',
              borderRadius: 8,
            }}
          >
            {options.map((item, index) => (
              <Option key={index} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
          {error === 'service' && clickSubmit && (
            <span className={'bank-input-label-error'}>
              {t('payments.chooseElectronServices')}
            </span>
          )}
        </div>
        {!!pickService &&
          (pickService === 'AdvCash' ? (
            <>
              <div className={'bank-container-input'}>
                <span className={'bank-input-label'}>
                  {t('titles.enterEmail')}
                </span>
                <Input
                  value={templatePay.email}
                  status={
                    error === 'email' && clickSubmit ? 'error' : undefined
                  }
                  onChange={(e) => onChange(e.target.value, 'email')}
                  placeholder={'Email'}
                />
                {error === 'email' && clickSubmit && (
                  <span className={'bank-input-label-error'}>
                    {t('titles.enterEmail')}
                  </span>
                )}
              </div>

              <div className={'bank-container-input'}>
                <span className={'bank-input-label'}>
                  {t('payments.enterWalletNumber')}
                </span>
                <Input
                  value={templatePay.num}
                  status={
                    ['num', 'length'].indexOf(error) !== -1 && clickSubmit
                      ? 'error'
                      : undefined
                  }
                  onChange={(e) => onChange(e.target.value, 'num')}
                  placeholder={t('payments.walletNumber').toString()}
                  maxLength={getValidSymbols(templatePay.service as Payment)[1]}
                />
                {error === 'num' && clickSubmit && (
                  <span className={'bank-input-label-error'}>
                    {t('payments.enterWalletNumber')}
                  </span>
                )}
                {error === 'length' && clickSubmit && (
                  <span className={'bank-input-label-error'}>
                    {t('payments.incorrectNumber')}
                  </span>
                )}
              </div>

              <Comment
                templatePay={templatePay}
                error={error}
                clickSubmit={clickSubmit}
                onChange={onChange}
              />
            </>
          ) : (
            <>
              <div className={'bank-container-input'}>
                <span className={'bank-input-label'}>
                  {t('payments.enterAccount')} {pickService}
                </span>
                <Input
                  placeholder={`${t('payments.account')} ${pickService}`}
                  value={templatePay.num}
                  status={
                    ['num', 'length'].indexOf(error) !== -1 && clickSubmit
                      ? 'error'
                      : undefined
                  }
                  onChange={(e) => onChange(e.target.value, 'num')}
                  maxLength={getValidSymbols(templatePay.service as Payment)[1]}
                />
                {error === 'num' && clickSubmit && (
                  <span className={'bank-input-label-error'}>
                    {t('payments.enterWalletNumber')}
                  </span>
                )}
                {error === 'length' && clickSubmit && (
                  <span className={'bank-input-label-error'}>
                    {t('payments.enterAccount')} {pickService}
                  </span>
                )}
              </div>
              <Comment
                templatePay={templatePay}
                error={error}
                clickSubmit={clickSubmit}
                onChange={onChange}
              />
            </>
          ))}
      </div>
    </div>
  );
};

export default Service;

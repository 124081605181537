import { IBaseHandlerParam } from '../../shared/interfaces';
import { axios } from '../../shared/exios';

interface IAccountGet<T>
  extends Omit<IBaseHandlerParam<T>, 'data' | 'onSuccess'> {
  onSuccess: (data: { data: T }) => void;
}

export const accountHandlers = {
  getAccount: <T>({ onSuccess, onError }: IAccountGet<T>) => {
    axios
      .get('/account/api/account')
      .then(({ data }) => {
        if (onSuccess) {
          onSuccess(data);
        }
      })
      .catch(() => {
        if (onError) {
          onError();
        }
      });
  },
};

import { FC } from 'react';
import styled from 'styled-components';
import IOButton from '../Button/Button';

const IOButtons: FC = () => {
  return (
    <WalletIOButtons>
      <WalletButton>
        <IOButton type="deposit" />
      </WalletButton>
      <WalletButton>
        <IOButton type="withdraw" />
      </WalletButton>
    </WalletIOButtons>
  );
};

export default IOButtons;

const WalletIOButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const WalletButton = styled.div`
  width: 180px;
  height: 40px;
`;

import { AntdBreadcrumb, Select, Typography } from '@pankod/refine-antd';
import { OrderBookTables } from './orderBookTable';
import {
  useLocation,
  useNavigate,
  useParams,
} from '@pankod/refine-react-router-v6';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '../../../app/ErrorBoundary';
import { getOrderBookBuySpot, getOrderBookSellSpot } from '../spotResponses';
import { useAuthState } from '../../../useAuthState';
import styled from 'styled-components';
import { useCookiesCustom } from '../../../shared/hooks/useCookieCustom';

// TODO: Привести в нормальный вид, добавить пагинацию

const BreadcrumbText = styled(Typography.Text)`
  cursor: pointer;
  font-size: 16px;
`;

export const OrderBookExpanded = () => {
  const {removeCookie} = useCookiesCustom();
  const { t } = useTranslation();

  const [orderBookSellPage] = useState(1);
  const [orderBookBuyPage] = useState(1);

  const [finalSellData, setFinalSellData] = useState([]);
  const [finalBuyData, setFinalBuyData] = useState([]);
  const [roundFilter, setRoundFilter] = useState(2);
  const [stringFilter, setStringFilter] = useState(15);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    Promise.all([
      getOrderBookBuySpot({
        pairToLowerCase: localStorage['activePair']
          ?.toLowerCase()
          .replace('-', ''),
        setFunc: setFinalBuyData,
        limit: stringFilter,
        page: orderBookBuyPage,
      }),
      getOrderBookSellSpot({
        pairToLowerCase: localStorage['activePair']
          ?.toLowerCase()
          .replace('-', ''),
        setFunc: setFinalSellData,
        limit: stringFilter,
        page: orderBookSellPage,
      }),
    ]).then();
  }, [stringFilter, location.pathname]);

  useEffect(() => {
    Promise.all([
      getOrderBookBuySpot({
        pairToLowerCase: localStorage['activePair']
          ?.toLowerCase()
          .replace('-', ''),
        setFunc: setFinalBuyData,
        limit: stringFilter,
        page: orderBookBuyPage,
        direction: 'desc',
      }),
      getOrderBookSellSpot({
        pairToLowerCase: localStorage['activePair']
          ?.toLowerCase()
          .replace('-', ''),
        setFunc: setFinalSellData,
        limit: stringFilter,
        page: orderBookSellPage,
        direction: 'desc',
      }),
    ]).then();
  }, []);

  return (
      <ErrorBoundary
          logout={() => {}}
          navigate={navigate}
          removeCookie={removeCookie}
      >
    <div>
      <div className="flex-row items-center" style={{ gap: '23px' }}>
        <AntdBreadcrumb>
          <BreadcrumbText
            onClick={() =>
              navigate(`/spot/dashboard/${params?.ccy}-${params?.fiat}`)
            }
            className="text-gray"
          >
            {t('orderBookExpanded.spotTrading')}
          </BreadcrumbText>
        </AntdBreadcrumb>
        <ChevronArrow />
        <AntdBreadcrumb>
          <Typography.Text style={{ fontSize: '16px' }}>
            {t('orderBookExpanded.orderBook')} {params?.ccy}/{params?.fiat}
          </Typography.Text>
        </AntdBreadcrumb>
      </div>
      <div
        className="flex-row"
        style={{
          justifyContent: 'space-between',
          fontSize: '16px',
          marginTop: '23px',
        }}
      >
        <Typography.Title>
          {t('orderBookExpanded.orderBook')} {params?.ccy}/{params?.fiat}
        </Typography.Title>
        <div className="flex-row gap-16">
          <Select
            style={{ width: '235px', height: '40px', borderRadius: '8px' }}
            className={'select-style custom-select-order-book-filter'}
            defaultValue={15}
            onChange={(e) => setStringFilter(e)}
          >
            <Select.Option value={15}>
              <Typography.Text>
                15 {t('orderBookExpanded.lines')}
              </Typography.Text>
            </Select.Option>
            <Select.Option value={30}>
              <Typography.Text>
                30 {t('orderBookExpanded.lines')}
              </Typography.Text>
            </Select.Option>
            <Select.Option value={45}>
              <Typography.Text>
                45 {t('orderBookExpanded.lines')}
              </Typography.Text>
            </Select.Option>
          </Select>
          <div className="custom-book-select-container">
            <Select
              style={{ width: '235px', height: '40px', position: 'relative' }}
              className={'select-style custom-select-order-book-filter'}
              defaultValue={2}
              onChange={(e) => setRoundFilter(e)}
            >
              <Select.Option value={2}>
                <Typography.Text>
                  2 {t('orderBookExpanded.digits1')}
                </Typography.Text>
              </Select.Option>
              <Select.Option value={1}>
                <Typography.Text>
                  1 {t('orderBookExpanded.digit')}
                </Typography.Text>
              </Select.Option>
              <Select.Option value={0}>
                <Typography.Text>
                  0 {t('orderBookExpanded.digits2')}
                </Typography.Text>
              </Select.Option>
            </Select>
            <div className="custom-select-placeholder">
              <Typography.Text>
                {t('orderBookExpanded.roundingUpTo')} {roundFilter}{' '}
                {roundFilter === 1
                  ? t('orderBookExpanded.digits1')
                  : t('orderBookExpanded.digits2')}
              </Typography.Text>
            </div>
          </div>
        </div>
      </div>
      <OrderBookTables
        buyData={finalBuyData.reverse()}
        sellData={finalSellData.reverse()}
        stringFilter={stringFilter}
        roundFilter={roundFilter}
      />
    </div>
      </ErrorBoundary>
  );
};

const ChevronArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.80806 2.05806C6.05214 1.81398 6.44786 1.81398 6.69194 2.05806L14.1919 9.55806C14.436 9.80214 14.436 10.1979 14.1919 10.4419L6.69194 17.9419C6.44786 18.186 6.05214 18.186 5.80806 17.9419C5.56398 17.6979 5.56398 17.3021 5.80806 17.0581L12.8661 10L5.80806 2.94194C5.56398 2.69786 5.56398 2.30214 5.80806 2.05806Z"
      fill="#767687"
    />
  </svg>
);

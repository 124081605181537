import React, { lazy, useEffect, useState } from 'react';
import '../../widgets/exchange/index.css';
import { HttpError, useCustom, useList } from '@pankod/refine-core';
import Modal from '../../shared/components/ui/modal';
import {
  EXCHANGE_FORM_FIELDS_NAMES,
  FieldData,
  ICryptoPair,
  ICurrencyOption,
  IExchangeContent,
} from '../../features/cryptocurrencyExchange/cryptoCurrencyExchange.p';
import { getUniqOptionList } from '../../shared/helpers/getUniqOptionList';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { ICryptoCurrencyConstraintItem } from '../../widgets/order/types';
import { useTranslation } from 'react-i18next';
import { Grid, notification } from 'antd';
import { createFilteredAvailablePairs } from '../../shared/helpers/array/createFilteredAvailablePairs';
import { API_URL } from '../../packages/keycloak-client/constants';
import { useAuthState } from '../../useAuthState';
import { ErrorBoundary } from '../../app/ErrorBoundary';
import {axios} from "../../shared/exios";
import { useCookiesCustom } from '../../shared/hooks/useCookieCustom';

const ExchangeHeading = lazy(() =>
  import('../../widgets/exchange/exchangeHeading').then((module) => ({
    default: module.default,
  }))
);

const ExchangeContent = lazy(() =>
  import('../../widgets/exchange/exchangeContent').then((module) => ({
    default: module.default,
  }))
);

const CryptoCurrencyExchangeMobile = lazy(() =>
  import('./mobile').then((module) => ({
    default: module.default,
  }))
);

const { useBreakpoint } = Grid;

const DEFAULT_VALUES: FieldData[] = [
  {
    name: EXCHANGE_FORM_FIELDS_NAMES.email,
    value: '',
  },
  {
    name: EXCHANGE_FORM_FIELDS_NAMES.walletAddress,
    value: '',
  },
];

const CryptoCurrencyExchange: React.FC = () => {
  const { t } = useTranslation();
  const {cookie, removeCookie} = useCookiesCustom();
  const userAPI = useAuthState((state) => state.userAPI);

  const [commission, setCommission] = useState<string>('0');
  const [showInputModal, setShowInputModal] = useState(false);
  const [showOutputModal, setShowOutputModal] = useState(false);
  const screens = useBreakpoint();
  const navigate = useNavigate();

  const [pairData, setPairData] = useState<ICryptoPair[]>([]);
  const listPairs = pairData.map((item) => item.pair);
  const pairsWithSelectedCoin = (coin: string) => {
    return listPairs.filter((item) => item.includes(coin));
  };
  const getAvailableCoins = (coin: string) => {
    const availableCoins: string[] = [];
    pairsWithSelectedCoin(coin).forEach((item) => {
      let i = item.indexOf('/');
      if (item.slice(0, i) === coin) {
        availableCoins.push(item.slice(i + 1, item.length));
      }
    });
    return availableCoins;
  };
  const [currentPair, setCurrentPair] = useState<ICryptoPair>(pairData[0]);
  const [cryptoCurrencies, setCryptoCurrencies] = useState<ICurrencyOption[]>(
    []
  );

  const [targetCurrencies, setTargetCurrencies] = useState<ICurrencyOption[]>(
    []
  );

  const [exchangeData, setExchangeData] = useState<IExchangeContent>({
    inputCoin: '',
    outputCoin: '',
    inputAmount: '',
    outputAmount: '',
    output_network: null,
    input_network: null,
  });
  const availableInputs = getAvailableCoins(exchangeData.outputCoin);
  const availableOutputs = getAvailableCoins(exchangeData.inputCoin);
  const [transferData, setTransferData] = useState<FieldData[]>(DEFAULT_VALUES);

  const [minimumExchangeAmount, setMinimumExchangeAmount] = useState(0);

  const { data: currencyConstraintDataList } = useCustom<
    { data: ICryptoCurrencyConstraintItem[] },
    HttpError
  >({
    url: `${API_URL}/config/api/currency-constraint/search`,
    method: 'post',
    config: {
      payload: {
        page: 1,
        limit: 100,
        includes: [
          {
            relation: 'networks',
          },
        ],
        filters: [
          {
            field: 'is_available_offchain_exchange',
            operator: '=',
            value: true,
          },
        ],
      },
    },
  });

  useCustom<{ data: ICryptoPair[] }, HttpError>({
    url: `${API_URL}/config/api/info/offchain-pair-price`,
    method: 'get',
    queryOptions: {
      enabled: !!currencyConstraintDataList,
      onSuccess: ({ data: requestData }) => {
        if (!currencyConstraintDataList?.data.data) return;
        const data = requestData.data;
        let firstFilteredPairs = createFilteredAvailablePairs({
          inputData: currencyConstraintDataList.data.data,
          pairsData: data,
        });
        setPairData(firstFilteredPairs);
        setCurrentPair(firstFilteredPairs[0]);
        const currency = currencyConstraintDataList?.data.data.find(
          (item) => item.currency === firstFilteredPairs[0].pair?.split('/')[0]
        );

        if (firstFilteredPairs.length !== 0) {
          const filteredPairs = firstFilteredPairs.filter((item) =>
            item.pair.includes(firstFilteredPairs[0]?.pair?.split('/')[0])
          );

          setTargetCurrencies(getUniqOptionList(filteredPairs));
          setCryptoCurrencies(getUniqOptionList(firstFilteredPairs));
          if (exchangeData.inputCoin === '') {
            setExchangeData({
              inputAmount: currency ? currency?.minimal_trade_amount : '0',
              inputCoin: firstFilteredPairs[0]?.pair?.split('/')[0],
              outputCoin: firstFilteredPairs[0]?.pair?.split('/')[1],
              outputAmount: currency
                ? (
                    +firstFilteredPairs[0]?.price *
                    +currency?.minimal_trade_amount
                  ).toString()
                : '0',
              output_network:
                getNetworks(
                  currencyConstraintDataList.data.data,
                  firstFilteredPairs[0]?.pair?.split('/')[1]
                )[0]?.network || null,
              input_network:
                getNetworks(
                  currencyConstraintDataList.data.data,
                  firstFilteredPairs[0]?.pair?.split('/')[0]
                )[0]?.network || null,
            });
          }
        } else {
          setCryptoCurrencies([]);
        }
        setMinimumExchangeAmount(
          currency ? +currency?.minimal_trade_amount : 0
        );
      },
    },
  });

  const getNetworks = (
    currencies: ICryptoCurrencyConstraintItem[],
    currency: string
  ) => {
    return (
      currencies.find((item) => item.currency === currency)?.networks || []
    );
  };
  // const { mutateAsync, isLoading } = useCreate();

  useEffect(() => {
    let isPairExist = true;
    let curPair = pairData.find(
      (item) =>
        item.pair ===
        `${exchangeData.inputCoin.toUpperCase()}/${exchangeData.outputCoin.toUpperCase()}`
    );
    if (!curPair) {
      curPair = pairData.find(
        (item) => item.pair.split('/')[0] === exchangeData.inputCoin
      );
      isPairExist = false;
    }
    const currency = currencyConstraintDataList?.data.data.find(
      (item) => item.currency === exchangeData.inputCoin
    );
    if (curPair) {
      if (exchangeData.inputCoin !== currentPair.pair.split('/')[0]) {
        setExchangeData((prev) => ({
          ...prev,
          inputAmount: currency ? currency.minimal_trade_amount : '0',
          outputAmount: currency
            ? (+currency.minimal_trade_amount * +curPair!.price).toString()
            : '0',
        }));
      }
      if (
        exchangeData.outputCoin !== currentPair.pair.split('/')[1] ||
        !isPairExist
      ) {
        setExchangeData((prev) => ({
          ...prev,
          outputAmount: currency
            ? (+exchangeData.inputAmount * +curPair!.price).toString()
            : '0',
          outputCoin: curPair!.pair.split('/')[1],
        }));
      }
      setCurrentPair(curPair);
      setMinimumExchangeAmount(currency ? +currency.minimal_trade_amount : 0);
    }
  }, [showInputModal, showOutputModal]);

  const onChangeStart = () => {
    const email = transferData.find(
      (item) => (item.name as string[])[0] === EXCHANGE_FORM_FIELDS_NAMES.email
    )?.value;
    const payload: Record<string, any> = {
      notification_email: email === '' ? undefined : email,
      input_sum: exchangeData.inputAmount,
      input_price: exchangeData.outputAmount,
      input_currency: exchangeData.inputCoin,
      output_wallet: transferData.find(
        (item) =>
          (item.name as string[])[0] ===
          EXCHANGE_FORM_FIELDS_NAMES.walletAddress
      )?.value,
      output_currency: exchangeData.outputCoin,
    };
    exchangeData.input_network &&
      (payload.input_network = exchangeData.input_network);
    exchangeData.output_network &&
      (payload.output_network = exchangeData.output_network);

    axios.post(`${API_URL}/offchain-exchange/api/orders`, payload, {
      headers: {
        Authorization: cookie.token ? `Bearer ${cookie.token}` : '',
      }
    }).then((res) => {
      notification.success({
        description: t('notifications.orderCreated').toString(),
          message: `${t(
              'notifications.orderCreatedDescriptionPre'
          ).toString()} #${res?.data?.data?.id} ${t(
              'notifications.orderCreatedDescriptionPost'
          ).toString()} ${res?.data?.data?.input_currency}-${
              res?.data?.data?.output_currency
          }`,
      })

      if (!!userAPI?.email?.length) {
        navigate(`/exchange-outputOrder/${res.data.data.id}`);
      } else navigate(`/exchange-outputOrder/${res.data.data.jwt}`);
    }).catch((e) => {
      console.error('onChangeStart e', e)
      notification.error({
        description: "Ошибка создания обмена",
        message: '',
      })
    });
  };

  const reversePair = () => {
    const newPair: ICryptoPair = {
      ...currentPair,
      ...pairData.find(
        (pair) =>
          pair.pair ===
          `${exchangeData.outputCoin.toUpperCase()}/${exchangeData.inputCoin.toUpperCase()}`
      ),
    };
    const newMinInputAmount =
      currencyConstraintDataList?.data.data.find(
        (item) => item.currency == exchangeData.outputCoin
      )?.minimal_trade_amount || '0';
    const newData: IExchangeContent = {
      inputCoin: exchangeData.outputCoin,
      outputCoin: exchangeData.inputCoin,
      inputAmount:
        +exchangeData.outputAmount >= +newMinInputAmount
          ? exchangeData.outputAmount
          : newMinInputAmount,
      outputAmount:
        +exchangeData.outputAmount >= +newMinInputAmount
          ? (+exchangeData.outputAmount * +newPair.price).toString()
          : (+newMinInputAmount * +newPair.price).toString(),
      input_network: exchangeData.output_network,
      output_network: exchangeData.input_network,
    };
    setExchangeData(newData);
    setCurrentPair(newPair);
    setMinimumExchangeAmount(+newMinInputAmount);
  };
  const networkData = useList<
    { id: string; description: string; name: string; network: string },
    HttpError
  >({
    resource: 'network',
    dataProviderName: 'config',
  });

  // TODO: PLAT-2675

  useList<{ value: string }>({
    resource: 'config',
    dataProviderName: 'config',
    config: {
      filters: [
        {
          field: 'key',
          operator: 'eq',
          value: 'commission',
        },
      ],
    },
    queryOptions: {
      onSuccess: (data) => {
        setCommission(data.data[0].value ?? '0');
      },
    },
  });
  const changeExchangeData = (input: string, output: string): void => {
    if (!(Math.abs(+exchangeData.inputAmount - +input) < Number.EPSILON)) {
      let outputData: number;
      outputData = +input * +currentPair.price;

      setExchangeData({
        ...exchangeData,
        inputAmount: input,
        outputAmount: outputData.toString(),
      });

      return;
    }

    if (!(Math.abs(+exchangeData.outputAmount - +output) < Number.EPSILON)) {
      let inputData: number;
      inputData = +output * (1 / +currentPair.price);

      setExchangeData({
        ...exchangeData,
        inputAmount: inputData.toString(),
        outputAmount: output,
      });

      return;
    }
  };
  return !screens.md ? (
    <ErrorBoundary
      logout={() => {}}
      navigate={navigate}
      removeCookie={removeCookie}
    >
      <CryptoCurrencyExchangeMobile
        changePrice={changeExchangeData}
        currentPair={currentPair}
        exchangeData={exchangeData}
        setExchangeData={setExchangeData}
        minimumExchangeAmount={minimumExchangeAmount}
        email={cookie?.email}
        onFormChange={(value) => setTransferData(value)}
        onChangeStart={onChangeStart}
        transferData={transferData}
        isOrderCreateLoading={false}
        listCrypto={cryptoCurrencies}
        listTarget={targetCurrencies}
        currencyConstraintList={currencyConstraintDataList?.data.data}
        network={networkData.data?.data}
      />
    </ErrorBoundary>
  ) : (
    <ErrorBoundary
      logout={() => {}}
      navigate={navigate}
      removeCookie={removeCookie}
    >
      <div className={'exchange-container'} style={{ alignItems: 'center' }}>
        <div>
          <ExchangeHeading />
          <ExchangeContent
            isOrderCreateLoading={false}
            minimumExchangeAmount={minimumExchangeAmount}
            onReverseClick={reversePair}
            changePrice={changeExchangeData}
            exchangeData={exchangeData}
            currentPair={currentPair}
            listCurrencies={currencyConstraintDataList?.data.data}
            email={cookie?.email}
            transferData={transferData}
            onFormChange={(value) => setTransferData(value)}
            onChangeStart={onChangeStart}
            onClickInputCoins={() => setShowInputModal(true)}
            onClickOutputCoins={() => setShowOutputModal(true)}
            commission={commission}
            pairData={pairData}
          />
        </div>
      </div>
      <Modal.ChoseCurrency
        inputData={'enabled'}
        outputData={'disabled'}
        listCurrencies={cryptoCurrencies}
        availableCoins={availableInputs}
        title={t('cryptoExchange.changer.modals.sendTitle')}
        isShow={showInputModal}
        setIsShow={setShowInputModal}
        exchangeData={exchangeData}
        onChoseCurrency={(coin, input_network: string | null) => {
          const currency = currencyConstraintDataList?.data.data.find(
            (item) => item.currency === coin
          );
          setExchangeData({
            ...exchangeData,
            inputCoin: coin,
            inputAmount: currency ? currency.minimal_trade_amount : '0',
            input_network,
          });
          setShowInputModal(false);
        }}
        subTitle={t('cryptoExchange.changer.modals.subTextInput')}
        currencyConstraintList={currencyConstraintDataList?.data.data}
      />
      <Modal.ChoseCurrency
        availableCoins={availableOutputs}
        inputData={'disabled'}
        outputData={'enabled'}
        listCurrencies={cryptoCurrencies}
        title={t('cryptoExchange.changer.modals.getTitle')}
        isShow={showOutputModal}
        setIsShow={setShowOutputModal}
        exchangeData={exchangeData}
        onChoseCurrency={(coin, output_network: string | null) => {
          setExchangeData({
            ...exchangeData,
            outputCoin: coin,
            output_network,
          });
          setShowOutputModal(false);
        }}
        subTitle={t('cryptoExchange.changer.modals.subTextOutput')}
        currencyConstraintList={currencyConstraintDataList?.data.data}
      />
    </ErrorBoundary>
  );
};

export default CryptoCurrencyExchange;

import { TableColumnProps } from '@pankod/refine-antd';
import { IGetListData } from '../../features/myOrders/myOrders.p';
import { Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslate } from '@pankod/refine-core';

export const useGetTranslateMyOrderTableColumns = () => {
  const t = useTranslate();

  const idColumn: TableColumnProps<IGetListData> = {
    title: t('myOrders.table.id'),
    dataIndex: 'id',
    key: 'id',
    render: (value, record, index) => {
      return (
        <div className={'flex-row gap-8'}>
          <Typography.Text className={'text-14px'}>{value}</Typography.Text>
        </div>
      );
    },
    // TODO: Eсли нужна сортировка по столбцу.
    //sorter: (a, b) => stingToAsciiCode(a.address) - stingToAsciiCode(b.address),
  };

  const createdAtColumn: TableColumnProps<IGetListData> = {
    title: t('myOrders.table.date'),
    dataIndex: 'created_at',
    key: 'created_at',
    render: (value, record, index) => {
      return (
        <Typography.Text className={'text-14px'}>
          {dayjs(value).format('DD.MM.YY HH:mm:ss')}
        </Typography.Text>
      );
    },
  };

  const sendAmountColumn: TableColumnProps<IGetListData> = {
    title: t('myOrders.table.send'),
    dataIndex: 'input_price',
    key: 'input_price',
    render: (value, record, index) => {
      if (!!value) {
        return (
          <Typography.Text className={'text-14px'}>{`${(+value)?.toFixed(8)} ${
            record.input_currency
          }`}</Typography.Text>
        );
      } else {
        return (
          <Typography.Text className={'text-14px'}>{`${0} ${
            record.input_currency
          }`}</Typography.Text>
        );
      }
    },
  };

  const getAmountColumn: TableColumnProps<IGetListData> = {
    title: t('myOrders.table.receive'),
    dataIndex: 'output_price',
    key: 'output_price',
    render: (value, record, index) => {
      if (!!value) {
        return (
          <Typography.Text className={'text-14px'}>{`${(+value)?.toFixed(8)} ${
            record.output_currency
          }`}</Typography.Text>
        );
      } else {
        return (
          <Typography.Text className={'text-14px'}>{`${0} ${
            record.output_currency
          }`}</Typography.Text>
        );
      }
    },
  };

  const courseColumn: TableColumnProps<IGetListData> = {
    title: t('myOrders.table.rate'),
    dataIndex: 'course',
    key: 'course',
    render: (value, record, index) => {
      if (!!record?.output_price && !!record?.input_price) {
        return (
          <Typography.Text className={'text-14px'}>{`${(
            record?.output_price / record?.input_price
          )?.toFixed(8)} ${record?.output_currency}`}</Typography.Text>
        );
      } else {
        return (
          <Typography.Text className={'text-14px'}>{`${0} ${
            record?.output_currency
          }`}</Typography.Text>
        );
      }
    },
  };

  const statusColum: TableColumnProps<IGetListData> = {
    title: t('myOrders.table.status'),
    dataIndex: 'status',
    key: 'status',
    render: (value, record, index) => {
      let currColor = '';
      switch (value) {
        case 'canceled':
          currColor = '#F16063';
          break;
        case 'executed':
          currColor = '#58BF92';
          break;
        case 'new':
          currColor = '#5297FF';
          break;
        default:
          currColor = '#58BF92';
      }
      return (
        <>
          {
            <div className={'flex-row align-items-center gap-8'}>
              <div
                style={{
                  width: 8,
                  height: 8,
                  borderRadius: 4,
                  background: currColor,
                }}
              />
              <Typography.Text className={'text-14px'}>
                {t(`wallet.table.status.value.${value}`)}
              </Typography.Text>
            </div>
          }
        </>
      );
    },
  };

  return {
    idColumn,
    createdAtColumn,
    sendAmountColumn,
    getAmountColumn,
    courseColumn,
    statusColum,
  };
};

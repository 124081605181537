import {UserAvatar} from 'shared/components/ui/user-avatar';
import Styles from './index.module.css';
import {useChatContext} from '../context';
import {Badge, Typography} from '@pankod/refine-antd';
import {useTranslation} from 'react-i18next';
import {IP2POrderUser} from "../../../../pages/p2p/myDeals/cards/myTrades.p";
import styled from "styled-components";

interface IHeaderChat {
  nickname: string;
  buyerInfo: IP2POrderUser;
  sellerInfo: IP2POrderUser;
  isSeller: boolean;
}

const ChatHeader = ({nickname, sellerInfo}: IHeaderChat): JSX.Element => {
  const {t} = useTranslation();

  const {user} = useChatContext();

  const getPercentOfCompletedDeals = () => {
    return sellerInfo?.total_orders_amount === 0
      ? 0
      : +(
        +(+sellerInfo?.success_orders_amount /
          +sellerInfo?.total_orders_amount
        ) * 100
      )?.toFixed()
  }


  return (
    <MainContainer>
      <div className={Styles.container}>
        <Badge dot color="green">
          <UserAvatar user={user} size={'large'} color="blue"/>
        </Badge>
        <div className={Styles.container__titles}>
          <Typography.Text className={Styles.title__name}>
            {nickname}
          </Typography.Text>
          <Typography.Text className={Styles.title__info}>
            {t('chatModule.statuses.online')}
          </Typography.Text>
        </div>

      </div>
      <SellerInfo>
        <Text>
          {`${sellerInfo?.total_orders_amount} ${t('p2p.orderDeals.deals')}`}
        </Text>
        <Text>
          {`${getPercentOfCompletedDeals()}% ${t('p2p.orderDeals.completed')}`}
        </Text>
      </SellerInfo>
    </MainContainer>
  );
};

export {ChatHeader};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 32px 16px 24px;
  border-bottom: 1px solid #413D50;
`

const Text = styled(Typography)`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #FBFBFF;
`

const SellerInfo = styled.div`
  display: flex;
  gap: 8px;
`

import { t } from 'i18next';
import {IGetListData} from '../../pages/p2p/myDeals/cards/myTrades.p'

interface IGetCurrentStep {
  currentStatus: string;
  isSeller?: boolean;
  isMyAppeal: boolean;
  appealsAmount: number;
  dealType: string;
  currentOrder: IGetListData;
  userId: string
}

interface IButton {
  isDisabled: boolean;
  text: string;
}

interface ICurrentStepP2P {
  currentStep: number;
  leftButton: IButton;
  rightButton: IButton;
}

/**
 * Checks if the given time has reached 30 minutes after the current time.
 *
 * @param {string} time - The time to be checked.
 * @return {boolean} Returns true if the given time has reached 30 minutes after the current time, otherwise returns false.
 */
const isTimerTimeEnd = (time: string): boolean => {
  // Получаем текущую дату и время с часовым поясом 00
  const now = new Date()
  const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

  // Парсим переданную дату в формате 2023-09-13 07:29:59
  const targetDateTime = new Date(time)


  // Добавляем 30 минут к переданной дате
  const targetDateTimePlus30Mins = new Date(targetDateTime.getTime() + 30 * 60000);

  // Сравниваем текущую дату и время с targetDateTimePlus30Mins
  return utc.getTime() > targetDateTimePlus30Mins.getTime()
};

export const getCurrentStepP2P = ({
                                    currentStatus,
                                    isSeller,
                                    isMyAppeal,
                                    appealsAmount,
                                    dealType,
                                    currentOrder,
                                    userId
                                  }: IGetCurrentStep): ICurrentStepP2P => {
  let isOwner = false

  if (currentOrder && currentOrder.ad && currentOrder.ad.owner_id) {
    isOwner = currentOrder.ad.owner_id === userId
  }

  if (isSeller) {
    return getSellerSteps(currentStatus, isMyAppeal, appealsAmount, dealType, currentOrder, isOwner, true);
  } else {
    return getBuyerSteps(currentStatus, isMyAppeal, appealsAmount, dealType, currentOrder, isOwner, false);
  }
};

const getSellerSteps = (
  currentStatus: string,
  isMyAppeal: boolean,
  appealsAmount: number,
  dealType: string,
  currentOrder: IGetListData,
  isOwner: boolean,
  isSeller: boolean,
) => {
  switch (currentStatus) {
    case 'respond':
      return {
        currentStep: 0,
        leftButton: {
          isDisabled: !isOwner,
          text:
            !isOwner
              ? t('p2p.getCurrentStep.confirmPayment')
              : t('p2p.getCurrentStep.acceptDeal'),
        },
        rightButton: {
          isDisabled: !isOwner && !isTimerTimeEnd(currentOrder.timer_start),
          text:
            t('p2p.getCurrentStep.cancelDeal'),
        },
      };
    case 'respond_accepted':
      return {
        currentStep: 1,
        leftButton: {
          isDisabled: isSeller,
          text: t('p2p.getCurrentStep.confirmPayment'),
        },
        rightButton: {
          isDisabled: !isTimerTimeEnd(currentOrder.timer_start),
          text: t('p2p.getCurrentStep.submitAppeal'),
        },
      };
    case 'fiat_transferred':
      return {
        currentStep: 2,
        leftButton: {
          isDisabled: !isSeller,
          text: t('p2p.getCurrentStep.confirmPayment'),
        },
        rightButton: {
          isDisabled: !isTimerTimeEnd(currentOrder.timer_start),
          text: t('p2p.getCurrentStep.submitAppeal'),
        },
      };
    case 'success':
      return {
        currentStep: 3,
        leftButton: {
          isDisabled: true,
          text: t('p2p.getCurrentStep.confirmPayment'),
        },
        rightButton: {
          isDisabled: true,
          text: t('p2p.getCurrentStep.submitAppeal'),
        },
      };
    case 'appealed':
      return {
        currentStep: -1,
        leftButton: {
          isDisabled: true,
          text: t('p2p.getCurrentStep.confirmPayment'),
        },
        rightButton: {
          isDisabled: !isMyAppeal,
          text: isMyAppeal ? t('p2p.getCurrentStep.cancelAppeal') : t('p2p.getCurrentStep.submitAppeal'),
        },
      };
    case 'refused':
      return {
        currentStep: 0,
        leftButton: {
          isDisabled: true,
          text: t('p2p.getCurrentStep.acceptDeal'),
        },
        rightButton: {
          isDisabled: true,
          text: t('p2p.getCurrentStep.cancelDeal'),
        },
      };
    case 'canceled':
      return {
        currentStep: 0,
        leftButton: {
          isDisabled: true,
          text: t('p2p.getCurrentStep.confirmPayment'),
        },
        rightButton: {
          isDisabled: true,
          text: t('p2p.getCurrentStep.submitAppeal'),
        },
      };
    default:
      return {
        currentStep: 0,
        leftButton: {
          isDisabled: true,
          text: t('p2p.getCurrentStep.acceptDeal'),
        },
        rightButton: {
          isDisabled: true,
          text: t('p2p.getCurrentStep.cancelDeal'),
        },
      };
  }
};

const getBuyerSteps = (
  currentStatus: string,
  isMyAppeal: boolean,
  appealsAmount: number,
  dealType: string,
  currentOrder: IGetListData,
  isOwner: boolean,
  isSeller: boolean
) => {
  switch (currentStatus) {
    case 'respond':
      return {
        currentStep: 0,
        leftButton: {
          isDisabled: !isOwner,
          text: isOwner ? t('p2p.getCurrentStep.acceptDeal') : t('p2p.getCurrentStep.confirmPayment'),
        },
        rightButton: {
          isDisabled: !isOwner && !isTimerTimeEnd(currentOrder.timer_start),
          text: t('p2p.getCurrentStep.cancelDeal')
        },
      };
    case 'respond_accepted':
      return {
        currentStep: 1,
        leftButton: {
          isDisabled: isSeller,
          text: t('p2p.getCurrentStep.confirmPayment'),
        },
        rightButton: {
          isDisabled: !isTimerTimeEnd(currentOrder.timer_start),
          text: t('p2p.getCurrentStep.submitAppeal'),
        },
      };
    case 'fiat_transferred':
      return {
        currentStep: 2,
        leftButton: {
          isDisabled: !isSeller,
          text: t('p2p.getCurrentStep.confirmPayment'),
        },
        rightButton: {
          isDisabled: isTimerTimeEnd(currentOrder.timer_start),
          text: t('p2p.getCurrentStep.submitAppeal'),
        },
      };
    case 'success':
      return {
        currentStep: 3,
        leftButton: {
          isDisabled: true,
          text: t('p2p.getCurrentStep.confirmPayment'),
        },
        rightButton: {
          isDisabled: true,
          text: t('p2p.getCurrentStep.submitAppeal'),
        },
      };
    case 'appealed':
      return {
        currentStep: -1,
        leftButton: {
          isDisabled: true,
          text: t('p2p.getCurrentStep.confirmPayment'),
        },
        rightButton: {
          isDisabled: !isMyAppeal,
          text: isMyAppeal ? t('p2p.getCurrentStep.cancelAppeal') : t('p2p.getCurrentStep.submitAppeal'),
        },
      };
    case 'canceled':
      return {
        currentStep: 0,
        leftButton: {
          isDisabled: true,
          text: t('p2p.getCurrentStep.confirmPayment'),
        },
        rightButton: {
          isDisabled: true,
          text: t('p2p.getCurrentStep.submitAppeal'),
        },
      };
    case 'refused':
      return {
        currentStep: 0,
        leftButton: {
          isDisabled: true,
          text: t('p2p.getCurrentStep.confirmPayment'),
        },
        rightButton: {
          isDisabled: true,
          text: t('p2p.getCurrentStep.cancelDeal'),
        },
      };
    default:
      return {
        currentStep: 0,
        leftButton: {
          isDisabled: false,
          text: t('p2p.getCurrentStep.acceptDeal'),
        },
        rightButton: {
          isDisabled: false,
          text: t('p2p.getCurrentStep.cancelDeal'),
        },
      };
  }
};

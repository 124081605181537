import * as React from 'react';
import { FC } from 'react';
import EyeButton from '../../../../shared/components/ui/button/eyeButton';
import { Typography } from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import IOButtons from '../IOButtons/IOButtons';
import { useWalletState } from '../../../../useWalletState';
import { useAuthState } from '../../../../useAuthState';

const { Title, Text } = Typography;

const WalletHeader: FC = () => {
  const { t } = useTranslation();
  const totalFiat = useAuthState((state) => state.totalFiat);
  const total = useAuthState((state) => state.total);
  const { isBalanceVisible, setBalanceVisible } = useWalletState(
    (state) => state
  );

  const showBalanceUSD = () =>
    !isBalanceVisible ? '*******' : `≈${totalFiat || 0} USD`;

  const showBalanceBTC = () =>
    !isBalanceVisible ? '*******' : `${total || 0} BTC`;

  return (
    <WalletHeaderWrapper>
      <WalletWidgetName>{t('wallet.view.title')}</WalletWidgetName>
      <WalletBalanceContent>
        <div>
          <WalletBalanceText>
            {t('wallet.view.amount')}
            <EyeButton
              color={'white'}
              isVisible={!isBalanceVisible}
              onClickAction={setBalanceVisible}
            />
          </WalletBalanceText>
          {total && (
            <WalletBalanceWrapper>
              <WalletBalanceBTC>{showBalanceBTC()}</WalletBalanceBTC>
              <WalletBalanceUSD>{showBalanceUSD()}</WalletBalanceUSD>
            </WalletBalanceWrapper>
          )}
        </div>
        <IOButtons />
      </WalletBalanceContent>
    </WalletHeaderWrapper>
  );
};

export default WalletHeader;

const WalletHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const WalletWidgetName = styled(Title)`
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 0 !important;
`;

const WalletBalanceContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WalletBalanceText = styled(Text)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 0 !important;
  font-size: 16px;
  font-weight: 700;
`;

const WalletBalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const WalletBalanceBTC = styled(Text)`
  font-weight: bold;
  font-size: 28px;
`;

const WalletBalanceUSD = styled(Text)`
  font-size: 14px;
  color: #767687;
`;

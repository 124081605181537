import { FC } from 'react';
import {  Card, Typography } from '@pankod/refine-antd';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  getFractionNumber,
  getFullCoinName,
  getIconByCoinName,
  TCoinName,
} from '../../../../entities/coin';
import CoinDepositModal from '../CoinDepositModal/CoinDepositModal';
import { useRelay } from '../../../../shared/hooks/use-relay';
import {getFullNameCoin} from "../../../../shared/helpers/getFullNameCoin";

interface IBalance {
  amount: string;
  deposit: string;
}
interface IProps {
  isVisible: boolean;
  coin: {
    name: TCoinName;
    data: IBalance;
  };
}

const { Title, Text } = Typography;
const CoinCard: FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { isVisible, coin } = props;
  const { disable, state } = useRelay();

  const navigate = useNavigate();

  const handleNavigate = () =>
    navigate(`/coin-accounts/${coin?.name?.toLowerCase()}`);

  const showBalance = () =>
    !isVisible ? '********' : getFractionNumber(+coin?.data?.amount);

  const showDeposit = () => !isVisible ? '********' : getFractionNumber(+coin?.data.deposit)

  return (
    <CoinCardBorder>
      <CoinCardWrapper bordered={false} onClick={handleNavigate}>
        <CoinCardHeader>
          <CoinIcon>{getIconByCoinName(coin.name.toLowerCase(),30)}</CoinIcon>
          <CoinCardTitleWrapper>
            <CoinCardTitle>{getFullNameCoin(coin.name.toLowerCase())}</CoinCardTitle>
          </CoinCardTitleWrapper>
        </CoinCardHeader>
        <CoinCardBody>
          <Text>{t('wallet.balance')}</Text>
          <CoinBalanceWrapper>
            <CoinBalance>{showBalance()}</CoinBalance>
            <CoinDeposit>
              {showDeposit() +
                ' ' +
                t('wallet.frozen')}
            </CoinDeposit>
          </CoinBalanceWrapper>
        </CoinCardBody>
      </CoinCardWrapper>
      <CoinDepositModal
        coin={coin.name}
        coinName={getFullCoinName(coin.name)}
        isOpen={state}
        onCancel={disable}
      />
    </CoinCardBorder>
  );
};

export default CoinCard;


const CoinCardBorder = styled.div`
  display: flex;
  width: 362px;
  max-height: 173px;
  position: relative;
  padding-top: 2px;
  padding-left: 2px;
  background: linear-gradient(
    155deg,
    rgba(82, 151, 255, 1),
    rgba(82, 151, 255, 0.1),
    rgba(82, 151, 255, 0)
  );
  border-radius: 16px;

  animation: fadeIn 0.25s ease-in;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const CoinCardWrapper = styled(Card)`
  width: 360px;
  cursor: pointer;
  border-radius: 1rem;
  background: #1c1c27;
  color: white;
  padding: 15px 0 16px 24px;
  overflow: hidden;

  .ant-card-body {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
  }
`;

const CoinIcon = styled.div`
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  position: relative;
  transform: translate(0);

  &:before {
    content: '';
    position: absolute;
    inset: -10px;
    transform: translate(-5px, 0);
    z-index: -1;
    background: linear-gradient(25.41deg, #6e27f8 2.1%, #20d9c0 71.42%);
    filter: blur(18.5px);
    border-radius: 40px;
    opacity: 0.2;
  }
`;

const CoinCardHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap:8px;
`;

const CoinCardTitleWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
`;

const CoinCardTitle = styled(Title)`
  width: 100% !important;
  font-size: 18px !important;
  margin-bottom: 0 !important;
`;

const CoinCardBody = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
`;

const CoinBalanceWrapper = styled.div`
  width: 80%;

  display: flex;
  justify-content: center;
  flex-direction: column;

  border-radius: 4px;
`;

const CoinBalance = styled(Text)`
  font-size: 18px;
  font-weight: 500;
`;

const CoinDeposit = styled(Text)`
  color: rgba(118, 118, 135, 1);
  font-size: 12px;
`;

import { Typography } from '@pankod/refine-antd';
import { BaseRecord } from '@pankod/refine-core';
import './style.css';
import { useParams } from '@pankod/refine-react-router-v6';
import { BookTableContent, BookTableHeader, BookTableRow, BookTableRowBg, Columns, ContentCell, OrderBookTableWrapper, TableTitle, TitleCell } from './components';
import { useTranslation } from 'react-i18next';

type TableColumns = {
  title: string;
  value: string;
};

export const OrderBookTables = ({
  stringFilter,
  buyData,
  sellData,
  roundFilter,
}: {
  stringFilter: number;
  buyData: any;
  sellData: any;
  roundFilter: any;
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('orderBookTable.cols.total'),
      value: 'total',
    },
    {
      title: t('orderBookTable.cols.sum'),
      value: 'sum',
    },
    {
      title: t('orderBookTable.cols.quantity'),
      value: 'quantity',
    },
    {
      title: t('orderBookTable.cols.price'),
      value: 'price',
    },
  ];

  return (
    <div className="flex-row">
      <OrderBookTable
        isReverse={false}
        stringFilter={stringFilter}
        color={'rgba(88, 191, 146, 0.12)'}
        columns={columns}
        title={t('orderBookTable.purchase')}
        data={buyData}
        roundFilter={roundFilter}
      />
      <OrderBookTable
        isReverse={true}
        stringFilter={stringFilter}
        color={'rgba(241, 96, 99, 0.15)'}
        columns={columns}
        title={t('orderBookTable.sale')}
        data={sellData}
        roundFilter={roundFilter}
      />
    </div>
  );
};

const OrderBookTable = ({
  title,
  columns,
  data,
  color,
  stringFilter,
  roundFilter,
  isReverse,
}: {
  title: string;
  columns: TableColumns[];
  data?: Array<any>;
  color: string;
  stringFilter: number;
  roundFilter: number;
  isReverse: boolean;
}) => {
  const getColumnFullTitle = (column: TableColumns) => {
    switch (column.value) {
      case 'type':
        return column?.title;
      case 'quantity':
        return column?.title + ` (${ccy})`;
      default:
        return column?.title + ` (${fiat})`;
    }
  };
  const { ccy, fiat } = useParams();
  const getItemInfo = (
    column: TableColumns,
    item: BaseRecord,
    index: number
  ) => {
    let itemText: string | number = '';
    switch (column.value) {
      case 'price':
        itemText = !!item.price ? (+item.price)?.toFixed(roundFilter) : 0;
        break;
      case 'sum':
        itemText = !!item.amountAll
          ? (+item.amountAll)?.toFixed(roundFilter)
          : 0;
        break;
      case 'total':
        itemText =
          !!item?.amountAll && !!item?.price
            ? (+item?.amountAll * +item?.price)?.toFixed(roundFilter)
            : 0;
        break;
      default:
        itemText = !!item.amountInCurrency
          ? (+item.amountInCurrency)?.toFixed(roundFilter)
          : 0;
    }
    return (
      <ContentCell key={item.id} className={`column-${column.value}`}>
        <Typography.Text>{itemText}</Typography.Text>
      </ContentCell>
    );
  };

  function reverseCols() {
    return isReverse ? columns.reverse() : columns;
  }

  const TableColumn = (item: TableColumns) => {
    return (
      <TitleCell className={`column-${item.value}`}>
        <TableTitle key={item.value}>
          {getColumnFullTitle(item)}
        </TableTitle>
      </TitleCell>
    )
  }

  const TableRowF = (item: any, index: number) => {
    return (
      index + 1 <= stringFilter && (
        <BookTableRow key={item.id}>
          <BookTableRowBg className={title === 'Покупка' ? 'fromRight' : 'fromLeft'}
            style={{
              width: `${item.percent}%`,
              background: color,
            }}
          />
          {columns.map((column, i) => getItemInfo(column, item, index))}
        </BookTableRow>
      )
    )
  }

  return (
    <OrderBookTableWrapper>
      <BookTableHeader>
        <Typography.Text className="text-bold">{title}</Typography.Text>
      </BookTableHeader>
      <Columns>
        {isReverse ? reverseCols().map((item) => (
          TableColumn(item)
        ))
          : columns.map((item) => (
            TableColumn(item)
          ))
        }
      </Columns>
      <BookTableContent>
        {!!data?.length &&
          isReverse ? data.reverse()?.map(
            (item, index) =>
              TableRowF(item, index)
          )
          : data?.map(
            (item, index) =>
              TableRowF(item, index)
          )
        }
      </BookTableContent>
    </OrderBookTableWrapper>
  );
};

import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Typography,
} from '@pankod/refine-antd';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FieldData } from '../../../../features/cryptocurrencyExchange/cryptoCurrencyExchange.p';
import Modals from '../../../../shared/components/ui/modal';
import { DEFAULT_VALUES, ModalWidth, WithdrawStep } from '../../constants';
import { coinHandlers } from '../../../../entities/coin/coinHandlers';
import { accountHandlers } from '../../../../entities/account/accountHandler';
import CoinForm from '../CoinForm/CoinForm';
import { notification } from 'antd';
import { useWalletState } from '../../../../useWalletState';
import { ReactComponent as CopyIcon } from '../../../../shared/components/ui/assets/svg/copyIcon.svg';
import { useAuthState } from '../../../../useAuthState';

const { Text } = Typography;

interface IProps {
  isOpen: boolean;
  onCancel: () => void;
}

const WithdrawModal: FC<IProps> = ({ isOpen, onCancel }) => {
  const balanceArr = useAuthState((state) => state.balance);
  const balanceBTCAmount = useAuthState((state) => state?.balance?.BTC?.amount);
  const balanceETHAmount = useAuthState((state) => state?.balance?.ETH.amount);
  const userAPI = useAuthState((state) => state.userAPI);
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const selectedCoin = useWalletState((state) => state.selectedCoin);
  const selectedCoinNetwork = useWalletState(
    (state) => state.selectedCoinNetwork
  );
  const resetWalletData = useWalletState((state) => state.resetWalletData);

  const amount = Form.useWatch('amount', form);
  const address = Form.useWatch('address', form);

  const [transferData] = useState<FieldData[]>(DEFAULT_VALUES);
  const [step, setStep] = useState<WithdrawStep>(WithdrawStep.Main);
  const [isUserPassphraseGuardEnabled, setIsUserPassphraseGuardEnabled] =
    useState<boolean>(false);
  const [confirmModalData, setConfirmModalData] = useState({
    amount: '0',
    address: '',
    coin: '',
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  let [error, setError] = useState(false);
  const [attempts, setAttempts] = useState(3);

  const checkCoinAndNetwork = !!selectedCoin?.networks?.length
    ? !!selectedCoin && !!selectedCoinNetwork
    : !!selectedCoin;

  const getModalTitle = () =>
    step === WithdrawStep.Main
      ? t('wallet.coin.withdraw.withdrawalCryptocurrency')
      : t('wallet.coin.withdraw.securityConfirmation');

  const close = () => {
    setStep(WithdrawStep.Closed);
    form.resetFields();
    onCancel();
  };

  // TODO: Доработать модалку
  const getFooterStep = () =>
    step === WithdrawStep.Main ? (
      <ModalFooter>
        <PurpleButton
          onClick={onOk}
          disabled={
            !checkCoinAndNetwork ||
            !address ||
            !+amount ||
            !(+amount <= getWalletAmount()) ||
            !(+amount >= +selectedCoin!.minimal_withdraw_amount)
          }
        >
          {t('wallet.coin.withdraw.modalWithdrawButton')}
        </PurpleButton>
        <PurpleOutlineButton onClick={close}>
          {t('wallet.coin.withdraw.modalCancelButton')}
        </PurpleOutlineButton>
      </ModalFooter>
    ) : (
      <ModalFooter>
        <Text className={'text-14px'}>
          {t('wallet.coin.withdraw.forgotSecretPhrase')}
        </Text>
        <Text className={'bold-14px-text'}>
          {t('wallet.coin.withdraw.help')}
        </Text>
      </ModalFooter>
    );

  const getWalletAmount = (): number => {
    if (selectedCoin) {
      const balance = Object.entries(balanceArr).filter(
        ([key, _]) => key?.toLowerCase() === selectedCoin.currency.toLowerCase()
      );

      if (!!balance?.length) {
        // @ts-ignore
        return balance[0][1]?.amount;
      }
    }
    return 0;
  };

  const onOk = () => {
    const amount = form.getFieldsValue(['amount']);
    let hasError = false;
    const coin = selectedCoin?.currency;
    if (
      coin === 'btc' &&
      (+balanceBTCAmount === 0 || +amount > +balanceBTCAmount)
    ) {
      notification['warning']({
        message: t('notifications.insufficientFunds'),
        description: t('notifications.insufficientFundsBtc'), //На счету BTC недостаточно средств для вывода!
      });
      hasError = true;
    }
    if (
      coin === 'eth' &&
      (+balanceETHAmount === 0 || +amount > +balanceBTCAmount)
    ) {
      notification['warning']({
        message: t('notifications.insufficientFunds'),
        description: t('notifications.insufficientFundsEth'),
      });
      hasError = true;
    }
    if (
      step === WithdrawStep.Main &&
      isUserPassphraseGuardEnabled &&
      !hasError
    ) {
      try {
        form.validateFields().then(() => {
          setStep(WithdrawStep.PassphraseGuard);
        });
      } catch (errorInfo) {
        console.error(errorInfo);
      }
      return;
    }

    if (!hasError) {
      form
        .validateFields()
        .then(() => {
          if (selectedCoin) {
            coinHandlers.output({
              coin: selectedCoin.currency,
              data: form.getFieldsValue([
                'amount',
                'address',
                'passphrase',
                'network',
              ]),
              onSuccess: () => {
                setConfirmModalData({
                  amount: form.getFieldValue('amount').toString(),
                  address: form.getFieldValue('address'),
                  coin: selectedCoin.currency,
                });
                setShowConfirmModal(true);
                close();
              },
              onError: () => {
                setError(true);
                setAttempts((prevState) => prevState - 1);
              },
            });
          }
        })
        .catch((reason) => {
          console.log(reason);
        });
    }
  };


  useEffect(() => {
    accountHandlers.getAccount<any[]>({
      onSuccess: (data) => {
        setIsUserPassphraseGuardEnabled(
          data.data.filter((phrase: any) => phrase.passphrase_isset).length > 0
        );
      },
    });
    return () => {
      form.resetFields();
      setConfirmModalData({
        amount: '0',
        address: '',
        coin: '',
      });
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setStep(WithdrawStep.Main);
      resetWalletData();
    }
  }, [isOpen]);

  useEffect(() => {
    form.resetFields();
  }, [selectedCoin]);

  useEffect(() => {
    if (attempts === 0) {
      // navigate('/blocked');
      window.location.reload();
    }
  }, [attempts]);

  const resultSumText = `${
    amount > 0
      ? (+amount - (selectedCoin ? +selectedCoin?.output_commission : 0))
          .toString()
          .replace('.', ',') || 0
      : (0).toFixed(8).toString().replace('.', ',')
  } ${selectedCoin?.currency ? selectedCoin?.currency.toUpperCase() : ''}`;

  return (
    <>
      <Modal
        title={getModalTitle()}
        open={isOpen}
        onCancel={() => {
          onCancel();
          setAttempts(3);
          setError(false);
        }}
        onOk={onOk}
        width={ModalWidth}
        footer={getFooterStep()}
        destroyOnClose={true}
      >
        <Form form={form} layout={'vertical'} fields={transferData}>
          <FormContent $display={step === WithdrawStep.Main}>
            <CoinSelect type={'output'} />
            <>
              <FormInput
                $marginBottom={24}
                name="address"
                label={t('wallet.coin.withdraw.inputs.labels.walletAddress')}
                rules={[
                  {
                    required: true,
                    message: t(
                      'wallet.coin.withdraw.inputs.errors.required'
                    ).toString(),
                  },
                ]}
                required={false}
                hidden={step !== WithdrawStep.Main}
              >
                <AddressInput
                  size="large"
                  placeholder={t(
                    'wallet.coin.withdraw.inputs.placeholders.walletAddress'
                  ).toString()}
                />
              </FormInput>
              <FormInput
                $marginBottom={8}
                name="amount"
                label={t('wallet.coin.withdraw.inputs.labels.withdrawSum')}
                rules={[
                  {
                    required: true,
                    message: t(
                      'wallet.coin.withdraw.inputs.errors.required'
                    ).toString(),
                  },
                  {
                    validator(rule, value, callback) {
                      +value > +getWalletAmount()
                        ? callback(
                            t(
                              'wallet.coin.withdraw.inputs.errors.amountExceedAvailableBalance'
                            ).toString()
                          )
                        : callback();
                    },
                  },
                  {
                    validator(rule, value, callback) {
                      +value <= 0
                        ? callback(
                            t(
                              'wallet.coin.withdraw.inputs.errors.amountMustGreaterZero'
                            ).toString()
                          )
                        : callback();
                    },
                  },
                  {
                    validator(rule, value, callback) {
                      if (selectedCoin) {
                        +value < +selectedCoin?.minimal_withdraw_amount
                          ? callback(
                              `${t(
                                'wallet.coin.withdraw.inputs.errors.minSum'
                              ).toString()} ${
                                selectedCoin?.minimal_withdraw_amount
                              } ${selectedCoin?.currency.toUpperCase()}`
                            )
                          : callback();
                      }
                    },
                  },
                ]}
                required={false}
                hidden={step !== WithdrawStep.Main}
              >
                <BalanceInput
                  type={'number'}
                  size="large"
                  placeholder="0.000000000"
                  controls={false}
                />
              </FormInput>
              {selectedCoin && (
                <AmountHint>
                  <AmountHintText>
                    {t('wallet.coin.withdraw.availableBalance')}:
                  </AmountHintText>
                  <AmountHintText>
                    {`${
                      !!getWalletAmount() ? getWalletAmount() : 0
                    } ${selectedCoin?.currency?.toUpperCase()}`}
                  </AmountHintText>
                  <CopyStyled onClick={onOk}>
                    <CopyIcon />
                  </CopyStyled>
                </AmountHint>
              )}
              <ResultSumContainer>
                <ResultSumContainerTitle>
                  {t('wallet.coin.withdraw.resultSum')}
                </ResultSumContainerTitle>
                <ResultSum>
                  <ResultSumText>{resultSumText}</ResultSumText>
                  <AmountHint>
                    <AmountHintText $gray>
                      {t('wallet.coin.withdraw.withdrawCommission')}
                    </AmountHintText>
                    <AmountHintText>
                      {selectedCoin
                        ? `${
                            selectedCoin?.output_commission
                          } ${selectedCoin?.currency.toUpperCase()}`
                        : '-'}
                    </AmountHintText>
                  </AmountHint>
                </ResultSum>
              </ResultSumContainer>
            </>
          </FormContent>
          <FormContent $display={step === WithdrawStep.PassphraseGuard}>
            <div style={{ marginBottom: 24 }}>
              <Text className={'text-16px'}>
                {t('wallet.coin.withdraw.securityDescription')}
              </Text>
            </div>
            <Form.Item
              name={'passphrase'}
              label={t(
                'wallet.coin.withdraw.inputs.labels.currentSecretPhrase'
              )}
              rules={[
                {
                  required: step === WithdrawStep.PassphraseGuard,
                  message: t(
                    'wallet.coin.withdraw.inputs.errors.required'
                  ).toString(),
                },
              ]}
              required={false}
              hidden={step !== WithdrawStep.PassphraseGuard}
              extra={
                error && (
                  <span className={'helpInput'} style={{ fontSize: '12px' }}>
                    {t('wallet.coin.withdraw.inputs.errors.attempts').replace(
                      ':!:',
                      attempts.toString()
                    )}
                  </span>
                )
              }
            >
              <Input.Password
                style={{ borderRadius: 8 }}
                placeholder={
                  t('wallet.coin.withdraw.secretPhrasePlaceholder') ?? undefined
                }
                size={'large'}
              />
            </Form.Item>
            <Form.Item style={{ marginTop: 40, marginBottom: 40 }}>
              <Button
                style={{ width: '100%' }}
                className={'custom-button-purple'}
                onClick={onOk}
              >
                {t('wallet.coin.withdraw.confirm')}
              </Button>
            </Form.Item>
          </FormContent>
        </Form>
      </Modal>
      <Modals.ConfirmWithdrawModal
        isShow={showConfirmModal}
        setIsShow={setShowConfirmModal}
        outputWallet={confirmModalData?.address}
        outputPrice={confirmModalData?.amount}
        outputCurrency={confirmModalData?.coin}
        email={userAPI?.email}
      />
    </>
  );
};

export default WithdrawModal;

const FormContent = styled.div<{ $display: boolean }>`
  display: ${(props) => (props.$display ? 'block' : 'none')};
`;

const CoinSelect = styled(CoinForm)`
  margin-bottom: 24px;
`;

const AddressInput = styled(Input)`
  border-radius: 8px;
  color: white;
  background: linear-gradient(80.47deg, #18181f 6.62%, #2d2d3a 148.62%);
`;

const BalanceInput = styled(InputNumber)`
  border: 1px solid #474755;
  border-radius: 8px;
  background: linear-gradient(80.47deg, #18181f 6.62%, #2d2d3a 148.62%);
  width: 100%;

  & .ant-input-number-input-wrap input::placeholder {
    color: #767687;
  }
`;

const AmountHint = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AmountHintText = styled(Text)<{ $gray?: boolean }>`
  font-size: 12px;
  color: ${(props) => (props.$gray ? '#767687' : 'white')};
`;

const FormInput = styled(Form.Item)<{ $marginBottom: number }>`
  margin-bottom: ${(props) => props.$marginBottom}px;
`;

const ResultSumContainer = styled.div`
  margin-top: 50px;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ResultSumContainerTitle = styled(Text)`
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
`;

const ResultSum = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ResultSumText = styled(Text)`
  font-size: 18px;
  font-weight: 500;
`;

const ModalFooter = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  gap: 16px;
`;

const PurpleOutlineButton = styled(Button)`
  width: 40% !important;
  height: 40px;

  color: white;
  background: linear-gradient(
    80.47deg,
    #18181f 6.62%,
    #2d2d3a 148.62%
  ) !important;

  position: relative;

  border: 1px solid #6e27f8;

  transform-style: preserve-3d;

  &:hover {
    background: #2b2b36 !important;
    color: white !important;
  }

  &:active {
    background: #18181f !important;
    color: white !important;
  }
`;

const PurpleButton = styled(Button)`
  width: 60% !important;
  height: 40px;

  color: white;
  background: linear-gradient(
    75.86deg,
    #6320e6 6.84%,
    #7b59fd 99.53%
  ) !important;

  border: none;

  &:hover {
    background: linear-gradient(
      75.86deg,
      #7d50ff 6.84%,
      #7785ff 107.11%
    ) !important;
    color: white !important;
    border: none;
  }

  &:disabled {
    background: #2b2b36 !important;
    color: #767687 !important;
  }

  &:active {
    background: linear-gradient(
      75.86deg,
      #4603ca 6.84%,
      #742efd 99.53%
    ) !important;
    color: white !important;
  }

  &:disabled {
    color: rgba(118, 118, 135, 1) !important;
    background: rgba(43, 43, 54, 1) !important;
  }
`;

const CopyStyled = styled.div`
  cursor: pointer;
`;

import { getCoinInfoByName } from './getCoinInfoByName';

export interface FormCoinList {
  coinFullName: string;
  coinIcon: JSX.Element;
  shortName: string;
  key: string;
}

export const formSelectList = <T>(
  data: T[],
  coinNameSource: keyof T
): FormCoinList[] => {
  return data?.map((item) => {
    const coinData = getCoinInfoByName(item[coinNameSource] as string);
    return {
      coinFullName: coinData.coinFullName,
      coinIcon: coinData.icon as JSX.Element,
      shortName: coinData.key,
      key: coinData.key,
    };
  });
};

import React from 'react';
import './index.css';
import { Table } from 'antd';
import { TableProps, Skeleton } from '@pankod/refine-antd';

export interface ITable extends TableProps<any> {
  maxHeight?: string | number;
}

const Index: React.FC<ITable> = ({ columns, maxHeight, loading, ...props }) => {
  return (
    <>
      <Skeleton
        className={'custom-skeleton-elements'}
        loading={!!loading}
        active={!!loading}
      >
        <div></div>
      </Skeleton>
      <Skeleton
        className={'custom-skeleton-elements'}
        loading={!!loading}
        active={!!loading}
      >
        <Table
          className={'my-table'}
          bordered={false}
          {...props}
          style={{
            ...props.style,
            overflowY: 'auto',
            maxHeight:
              typeof maxHeight === 'number' ? `${maxHeight}px` : maxHeight,
          }}
          columns={columns}
        />
      </Skeleton>
    </>
  );
};

export default Index;

export const AllTypeCoinIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1341_40933)">
      <path
        d="M24.22 10.8591C22.22 10.2591 20.16 10.8191 18.83 12.1491L16 14.6591L14.48 15.9991H14.49L11.8 18.3891C10.99 19.1991 9.85 19.5391 8.68 19.3091C7.43 19.0591 6.4 18.0591 6.11 16.8191C5.59 14.5891 7.27 12.6191 9.4 12.6191C10.31 12.6191 11.16 12.9691 11.84 13.6491L12.31 14.0591C12.69 14.3991 13.26 14.3991 13.64 14.0591C14.09 13.6591 14.09 12.9591 13.64 12.5591L13.22 12.1991C12.2 11.1791 10.84 10.6191 9.4 10.6191C6.42 10.6191 4 13.0391 4 15.9991C4 18.9591 6.42 21.3791 9.4 21.3791C10.84 21.3791 12.2 20.8191 13.17 19.8491L16 17.3491L16.01 17.3591L17.52 15.9991H17.51L20.2 13.6091C21.01 12.7991 22.15 12.4591 23.32 12.6891C24.57 12.9391 25.6 13.9391 25.89 15.1791C26.41 17.4091 24.73 19.3791 22.6 19.3791C21.7 19.3791 20.84 19.0291 20.16 18.3491L19.68 17.9291C19.3 17.5891 18.73 17.5891 18.35 17.9291C17.9 18.3291 17.9 19.0291 18.35 19.4291L18.77 19.7991C19.79 20.8091 21.14 21.3691 22.59 21.3691C25.86 21.3691 28.45 18.4691 27.92 15.1191C27.62 13.1291 26.15 11.4291 24.22 10.8591Z"
        fill="#FBFBFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_1341_40933">
        <rect width="24" height="24" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </svg>
);

export const TetherCoinIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0597 13.334V10.9884H23.3959V7.28711H8.58344V10.9884H13.9347V13.334C9.45156 13.5496 6.08594 14.4496 6.08594 15.5277C6.08594 16.6059 9.44781 17.5002 13.9347 17.7215V25.4634H18.0597V17.7215C22.5428 17.5077 25.9084 16.6077 25.9084 15.5277C25.9084 14.4477 22.5466 13.5496 18.0597 13.334ZM15.9972 17.0202C11.1428 17.0202 7.20719 16.2702 7.20719 15.3665C7.20719 14.5884 10.0741 13.9359 13.9309 13.7615V16.4109C14.5947 16.4409 15.2847 16.4577 15.9934 16.4577C16.7022 16.4577 17.3959 16.4409 18.0559 16.4109V13.7615C21.9128 13.9359 24.7797 14.5884 24.7797 15.3665C24.7872 16.2796 20.8516 17.0202 15.9972 17.0202Z"
      fill="#FBFBFF"
    />
  </svg>
);

import React, {FC} from 'react'
import styled from 'styled-components'
import {Tooltip, Typography} from '@pankod/refine-antd'
import {useTranslate} from "@pankod/refine-core";
import {PaymentTypeEnum} from "../../../p2p/createOrder/types";

const {Text} = Typography

interface IProps {
  paymentTypes: any[]
}

const PaymentTypes: FC<IProps> = ({paymentTypes = []}) => {
  const t = useTranslate()
  const getPayments = () => {
    let payments: JSX.Element[] = []
    const TooltipC = ({title, desc, children}: { title: string; desc: string, children: JSX.Element }): JSX.Element => {
      return <Tooltip
        key={title}
        color={'#2B2B36'}
        title={
          <div
            style={{
              padding: '4px'
            }}
          >
            <Typography>
              {title}
            </Typography>
            <Typography style={{
              color: '#767687'
            }}>
              {desc}
            </Typography>
          </div>
        }
      >
        {children}
      </Tooltip>
    }
    for (let i = 0; i < paymentTypes?.length; i++) {
      switch (paymentTypes[i]?.payment_type) {
        case PaymentTypeEnum.bankCardPayment:
          payments.push(
            <TooltipC title={paymentTypes[i].bank_name} desc={t('payments.types.banks')}>
              <Payment><PaymentText>{paymentTypes[i].bank_name}</PaymentText></Payment>
            </TooltipC>
          )
          break
        case PaymentTypeEnum.mobileBalancePayment:
          payments.push(
            <TooltipC title={'+7 (900) *** ** **'} desc={t('payments.types.mobiles')}>
              <Payment><PaymentText>{t('payments.mobileBalanceReplenishment').slice(0, 15) + '...'}</PaymentText></Payment>
            </TooltipC>
          )
          break
        case PaymentTypeEnum.cashPayment:
          payments.push(
            <TooltipC title={'Cash Payment'} desc={t('payments.types.cashes')}>
              <Payment><PaymentText>{t('payments.cashes')}</PaymentText></Payment>
            </TooltipC>
          )
          break
        case PaymentTypeEnum.bankTransferPayment:
          payments.push(
            <TooltipC title={paymentTypes[i].bank_name} desc={t('dashboard.filters.method.other.methods.bank')}>
              <Payment><PaymentText>{paymentTypes[i].bank_name}</PaymentText></Payment>
            </TooltipC>
          )
          break
        case PaymentTypeEnum.fastPaymentSystemPayment:
          payments.push(
            <TooltipC title={t('dashboard.filters.method.other.methods.fps')} desc={t('payments.types.sbp')}>
              <Payment><PaymentText>{t('dashboard.filters.method.other.methods.fps')}</PaymentText></Payment>
            </TooltipC>
          )
          break
        case PaymentTypeEnum.webServicePayment:
          payments.push(
            <TooltipC title={paymentTypes[i].web_service_name} desc={t('payments.electronService')}>
              <Payment><PaymentText>{paymentTypes[i].web_service_name}</PaymentText></Payment>
            </TooltipC>
          )
          break
      }
    }

    if (paymentTypes.length > 3) {
      // @ts-ignore
      const arr = (obj) => {
        switch (obj.payment_type) {
          case PaymentTypeEnum.bankCardPayment:
            return {
              label: obj.bank_name,
              type: t('payments.types.banks')
            };
          case PaymentTypeEnum.mobileBalancePayment:
            return {
              label: '+7 (900) *** ** **',
              type: t('payments.types.mobiles')
            };
          case PaymentTypeEnum.cashPayment:
            return {
              label: 'Cash Payment',
              type: t('payments.types.cashes')
            };
          case PaymentTypeEnum.bankTransferPayment:
            return {
              label: obj.bank_name,
              type: t('dashboard.filters.method.other.methods.bank')
            };
          case PaymentTypeEnum.fastPaymentSystemPayment:
            return {
              label: t('dashboard.filters.method.other.methods.fps'),
              type: t('payments.types.sbp')
            };
          case PaymentTypeEnum.webServicePayment:
            return {
              label: obj.web_service_name,
              type: t('payments.electronService')
            };
        }
      }
      payments.push(
        <Tooltip
          color={'#2B2B36'}
          title={
            paymentTypes.map((i, index) =>
              <div
                key={`dashboard-collapse-tab-plus-${index}`}
                style={{
                  padding: '4px'
                }}
              >
                <Typography>
                  {/*@ts-ignore*/}
                  {arr(i).label}
                </Typography>
                <Typography style={{
                  color: '#767687'
                }}>
                  {/*@ts-ignore*/}
                  {arr(i).type}
                </Typography>
              </div>
            )
          }
        >
          <Payment><MorePaymentText>+{paymentTypes.length - 3}</MorePaymentText></Payment>
        </Tooltip>
      )
    }
    if (paymentTypes.length > 3) {
      return [...payments.slice(0, 3), payments[payments.length - 1]]
    } else {
      return payments
    }
  }

  return <Payments>
    {getPayments()}
  </Payments>
}

export default PaymentTypes

const Payments = styled.div`
  max-width: 220px;
  min-height: 50px;
  display: flex;
  align-items: flex-start;
  gap: 4px;
  flex-wrap: wrap;
`

const Payment = styled.div`
  max-width: 220px;
  height: 23px;
  overflow: hidden;
  color: #FBFBFF;
  white-space: nowrap;

  padding: 4px;
  border-radius: 3px;
  background: #474755;
  z-index: 999;
`


const PaymentText = styled(Text)`
  font-size: 12px;
  font-weight: 500;
  color: #FBFBFF;
  line-height: 0;
`

const MorePaymentText = styled(Text)`
  font-size: 12px;
  font-weight: 500;
  color: #FBFBFF;
`

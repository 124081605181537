import { coinArr } from '../../../pages/coin-account/coinArr';
import { TCoinName } from '../types';

// Возвращает полное название монeты по ее id из coinArr
export const getFullCoinName = (coin: TCoinName) => {
  const coinName = coinArr.filter((c) => c.id === coin);

  if (!!coinName?.length) {
    return coinName[0]?.name;
  }

  return coin;
};

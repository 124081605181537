import { FC } from 'react';
import WalletHeader from '../WalletHeader/WalletHeader';
import WalletCoins from '../WalletCoins/WalletCoins';

const Wallet: FC = () => {
  return (
    <>
      <WalletHeader />
      <WalletCoins />
    </>
  );
};

export default Wallet;
